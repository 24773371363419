import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ListasService {

  constructor(private http: HttpClient) { }

  listasEndpoint = environment.BASE_URL + '/v1/listas/consultar';
  listasConsultarCiudades = environment.BASE_URL + '/v1/lista/ciudades/consultar';
  preguntasEndpoint = environment.BASE_URL + '/v1/lista/preguntas/consultar';
  listaVideos = environment.BASE_URL + "/v1/lista/videos/consultar";
  obtenerCirculoDeVidaPath = environment.BASE_URL + "/v1/emprendedor/obtenerCirculoDeVida";
  obtenerCompetenciasPath = environment.BASE_URL + "/v1/emprendedor/obtenerCompetencias";
  obtenerTipoEmprendedorPath = environment.BASE_URL + "/v1/emprendedor/obtenerTipoEmprendedor";
  obtenerCapacidadesPath = environment.BASE_URL + "/v1/emprendedor/obtenerCapacidades";


  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
    })
  }

  // Error handling
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  getListas(): Observable<any> {
    return this.http.post(this.listasEndpoint, {}, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.errorHandl)
      );
  }

  //Metodo para obtener las listas de ciudades por el back-end
  getListasCiudades(request): Observable<any> {
    return this.http.post(this.listasConsultarCiudades, request, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.errorHandl)
      );
  }

  getListaPreguntas(request): Observable<any> {
    return this.http.post(this.preguntasEndpoint, request, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.errorHandl)
      );
  }

  getVideos(): Observable<any> {
    sessionStorage.removeItem("videoList");
    const videoListStorage = sessionStorage.getItem("videoList");
    if (videoListStorage) {
      return of(JSON.parse(videoListStorage));
    }
    else {
      return this.http.post(this.listaVideos, {}, this.httpOptions)
        .pipe(
          map(this.guardarEnSesion),
          catchError(this.errorHandl)
        );
    }
  }

  private guardarEnSesion(res: Response) {
    let body = res || {};
    sessionStorage.setItem("videoList", JSON.stringify(body));
    return body;
  }

  obtenerCirculoDeVida(consultarEmprendedor): Observable<any> {
    return this.http.post(this.obtenerCirculoDeVidaPath, consultarEmprendedor, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.errorHandl)
      );
  }

  obtenerCompetencias(consultarEmprendedor): Observable<any> {
    return this.http.post(this.obtenerCompetenciasPath, consultarEmprendedor, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.errorHandl)
      );
  }

  obtenerTipoEmprendedor(consultarEmprendedor): Observable<any> {
    return this.http.post(this.obtenerTipoEmprendedorPath, consultarEmprendedor, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.errorHandl)
      );
  }

  obtenerCapacidades(consultarEmprendedor): Observable<any> {
    return this.http.post(this.obtenerCapacidadesPath, consultarEmprendedor, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.errorHandl)
      );
  }

  getCurrentVideos(): Observable<any> {
    return this.http.post(this.listaVideos, {}, this.httpOptions)
      .pipe(
        map(this.guardarEnSesion),
        catchError(this.errorHandl)
      );
  }

}
