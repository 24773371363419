<!-- BANNER -->
<div class="row" style="background-color: #64C2D2;">
    <div class="column alto-colunma width-texto1" style="color: #FFFFFF;text-align: right; padding-top: 10px;">
        <span style="margin-right: 20px;"> ¿Quieres utilizar Stive en tu negocio? </span>
    </div>
    <div class="column alto-colunma boton-margin">
        <button type="button" class="btn btn-subs" (click)="this.abrirSubsTipoDeSubs()">Adquiere tu suscripción</button>
    </div>
</div>
<div class="row h-100 pregistro">
    <div class="container h-100">
        <div class="logoregistro"><img src="../../assets/img/logostive.png" alt=""></div>
        <br>
        <div style="color: #696868; background-color: #F5F5F5;border-style: solid;border-width: 1px;border-color: #ece3e3;" class="div-banner2">
            <div class="row">
                <div class="column" style="width: 100%;display: flex;justify-content: center;align-items: center;">
                    <span> Para registrarte en Stive, necesitas un código de ingreso que llegará a tu correo electrónico.
                    </span>
                </div>
            </div>
            <div class="row" style="padding-top: 11px">
                <div class="column div-banner2-col2">
                    <span> si quieres adquirir un código, oprime en el <br> botón "Adquiere tu suscripción" de arriba. </span>
                </div>
                <div class="column pos-flecha" style="margin-left: 19px;">
                    <img class="size-flecha" src="../../assets/img/iconos/flecha-up.png">
                </div>
                <div class="column div-banner2-col2">
                    <span> si eres un usuario invitado por una <br> institución aliada, regístrate abajo. </span>
                </div>
                <div class="column pos-flecha pos-flecha-abajo">
                    <img class="size-flecha" src="../../assets/img/iconos/icons8-scroll-down-96.png">
                </div>
            </div>
        </div>
        <br>
        <div class="row formularios">
            <!--Formulario de registro-->
            <div class="col-md-11 col-lg-4 offset-md-1 p-0">
                <div class="w-100 text-center" *ngIf="videoReady; else elseBlock">
                    <iframe class="responsive-iframe" [src]="videoURL" frameborder="4"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe>
                </div>
                <div class="w-100">
                    <ng-template #elseBlock>
                        <mat-spinner style="margin-left: 40%;"></mat-spinner>
                    </ng-template>
                </div>
            </div>
            <div class="col-md-12 col-lg-4 offset-md-1 p-0 registrarse">
                <mat-tab-group class="tabG">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <label #tooltip="matTooltip" matTooltip="Comienza tu experiencia en Stive"
                                matTooltipPosition="above" matTooltipHideDelay="450" aria-label="mat Tooltip"
                                (click)="tooltip.toggle()">
                                REGÍSTRATE
                            </label>
                        </ng-template>
                        <div class="example-box">
                            <!--Formulario de registro-->
                            <form class="text-center" [formGroup]="registerForm" (ngSubmit)="onRegisterSubmit()">
                                <!--Email-->
                                <div class="form-group">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Ingresar correo</mat-label>
                                        <input matInput type="email" placeholder="Correo electrónico"
                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                            formControlName="useremail"
                                            [ngClass]="{ 'is-invalid': registerSubmitted && f.useremail.errors }">
                                        <mat-icon matSuffix>alternate_email</mat-icon>
                                    </mat-form-field>
                                    <span *ngIf="registerSubmitted && r.useremail.errors" class="error">
                                        <span *ngIf="r.useremail.errors.email">Ingrese un Email Valido</span>
                                    </span>
                                </div>
                                <!--Contraseña-->
                                <div class="form-group">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Ingresar contraseña</mat-label>
                                        <input matInput type="{{vp}}" id="exampleInputPassword" placeholder="Contraseña"
                                            formControlName="password"
                                            [ngClass]="{ 'is-invalid': registerSubmitted && f.password.errors }">
                                        <mat-icon matSuffix (click)="viewPassword()" class="viewPassword">remove_red_eye
                                        </mat-icon>
                                    </mat-form-field>
                                    <span *ngIf="registerSubmitted && r.password.errors">
                                        <span *ngIf="r.password.errors.pattern" class="error">
                                            <ul>
                                                <li>Se debe tener al menos una minúscula</li>
                                                <li>Se debe tener al menos una mayúscula</li>
                                                <li>Se debe tener al menos un número</li>
                                                <li>Debe tener una longitud mayor a 8 caracteres y menor o igual a 20
                                                </li>
                                                <li>Se debe tener al menos un caracter especial Ejm:(.$@$!%*?&)</li>
                                            </ul>
                                        </span>
                                    </span>
                                </div>
                                <!--Confirmar Contraseña-->
                                <div class="form-group">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Confirmar contraseña</mat-label>
                                        <input matInput type="{{vp}}" id="exampleInputPassword2"
                                            placeholder="Confirmar contraseña" formControlName="password2"
                                            [ngClass]="{ 'is-invalid': registerSubmitted && f.password.errors }">
                                        <mat-icon matSuffix (click)="viewPassword()" class="viewPassword">remove_red_eye
                                        </mat-icon>
                                    </mat-form-field>
                                    <span *ngIf="registerSubmitted && registerForm.errors?.invalid" class="error">
                                        La contraseña no coincide
                                    </span>
                                </div>
                                <!--Recordar contraseña-->
                                <div class="form-group form-check">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                    <label class="form-check-label" for="exampleCheck1">Recordar contraseña</label>
                                </div>
                                <button type="submit" class="btn btn-primary">Registrarse en Stive</button>
                            </form>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <label #tooltip="matTooltip" matTooltip="Continúa tu experiencia en Stive"
                                matTooltipPosition="above" matTooltipHideDelay="450" aria-label="mat Tooltip"
                                (click)="tooltip.toggle()">
                                INICIA SESIÓN
                            </label>
                        </ng-template>
                        <div class="example-box">
                            <!--Formulario de inicio de seccion-->
                            <form class="text-center" [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
                                <!--Email-->
                                <div class="form-group">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Ingresar email</mat-label>
                                        <input matInput type="email" aria-describedby="emailHelp"
                                            placeholder="Correo electrónico" formControlName="useremail"
                                            [ngClass]="{ 'is-invalid': loginSubmitted && f.useremail.errors }">
                                        <mat-icon matSuffix>alternate_email</mat-icon>
                                    </mat-form-field>
                                    <span *ngIf="loginSubmitted && f.useremail.errors">
                                        <span *ngIf="f.useremail.errors.email" class="error">Debes ingresar un Email
                                            valido</span>
                                    </span>
                                </div>
                                <!--Contraseña-->
                                <div class="form-group">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Ingresar contraseña</mat-label>
                                        <input matInput type="{{vp}}" id="exampleInputPassword3"
                                            placeholder="Contraseña" formControlName="password"
                                            [ngClass]="{ 'is-invalid': loginSubmitted && f.password.errors }">
                                        <mat-icon matSuffix (click)="viewPassword()" class="viewPassword">remove_red_eye
                                        </mat-icon>
                                    </mat-form-field>
                                </div>
                                <!--Recordar Contraseña-->
                                <div class="form-group form-check checksesion">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck2">
                                    <label class="form-check-label" for="exampleCheck2">Recordar contraseña</label>
                                </div>
                                <!--Olvide contraseña-->
                                <div class="form-group">
                                    <button type="button" class="btn btn-tyc" (click)='recoverPwd()'>Olvidé mi
                                        contraseña</button>
                                </div>
                                <button type="submit" class="btn btn-primary">Ingresar</button>
                            </form>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>