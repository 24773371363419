import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  STORAGE: any = 'STATE';

  state: any;

  constructor() {
    this.init();
  }

  newState() {
    return {
      basico: {
        emailEmprendedor:"",
        idEmprendedor : "",
        codPaisEmprendedor : "",
        codProyecto: "",
        nombreProyecto: "",
        codPaisProyecto: "",
        nombres: "",
        apellidos: "",
        footer: "",
        tipoEmprendedor: "",
        estadoClasificacionDelProyecto: "",
        estadoCreacionDelaEstrategia: ""
      },
      emprendedor: {
        datos: {}
      },
      proyecto: {
        datos: {},
        plan: {},
        resumenTacticas: {}
      },
      estadoEmprendedor: [
        {
          'nombre': 'Competencias de emprendedor',
          'estado': false,
          'route': 'registered/entrepreneur/skills'
        },
        {
          'nombre': 'Tipo de emprendedor',
          'estado': false,
          'route': 'registered/entrepreneur/type'
        }
      ],
      estadoProyecto: [
        {
          'nombre': 'Inscripción de proyecto',
          'estado': false,
          'route': 'registered/project/create'
        },
        {
          'nombre': 'Fase y momento de negocio',
          'estado': false,
          'route': 'registered/project/phase'
        },
        {
          'nombre': 'Capacidad del negocio',
          'estado': false,
          'route': 'registered/project/capacities'
        },
        {
          'nombre': 'Posición competitiva',
          'estado': false,
          'route': 'registered/project/ocean'
        }
      ],
      estadoEstrategia: [
        {
          'nombre': 'Prioridades estratégicas',
          'estado': false,
          'route': 'registered/project/strategy'
        },
        {
          'nombre': 'Sistema prioridad 1',
          'estado': false,
          'route': 'registered/project/tactics'
        },
        {
          'nombre': 'Sistema prioridad 2',
          'estado': false,
          'route': 'registered/project/tactics'
        },
        {
          'nombre': 'Sistema prioridad 3',
          'estado': false,
          'route': 'registered/project/tactics'
        },
      ]
    };

  }

  init() {
    const datosAlmacenados = localStorage.getItem(this.STORAGE);
    if (datosAlmacenados) {
      this.state = JSON.parse(datosAlmacenados);
    } else {
      this.state = this.newState();
    }
  }

  updateBasico(atributo: string, valor: any) {
    this.state.basico[atributo] = valor;
    this.persist();
  }

  updateEnterpreneur(atributo: string, valor: any) {
    this.state.emprendedor[atributo] = valor;
    this.persist();
  }

  updateProject(atributo: string, valor: any) {
    this.state.proyecto[atributo] = valor;
    this.persist();
  }

  updateEmprendedor(nombre: string) {
    this.state.estadoEmprendedor.filter(estEmprendedor => estEmprendedor.nombre === nombre)[0]
      .estado = true;
    this.persist();
  }

  updateProyecto(nombre: string) {
    this.state.estadoProyecto.filter(estProyecto => estProyecto.nombre === nombre)[0]
      .estado = true;
    this.persist();
  }

  updateEstrategia(nombre: string) {
    this.state.estadoEstrategia.filter(estEstrategia => estEstrategia.nombre === nombre)[0]
      .estado = true;
    this.persist();
  }

  persist() {
    localStorage.setItem(this.STORAGE, JSON.stringify(this.state));
  }

  getInfo(primerNivel: string = '', segundoNivel: string = '') {
    if (primerNivel) {
      if (segundoNivel) {
        return this.state[primerNivel][segundoNivel];
      }
      return this.state[primerNivel];
    }
    return this.state;
  }

  validateNewUser(newEmail: string) {
    if (this.state.basico.emailEmprendedor != newEmail) {
      localStorage.removeItem(this.STORAGE);
      this.state = this.newState();
      this.updateBasico('emailEmprendedor', newEmail);
    }
  }

}
