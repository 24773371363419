<h1 mat-dialog-title style="font-size: 20px;">Cambio de plan </h1>
<div mat-dialog-content>
  <p style="font-size: 17px;">Se cambiará tu plan de Stive Pro a Lite y perderás el acceso al diagnóstico de emprendedor
    y al panel de seguimiento.
    <br>
    ¿Quieres continuar?
  </p>
</div>
<div mat-dialog-actions style="display: flex;justify-content: center;">
  <button mat-button mat-dialog-close cdkFocusInitial (click)="aceptarCambio('SI')"
    style="outline:0 !important;border-color: aliceblue;border-radius: 10px;border: none;background-color: white;color: rgb(91, 194, 213);">Continuar</button>
        
  <button mat-button mat-dialog-close cdkFocusInitial (click)="noAceptarCambio()"
    style="outline:0 !important;border-radius: 10px;border: none; background-color: white;color: rgb(91, 194, 213);">Volver</button>
</div>