import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgxSpinnerModule } from "ngx-spinner";
import { AlertComponent } from './_modal/alert/alert.component';
import { HeaderLayoutComponent } from './_components/header-layout/header-layout.component';
import { FooterLayoutComponent } from './_components/footer-layout/footer-layout.component';
import { VideoIntroductorioComponent } from './_modal/video-introductorio/video-introductorio.component';
import { MaterialModule } from './material.module';

@NgModule({
  declarations: [
    HeaderLayoutComponent,
    FooterLayoutComponent,
    AlertComponent,
    VideoIntroductorioComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxSpinnerModule,
    MaterialModule
  ],
  exports: [
    HeaderLayoutComponent,
    FooterLayoutComponent,
    NgxSpinnerModule,
    AlertComponent,
    VideoIntroductorioComponent
  ],
  entryComponents: [
    AlertComponent
   
  ]
})
export class CommonsModule { }
