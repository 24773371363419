import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  urlTYC: any;
  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.urlTYC = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.iubenda.com/privacy-policy/68884527");
  }

}
