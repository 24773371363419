import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl  } from '@angular/forms';
import { EmprendedorService, ListasService } from '@app/_services';
import { AlertService } from '@app/_modal/alert/alert.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { mensaje} from '@app/_const/alert_error_message';
import { StateService } from '@app/_helpers/state.service';
import { VideoService } from '@app/_modal/video-introductorio/video-introductorio.service';
import { MatSliderChange } from '@angular/material/slider';

@Component({
  selector: 'app-circulovida-edicion',
  templateUrl: './circulovida-edicion.component.html',
  styleUrls: ['./circulovida-edicion.component.css']
})
export class CirculovidaEdicionComponent implements OnInit {

   //Definimos la variable del formulario
   createForm: FormGroup = this.formBuilder.group({});

   submitted = false;
   preguntas:any[] = [];
   item:any[] = [];
   value:any;
   codigopais:string;
   codigoEmprendedor:String;
   updateEntrepreneur: any;
   tipo:string = "Emprendedor";
   subTipo:string = "CirculoVida";
   disabled: boolean = false;
   preguntasCV:any[] = [];



  constructor(
    //instanciamos la variable para la construccion del formulario
    private formBuilder: FormBuilder,
    private listaServ: ListasService,
    private entrepreneur: EmprendedorService,
    private alertSrv: AlertService,
    private spinner: NgxSpinnerService,
    private state: StateService,
    private router: Router,
    private videoIntroductorio: VideoService
  ) { }

  ngOnInit(): void {
    this.state.updateBasico('footer','emprendedor');
    //obtenemos los datos del setionStorage
    let paisEmprendedor= this.state.getInfo("basico", "codPaisEmprendedor");
    this.codigopais = paisEmprendedor;
    let emprendedor = this.state.getInfo("basico", "idEmprendedor");
    this.codigoEmprendedor = emprendedor;
    //obtener circulo de la vida
    this.obtenerCirculoDeVida();
  }

  obtenerCirculoDeVida(){
    let json = {"codigo": this.codigoEmprendedor}
    //mostramos el spinner en la pantalla de carga
    this.spinner.show();
    this.listaServ.obtenerCirculoDeVida(json).subscribe(
      res=>{
        this.preguntasCV = res.datos.circuloVida.preguntasCirculoVidaList;
        this.createFormValidation(this.preguntasCV.length);
        this.spinner.hide();
      },
      e=>{
        this.spinner.hide();
        this.alertSrv.showAlert(mensaje.error, "Error");
        console.log(e);
      }
    );
  }

  createFormValidation(length: number){
    const arrayFormGroup:any = {};
    for(let i=1; i<=length; i++){
      arrayFormGroup["pregunta"+i] = [this.preguntasCV[i-1].valor, Validators.required];
    }
    this.createForm = this.formBuilder.group(arrayFormGroup);
  }

   //metodo para enviar información
   onCreateFormSubmitted(){
    this.submitted = true;
    //paramos aca si nuestro formulario no cumple con las validaciones
    if(this.createForm.invalid) return;
    this.onSubmit();
  }

  onSubmit(){
    this.disabled = true;
    this.spinner.show();
    //creamos nuestro objeto json
    const preguntasList = [];
    for(let i=0; i<this.preguntasCV.length; i++){
      preguntasList.push({
        "codigo": this.preguntasCV[i].pregunta.codigo,
        "valor": this.preguntasCV[i].valor
      });
    }
    this.updateEntrepreneur = {
      "codigoEmprendedor": this.codigoEmprendedor,
      "preguntasCicloVidaList": preguntasList
    };
    console.log(this.updateEntrepreneur);
    //actualizamos el proyecto
    this.entrepreneur.setActualizar(this.updateEntrepreneur).subscribe(
      response => {
        this.disabled = false;
        this.spinner.hide();
        if(response.error && response.error.codigo){
          this.alertSrv.showAlert(response.error.mensaje, "Error");
          return;
        }
        this.state.updateEmprendedor("Circulo de la vida");
        this.router.navigate(['editar/competencias']);
        //omitimos el popup de Registro Exitoso
        // this.alertSrv.showAlert(mensaje.exito, "Registro Exitoso")
        // .afterClosed().subscribe(result => {
        //   //Cambiar estado
        //   this.state.updateEmprendedor("Circulo de la vida");
        //   //this.router.navigate(['registered/entrepreneur/skills']);
        //   this.router.navigate(['editar/competencias']);
        // });
      },
      e => {
        this.disabled = false;
        this.spinner.hide();
        this.alertSrv.showAlert(mensaje.error, "Error");
        console.log(e);
      }
      )
    }

  //abrimos el pop-up de video introductorio
  openVideoIntroductorio(){
    this.videoIntroductorio.showAlert("CirculoVida");
  }
  
  dashboard(){
    this.router.navigate(['registered/dashboard']);
  }

  irACompetencias(){
    this.router.navigate(['editar/competencias']);
  }

  irATipoEmprendedor(){
    this.router.navigate(['editar/tipo-emprendedor']);
  }

  onInputChange(event: MatSliderChange, preguntaFront) {
    let preguntasCVTempo:any[] = [];
    for(let i=0; i<=this.preguntasCV.length; i++){
      if(this.preguntasCV[i] && this.preguntasCV[i].pregunta) {
        if(this.preguntasCV[i].pregunta.codigo != preguntaFront.pregunta.codigo){
          preguntasCVTempo.push(this.preguntasCV[i]);
        }else{
          let nuevaPregunta = preguntaFront;
          nuevaPregunta.valor = event.value;
          preguntasCVTempo.push(nuevaPregunta);
        }
      }
    }
    this.preguntasCV = preguntasCVTempo;
  }

}
