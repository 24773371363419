import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-init-page-footer',
  templateUrl: './init-page-footer.component.html',
  styleUrls: ['./init-page-footer.component.css']
})
export class InitPageFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
