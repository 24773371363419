import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '@env/environment';

declare let gtag: (property: string, value: any, configs: any) => {};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'stive';

  constructor(
    private router: Router
    ) {
      // Observer navigation ends - Google Analytics
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag('config', environment.googleAnalyticsId, {
            page_path: event.urlAfterRedirects
          });
        }
      });
    }

  ngOnInit(): void {
    //Cada que finalice una pagina en nuestra aplicación ira
    //a la parte superior de la siguiente pagina.
      this.router.events.subscribe((evt) => {
          if (!(evt instanceof NavigationEnd)) {
              return;
          }
          window.scroll(0,0);
      });
  }

}
