import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProyectoService {

  constructor(private http: HttpClient) { }

  nuevoUsuario: any = {};

  registrarProyecto = environment.BASE_URL + '/v1/proyecto/registrar';
  listaPreguntas = environment.BASE_URL + '/v1/proyecto/fase/preguntas';
  listaMomentos = environment.BASE_URL + '/v1/proyecto/momentos/consultar';
  listaOceanos = environment.BASE_URL + '/v1/proyecto/propuestaValor/consultar';
  actualizarProyecto = environment.BASE_URL + '/v1/proyecto/actualizar';
  entornoCompetitivo = environment.BASE_URL + '/v1/proyecto/entornoCompetitivo/consultar';
  prioridadEstrategia = environment.BASE_URL + '/v1/proyecto/prioridadEstrategica/consultar';
  consultarProyecto = environment.BASE_URL + '/v1/proyectos/consultar';
  tacticasEstrategicas = environment.BASE_URL + '/v1/tacticas/consultar';
  tacticasEstrategicasGuardar = environment.BASE_URL + '/v1/tacticas/actualizar';
  consultarSeleccionTactica = environment.BASE_URL + '/v1/proyecto/seleccionTacticaProyecto/consultar';
  actualizarSeguimiento = environment.BASE_URL + '/v1/proyecto/seguimiento/actualizar';
  seleccionObjetivoKpi = environment.BASE_URL + '/v1/proyecto/seleccionarObjetivoKpi';
  consultarSeleccionObjetivoKpi = environment.BASE_URL + '/v1/proyecto/consultar/seleccionarObjetivoKpi';

     // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
    })
  };

  private extractData(res: Response) {
    const body = res;
    return body || { };
  }

   // Error handling
   errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }

 setNewProject(NewProject): Observable<any>{
    const json = JSON.stringify(NewProject);
    return this.http.post(this.registrarProyecto, json , this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.errorHandl)
      );
  }

  getListaMomentos(): Observable<any> {
    return this.http.post(this.listaMomentos, {}, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.errorHandl)
      );
  }

  getListaPreguntas(): Observable<any> {
    return this.http.get(this.listaPreguntas)
    .pipe(
      map(this.extractData),
      catchError(this.errorHandl)
    );
  }

  setActualizar(request): Observable<any> {
    const json = JSON.stringify(request);
    return this.http.post(this.actualizarProyecto, json, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.errorHandl)
    );
  }

  getPropuestaValor(request: any): Observable<any> {
    return this.http.post(this.listaOceanos, request, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.errorHandl)
    );
  }

  getEntornoCompetitivo(request: any): Observable<any> {
    return this.http.post(this.entornoCompetitivo, request, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.errorHandl)
    );
  }

  getPrioridad(request: any): Observable<any> {
    return this.http.post(this.prioridadEstrategia, request, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.errorHandl)
    );
  }

  consultProyecto(consultarProyecto): Observable<any>{
    const json = {codigoEmprendedor: consultarProyecto};
    return this.http.post(this.consultarProyecto, json, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.errorHandl)
      );
  }

  getTacticas(request: any): Observable<any> {
    return this.http.post(this.tacticasEstrategicas, request, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.errorHandl)
    );
  }

  guardarTacticas(request: any): Observable<any> {
    return this.http.post(this.tacticasEstrategicasGuardar, request, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.errorHandl)
    );
  }

  consultarTacticas(request: any): Observable<any> {
    return this.http.post(this.consultarSeleccionTactica, request, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.errorHandl)
    );
  }

  setActualizarSeguimiento(request: any): Observable<any> {
    return this.http.post(this.actualizarSeguimiento, request, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.errorHandl)
      );
  }

  SeleccionObjetivoKpi(request: any): Observable<any> {
    return this.http.post(this.seleccionObjetivoKpi, request, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.errorHandl)
      );
  }

  ConsultarSeleccionObjetivoKpi(request: any): Observable<any> {
    return this.http.post(this.consultarSeleccionObjetivoKpi, request, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.errorHandl)
      );
  }
}
