<app-header-layout></app-header-layout>
<div class="container">
    <br><h3 class="texto-h3" style="margin-bottom: 0px;font-size: 17px!important;">GESTIONAR PLAN</h3>
    <br><div class="content1 sub-tirulo centrar-item estilo-div-principal" style="font-size: 15px;">Cambia o renueva tu suscripción de Stive</div>
    <br><div class="content1 sub-tirulo centrar-item estilo-div-principal" style="font-size: 15px;">
            <b>Tu plan Stive {{this.tipoDeSub}} se encuentra activo hasta el día {{this.fechaVencimientoSub}}</b>
        </div>
    <div *ngIf="8 > diasValidosSub" class="content1 sub-tirulo centrar-item estilo-div-principal" style="font-size: 15px;color: red;">
        <b>En {{diasValidosSub}} días se vence tu plan, renuévalo para continuar usando Stive</b>
    </div>
</div><br>

<div style="cursor: pointer;font-family: arial;margin-top: -25px;color: #64D2CE;font-size: 18px;"  (click)="validarMostrarPrecios()">
    <p style="text-align: center;">
        <br><br>
        <button type="submit" class="btn btn-primary margen-volver-d" (click)="dashboard()">
            <mat-icon matSuffix style="float: left;margin-left: 5%;">keyboard_backspace</mat-icon>Volver al dashboard
        </button>
    </p>
</div>


<div class="row centrar-item"  id="div-principal">
    <!-- LITE -->
    <div class="col-md-6 mb-3 estilo-subuno" style="pointer-events: none;">
        <img class="responsive pos-lite" id="div-ppal-top" src="../../../../assets/img/banner-pago-lite.png" alt="" title="">
        <div class="container">
            <span class="sub-tirulo centrar-item" style="margin-bottom: 0px;font-size: 17px!important; color: #64C5D1;">{{textoTuPlanLite}}</span>
        </div>
        <form method="post" #formLITE  
            action="https://checkout.payulatam.com/ppp-web-gateway-payu" >
            <input name="merchantId"        type="hidden" value="962611">
            <input name="accountId"         type="hidden" value="970383">
            <input name="buyerEmail"        type="hidden" value="--" #buyerEmailLite>
            <input name="description"       type="hidden" value="Compra de subcripción LITE">
            <input name="referenceCode"     type="hidden" value="--" #referenceLiteTest>
            <input name="amount"            type="hidden" value="0" #amountLite>
            <!--IVA-->
            <input name="tax"               type="hidden" value="0">
            <input name="taxReturnBase"     type="hidden" value="0">
            <input name="currency"          type="hidden" value="COP">
            <input name="signature"         type="hidden" value="--" #signatureLiteTest>
            <input name="test"              type="hidden" value="false">
            <input name="responseUrl"       type="hidden" value="--" #paginaRtaLite>
            <input name="confirmationUrl"   type="hidden" value="--" #pgnConfirmacionLite>
            <input name="Submit1"           type="button" [ngClass]="{'btn-off': !habilitarBotonLite}" 
                    (click)="submitFormLite();" 
                    class="pos-btn-lite centrar-item estilo-boton-pagar margen-izq btn btn-primary" 
                    value="$125,000.00 por 3 meses">
        </form>

    </div>

    <!-- PRO -->
    <div class="col-md-6 mb-3 estilo-subuno" style="pointer-events: none;">
        <img class="responsive pos-pro" src="../../../../assets/img/banner-pago-pro.png" alt="" title="" >
        <div class="container">
            <span class="sub-tirulo centrar-item" style="margin-bottom: 0px;font-size: 17px!important; color: #64C5D1;">{{textoTuPlanPro}}</span>
        </div>
        <form method="post" #formPRO 
            action="https://checkout.payulatam.com/ppp-web-gateway-payu" >
            <input name="merchantId"        type="hidden" value="962611">
            <input name="accountId"         type="hidden" value="970383">
            <input name="buyerEmail"        type="hidden" value="--" #buyerEmailPro>
            <input name="description"       type="hidden" value="Compra de subcripción PRO">
            <input name="referenceCode"     type="hidden" value="--" #referencePro>
            <input name="amount"            type="hidden" value="0" #amountPro>
            <!--IVA-->
            <input name="tax"               type="hidden" value="0">
            <input name="taxReturnBase"     type="hidden" value="0">
            <input name="currency"          type="hidden" value="COP">
            <input name="signature"         type="hidden" value="--" #signaturePro>
            <input name="test"              type="hidden" value="false">
            <input name="responseUrl"       type="hidden" value="--" #paginaRtaPro>
            <input name="confirmationUrl"   type="hidden" value="--" #pgnConfirmacionPro>
            <input name="Submit2"           type="button" [ngClass]="{'btn-off': !habilitarBotonPro}" 
                    (click)="submitFormPro();"
                    class="pos-btn-pro centrar-item margen-izq  btn btn-primary estilo-boton-pagar" 
                    value="$175,000.00 por 4 meses">
        </form>
    </div>
</div>

<div style="cursor: pointer;font-family: arial;margin-top: -25px;color: #64D2CE;font-size: 18px;"  (click)="validarMostrarPrecios()">
    <p style="text-align: center;"><u>Ver comparativa de planes</u></p>
</div>
<div id="div-focus-top" style="pointer-events: none;" class="row centrar-item" *ngIf="mostrarPrecios">
    <img class="responsive-precios" src="../../../../assets/img/comparativa-de-precios.png" alt="" title="">
</div>
<div *ngIf="mostrarPrecios" style="cursor: pointer;font-family: arial;margin-top: -25px;color: #64D2CE;font-size: 18px;"
    (click)="mostrarResumen()">
    <br>
    <p style="text-align: center;"><u>Ver resumen de planes</u></p>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>