<div class="row centrar-item" id="div-principal">
    <!-- LITE -->
    <div class="col-md-6 mb-3 estilo-subuno" style="pointer-events: none;">
        <img class="responsive" id="div-ppal-top" src="../../../../assets/img/banner-pago-lite.png" alt="" title="">

        <form method="post" #formLITE  
            action="https://checkout.payulatam.com/ppp-web-gateway-payu" >
            <input name="merchantId"        type="hidden" value="962611">
            <input name="accountId"         type="hidden" value="970383">
            <input name="description"       type="hidden" value="Compra de subcripción LITE">
            <input name="referenceCode"     type="hidden" value="--" #referenceLiteTest>
            <input name="amount"            type="hidden" value="0" #amountLite>
            <!--IVA-->
            <input name="tax"               type="hidden" value="0">
            <input name="taxReturnBase"     type="hidden" value="0">
            <input name="currency"          type="hidden" value="COP">
            <input name="signature"         type="hidden" value="--" #signatureLiteTest>
            <input name="test"              type="hidden" value="false">
            <input name="responseUrl"       type="hidden" value="https://stivedev-front.com/register-payu">
            <input name="confirmationUrl"   type="hidden" value="https://apirest.stivedev-back.com:8100/v1/emprendedor/confirmacionPago">
            <input name="Submit1"           type="button"  
                    (click)="submitFormLite();"
                    class="estilo-boton-pagar margen-izq btn btn-primary" value="$250,000.00 por 3 meses">
        </form>

    </div>

    <!-- PRO -->
    <div class="col-md-6 mb-3 estilo-subuno" style="pointer-events: none;">
        <img class="responsive" src="../../../../assets/img/banner-pago-pro.png" alt="" title="" >

        <form method="post" #formPRO  
            action="https://checkout.payulatam.com/ppp-web-gateway-payu" >
            <input name="merchantId"        type="hidden" value="962611">
            <input name="accountId"         type="hidden" value="970383">
            <input name="description"       type="hidden" value="Compra de subcripción PRO">
            <input name="referenceCode"     type="hidden" value="--" #referencePro>
            <input name="amount"            type="hidden" value="0" #amountPro>
            <!--IVA-->
            <input name="tax"               type="hidden" value="0">
            <input name="taxReturnBase"     type="hidden" value="0">
            <input name="currency"          type="hidden" value="COP">
            <input name="signature"         type="hidden" value="--" #signaturePro>
            <input name="test"              type="hidden" value="false">
            <input name="responseUrl"       type="hidden" value="https://stivedev-front.com/register-payu">
            <input name="confirmationUrl"   type="hidden" value="https://apirest.stivedev-back.com:8100/v1/emprendedor/confirmacionPago">
            <input name="Submit2"           type="button"  
                    (click)="submitFormPro();"
                    class="margen-izq  btn btn-primary estilo-boton-pagar" value="$350,000.00 por 4 meses">
        </form>
    </div>
</div>

<div style="cursor: pointer;font-family: arial;margin-top: -25px;color: #64D2CE;font-size: 18px;"
    (click)="validarMostrarPrecios()">
    <p style="text-align: center;"><u>Ver comparativa de planes</u></p>
</div>
<div id="div-focus-top" style="pointer-events: none;" class="row centrar-item" *ngIf="mostrarPrecios">
    <img class="responsive-precios" src="../../../../assets/img/comparativa-de-precios.png" alt="" title="">
</div>

<div *ngIf="mostrarPrecios" style="cursor: pointer;font-family: arial;margin-top: -25px;color: #64D2CE;font-size: 18px;"
    (click)="mostrarResumen()">
    <br>
    <p style="text-align: center;"><u>Ver resumen de planes</u></p>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>