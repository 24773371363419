import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-planes-subs',
  templateUrl: './login-planes-subs.component.html',
  styleUrls: ['./login-planes-subs.component.css']
})
export class LoginPlanesSubsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
