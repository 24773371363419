import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmprendedorService, ListasService } from  '@app/_services';
import { AlertService } from '@app/_modal/alert/alert.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { mensaje } from '@app/_const/alert_error_message';
import { StateService } from '@app/_helpers/state.service';
import { VideoService } from '@app/_modal/video-introductorio/video-introductorio.service';
import { MatSliderChange } from '@angular/material/slider';


@Component({
  selector: 'app-competencias-edicion',
  templateUrl: './competencias-edicion.component.html',
  styleUrls: ['./competencias-edicion.component.css']
})
export class CompetenciasEdicionComponent implements OnInit {

  public TT_COTPEMSPCO13 : string = "Oferta: Acción de ofrecer un producto para su venta";
  public TT_COTPEMSPCO15 : string = "Las redes de trabajo son la cooperación entre empresas";
  public TT_COTPEMSPCO19 : string = "Fidelización: Conservar a las personas que han comprado un producto";
  public TT_COTPEMSPCO21 : string = "Canales: Medios que se utilizan para llevar el producto hacia el cliente final. Ejemplo: Distribuidor de productos";

 //Definimos la variable del formulario
 skillsForm: FormGroup = this.formBuilder.group({});

 submitted = false;
 competencias:any[] = [];
 item:any[] = [];
 value:any;
 codigopais:string;
 codigoEmprendedor:String;
 updateEntrepreneur: any;
 tipo:string = "Emprendedor";
 subTipo:string = "Competencias";
 disabled: boolean = false;
 competenciasFull:any[] = [];

 constructor(
   //instanciamos la variable para la construccion del formulario
   private formBuilder: FormBuilder,
   private listaServ: ListasService,
   private alertSrv: AlertService,
   private spinner: NgxSpinnerService,
   private entrepreneur: EmprendedorService,
   private state: StateService,
   private router: Router,
   private videoIntroductorio: VideoService
 ) { }

 ngOnInit(): void {
   this.state.updateBasico('footer','emprendedor');
   //obtenemos los datos del setionStorage
   this.codigopais = this.state.getInfo("basico", "codPaisEmprendedor");
   this.codigoEmprendedor = this.state.getInfo("basico", "idEmprendedor");
   //llamamos a nustra función y le pasamos los parametros
   this.getCompetenciasFull(this.codigopais, this.tipo, this.subTipo);
 }

  //metodo para enviar información
  onSkillsFormSubmitted(){
   this.submitted = true;
   //paramos aca si nuestro formulario no cumple con las validaciones
   if(this.skillsForm.invalid) return;
   this.onSubmit();
 }

 onSubmit(){
   this.disabled = true;
   this.spinner.show();
   //creamos nuestro objeto json
   const preguntasList = [];
   for(let i=0; i<this.competenciasFull.length; i++){
     preguntasList.push({
       "codigo": this.competenciasFull[i].pregunta.codigo,
       "valor": this.competenciasFull[i].valor
     });
   }
   this.updateEntrepreneur = {
     "codigoEmprendedor": this.codigoEmprendedor,
     "competenciasList": preguntasList
   };
   //actualizamos el proyecto
   this.entrepreneur.setActualizar(this.updateEntrepreneur).subscribe(
     response => {
       this.disabled = false;
       this.spinner.hide();
       if(response.error && response.error.codigo){
         this.alertSrv.showAlert(response.error.mensaje, "Error");
         return;
       }
       //eliminamos paisEmprededor del sesionStorage
       //sessionStorage.removeItem("paisEmprendedor");
      
       //cambiar estado
       this.state.updateEmprendedor("Competencias de emprendedor");
       this.router.navigate(['editar/tipo-emprendedor']);
      
       
     },
     e => {
       this.disabled = false;
       this.spinner.hide();
       this.alertSrv.showAlert(mensaje.error, "Error");
       console.log(e);
     }
   );
 }

 //obtenemos la lista de capacidades
 getCompetencias(codigopais:string, tipo:string, subTipo:string){
   this.spinner.show();
   this.listaServ.getListaPreguntas({"codigopais":codigopais, "tipo":tipo, "subTipo":subTipo}).subscribe(
     res=>{
       this.spinner.hide();
       this.competencias = res.datos.preguntasList;
       this.skillsFormValidation(this.competencias.length);
     }, e=>{
       this.spinner.hide();
       this.alertSrv.showAlert(mensaje.error, "Error");
       console.log(e);
     } 
   );
 }

 getCompetenciasFull(codigopais:string, tipo:string, subTipo:string){
  let json = {"codigo": this.codigoEmprendedor}
  //mostramos el spinner en la pantalla de carga
  this.spinner.show();
  this.listaServ.obtenerCompetencias(json).subscribe(
    res=>{
      this.competenciasFull = res.datos.competencias.preguntasCompetenciasList;
      this.skillsFormValidation(this.competenciasFull.length);
      this.spinner.hide();
    },
    e=>{
      this.spinner.hide();
      this.alertSrv.showAlert(mensaje.error, "Error");
      console.log(e);
    }
  );
}

 skillsFormValidation(length: number){
   const arrayFormGroup:any = {};
   for(let i=1; i<=length; i++){
     arrayFormGroup["pregunta"+i] = [this.competenciasFull[i-1].valor, Validators.required];
   }
   this.skillsForm = this.formBuilder.group(arrayFormGroup);

 }

 //abrimos el pop-up de video introductorio
 openVideoIntroductorio(){
   this.videoIntroductorio.showAlert("Competencias");
 }

 dashboard(){
  this.router.navigate(['registered/dashboard']);
  }

  irACirculo(){
    this.router.navigate(['editar/circulo-vida']);
  }

  irACompetencias(){
    this.router.navigate(['editar/competencias']);
  }

  irATipoEmprendedor(){
    this.router.navigate(['editar/tipo-emprendedor']);
  }

  onInputChange(event: MatSliderChange, preguntaFront) {
    let preguntasCVTempo:any[] = [];
    for(let i=0; i<=this.competenciasFull.length; i++){
      if(this.competenciasFull[i] && this.competenciasFull[i].pregunta) {
        if(this.competenciasFull[i].pregunta.codigo != preguntaFront.pregunta.codigo){
          preguntasCVTempo.push(this.competenciasFull[i]);
        }else{
          let nuevaPregunta = preguntaFront;
          nuevaPregunta.valor = event.value;
          preguntasCVTempo.push(nuevaPregunta);
        }
      }
    }
    this.competenciasFull = preguntasCVTempo;
  }

}
