<div class="row pos-aliado" style="background-color: #3a3a3a;width: 100%;">
     <!--LOGO-->
    <div class="column-aliado">
      <div style="text-align: center;">
        <img src="../../../assets/img/iconos-ver-1/Stive_footer1.png" style="width: 100px;">
      </div>
    </div>
    <!--REDES S-->
    <div class="column-aliado columna-menu-0">
      <h4 class="widget-title" style="font-weight: 150;">Síguenos:</h4>
      <div style="display: inline-flex;">
            <div class="social-text" style=" cursor: pointer;" (click)="irAInsta();" title="Instagram">
                <img src="../../../assets/img/iconos-ver-1/Stive_footer2.png" class="social-img-1">
            </div>
        &nbsp;<div class="social-text" style="padding-left: 5px;" style=" cursor: pointer;" (click)="irAYoutube();" title="Youtube">
                <img src="../../../assets/img/iconos-ver-1/Stive_footer3.png" class="social-img-2">
            </div>
      </div>
    </div>
    <!--MENU-->
    <div class="column-aliado columna-menu-1">
        <div><p class="texto-menu-1 line-menu-1">  __ </p></div>
        <div><p (click)="irAInicio();" style=" cursor: pointer; padding-right: 0px; border-bottom: 2px solid whitesmoke;" class="texto-menu-1"> 
          <span>Inicio</span> </p></div>
        
        <div><p class="texto-menu-1 line-menu-1">  __ </p></div>
        
        <div><p (click)="irComoFunciona();" style=" cursor: pointer; padding-right: 0px; border-bottom: 2px solid whitesmoke;" class="texto-menu-1"> 
          <span>¿Cómo funciona?</span> </p></div>
        <div><p class="texto-menu-1 line-menu-1">  __ </p></div>
    </div>
    <!-- MARCA -->
    <div class="column-aliado logo-innsie-1" style="color: white;">
      <div><img src="../../../assets/img/iconos-ver-1/Stive_footer4.png" style="width: 70px;"></div>
      <div><p style="font-size: 11px;font-style: italic;font-weight: 150;"> Stive es una marca registrada de Innside </p></div>
    </div>
  </div> 

<div>
  <p class="txt-marca-1">
      Stive es una marca registrada de innside.
      copyright &copy;2023
  </p>
</div>