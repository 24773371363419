import { Component, OnInit } from '@angular/core';
import { ProyectoService } from '@services/index';
import { ListasService } from '@services/index';
import { Router } from '@angular/router';
import { AlertService } from '@app/_modal/alert/alert.service';
import { NgxSpinnerService } from "ngx-spinner";
import { mensaje} from '@app/_const/alert_error_message';
import { DomSanitizer } from '@angular/platform-browser';
import { StateService } from '@app/_helpers/state.service';

@Component({
  selector: 'app-entornoc-edicion',
  templateUrl: './entornoc-edicion.component.html',
  styleUrls: ['./entornoc-edicion.component.css']
})
export class EntornocEdicionComponent implements OnInit {

  fuerzasList:any[]=[];
  fuerzaCompetitivasList:any[]=[];
  codigoEmprendedor: String;
  codigoProyecto: Number;
  videoURL:any;
  videoReady:boolean = false;
  descripcion_video: any;

  constructor(
    public proySrv: ProyectoService,
    public listSrv: ListasService,
    public alertSrv: AlertService,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer,
    private state: StateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.state.updateBasico('footer','estrategia');
    this.codigoEmprendedor = this.state.getInfo("basico", "idEmprendedor");
    this.codigoProyecto = this.state.getInfo("basico", "codProyecto");
    this.getEntornoCompetitivo();
    this.getVideo();
  }

  getEntornoCompetitivo(){
    this.spinner.show();
    let request = this.getDatosProyecto();
    this.proySrv.getEntornoCompetitivo(request).subscribe(
      res=>{
        this.spinner.hide();
        this.fuerzasList = res.datos.fuerzasList;
      },
      error=>{
        this.spinner.hide();
        this.alertSrv.showAlert(mensaje.error, "Error");
      }
    );
  }

  getVideo(){
    this.listSrv.getVideos().subscribe(
      res=>{
        console.log(res);
        res.datos.videosList.filter(v => {
          if(v.clave === "EntornoCompetitivo"){
            this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(v.url);
            this.descripcion_video = v.descripcion;
            this.videoReady = true;
          }
        });
      },
      error=>{
        this.alertSrv.showAlert(mensaje.error, "Error");
      }
    );
  }

  getDatosProyecto(){
    return {
      "codigoEmprendedor": this.codigoEmprendedor,
      "codigoProyecto": this.codigoProyecto
     };
  }

  siguiente(){
    //Cambiar estado
    this.state.updateEstrategia("Entorno competitivo");
    this.router.navigate(['registered/project/strategy']);
  }

  dashboard(){
    this.router.navigate(['registered/dashboard']);
  }

  irAEntorno(){
    this.router.navigate(['editar/entornoc']);
  }

  irAPropiedadesE(){
    this.router.navigate(['registered/project/strategy']);
  }

  irASistemaI(){
    this.router.navigate(['registered/project/tactics']);
  }

}
