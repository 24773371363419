<app-header-layout></app-header-layout>
<div class="row">
    <section class="estrategia w-100">
        <article class="row">
            <article class="row t" style="justify-content: center;">
                <h4>Creación de estrategia</h4>
                <h5>Edición Entorno competitivo</h5>
                <p class="text-center col-md-10"><b>Al crear un nuevo plan estratégico, se actualizarán los datos contenidos en el plan de seguimiento.
                <br>Te recomendamos descargar tu informe de plan estratégico, antes de crear el nuevo plan.</b></p>
                <!--  SEMI MENU de edición-->
                <div class="container" style="margin-bottom: 25px;">
                    <div class="row">
                        <div class="col text-center ng-star-inserted"><div class="ng-star-inserted"><div id="pintar" class="pointer" (click)="irAPropiedadesE()">Editar Propiedades estratégicas</div></div></div>
                        <div class="col text-center ng-star-inserted"><div class="ng-star-inserted"><div id="pintar" class="pointer" (click)="irASistemaI()">Editar Sistemas</div></div></div>
                    </div>
                </div>
                <p class="text-center col-md-10">Stive analiza el entorno local y global periódicamente para determinar el puntaje de 4 grupos de fuerzas externas que pueden favorecer o afectar tu negocio. Revisa tu entorno competitivo antes de continuar a crear tu estrategia de negocios.</p>
                <img class="imgoceano" src="../../../../assets/img/escala_oceano.png" height="30">
            </article>
            <div class="col-xl-6">
                <article class="row" style="justify-content: center;">
                    <div class="w-100 text-center" *ngIf="videoReady; else elseBlock">
                        <iframe class="responsive-iframe" [src]="videoURL" frameborder="4" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                </iframe>
                    </div>
                    <div class="w-100">
                        <ng-template #elseBlock>
                            <mat-spinner style="margin-left: 40%;"></mat-spinner>
                        </ng-template>
                    </div>
                    <p class="text-center col-md-10">{{descripcion_video}}</p>
                </article>
            </div>
            <div class="col-xl-6 contenedor">
                <article class="row">
                    <div class="cardpadre" *ngFor="let e of fuerzasList; let i = index">
                        <div class="cardnegocio text-center">
                            <h3>{{e.tipoFuerza}}</h3><strong>{{e.promedio}}</strong></div>
                        <div class="row">
                            <div class="col-md-6" *ngFor="let el of fuerzasList[i].fuerzasCompetitivasList; let j= index">
                                <div class="itemcard">
                                    <img src="{{el.icono}}" alt="" class="img">
                                    <p>{{el.fuerza}}</p>
                                    <h4>{{el.valor}}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <div class="offset-md-3 col-md-6 mb-3 text-center">
                    <button class="btn btn-primary" id="btn-entorno-competitivo" (click)="siguiente()">Continuar</button>
                    <button type="submit" class="btn btn-primary" (click)="dashboard()">
                        <mat-icon matSuffix id="iconoVideo">keyboard_backspace</mat-icon>
                        Volver al dashboard
                    </button>
                </div>
            </div>
        </article>
    </section>
</div>
<app-footer-layout></app-footer-layout>
<ngx-spinner></ngx-spinner>
