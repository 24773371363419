import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emprendoderes',
  templateUrl: './emprendoderes.component.html',
  styleUrls: ['./emprendoderes.component.css']
})
export class EmprendoderesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
