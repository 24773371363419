<app-header-layout></app-header-layout>
<div class="row mb-5">
    <div class="container">
        <div class="formularioregistro mt-5">
            <h4 class="titulo-pagina">Perfil Proyecto</h4>
            <!--SEMI - stepper-->
            <app-footer-layout style="width: 100%;"></app-footer-layout>
            <article class="row pc">

                <div class="col-md-8 text-center">
                    <p>Para registrar tu proyecto en Stive es necesario llenar el siguiente formulario. Hablaremos de la industria en la que 
                        se encuentra, definiremos tu negocio y conoceremos su momento actual.
                    </p>
                </div>
                <div class="col-md-4 text-center is">
                    <button type="submit" class="btn btn-primary" (click)="dashboard()">
                        <mat-icon matSuffix id="iconoVideo">keyboard_backspace</mat-icon>
                        Volver al dashboard
                    </button><br><br>
                    <button type="submit" style="margin-top: -30px!important;" class="btn btn-primary fondo-boton-video" (click)="openVideoIntroductorio()">
                        <mat-icon matSuffix id="iconoVideo">video_library</mat-icon>
                        Video de apoyo
                    </button>
                </div>
            </article>
            <form class="registroint " [formGroup]="createForm" (ngSubmit)=" onCreateFormSubmitted()">
<!-- SECCIOn 1-->
            <h3 *ngIf="mostrarSeccion1">DATOS GENERALES</h3>
            <!--nombre-->
            <div class="row centrar-item" *ngIf="mostrarSeccion1">
                <div class="col-md-6 mb-3 ">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Nombre de proyecto </mat-label>
                        <input matInput  type="text" placeholder="Nombre del proyecto" id="exampleInputName1" formControlName="projectName" [ngClass]="{ 'is-invalid': submitted && f.projectName.errors }">
                    </mat-form-field>
                </div>
            </div>
            <!--pais-->
            <div class="row centrar-item" *ngIf="mostrarSeccion1">
                <div class="col-md-6 mb-3">
                    <div class="input-group ">
                        <mat-form-field class="w-100" id="inputGroupSelect01" appearance="outline">
                            <mat-label>País</mat-label>
                            <mat-select formControlName="country">
                                <mat-option *ngFor="let country of listCountry" [value]="country.nombre" >
                                       {{country.nombre}}
                               </mat-option>
                             </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!--DS-->
            <div class="row centrar-item" *ngIf="mostrarSeccion1">
                <div class="col-md-6 mb-3">
                    <mat-form-field class="input-group" appearance="outline">
                        <mat-label>Descripción del proyecto</mat-label>
                        <textarea  matInput placeholder="Descripcion de proyecto" formControlName="description">
                    </textarea>
                    </mat-form-field>
                </div>
            </div>
            <!--industrias-->
            <div class="row centrar-item" *ngIf="mostrarSeccion1">
                <div class="col-md-6 mb-3">
                    <div class="input-group ">
                        <mat-form-field class="w-100" id="inputGroupSelect02" appearance="outline">
                            <mat-label>Industria</mat-label>
                            <mat-select formControlName="industry" (selectionChange)="onChangeIndustry($event.value)">
                                <mat-option *ngFor="let industry of listIndustry" [value]="industry.nombre">
                                    {{industry.nombre}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!--subindustria-->
            <div class="row centrar-item" *ngIf="mostrarSeccion1">
                <div class="col-md-6 mb-3">
                    <div class="input-group ">
                        <mat-form-field class="w-100" id="inputGroupSelect03" appearance="outline">
                            <mat-label>Sub-industria</mat-label>
                            <mat-select formControlName="subIndustry" (selectionChange)="onChangeSubIndustry($event.value)">
                                <mat-option *ngFor="let subIndustry of listSubIndustry" [value]="subIndustry.nombre">
                                    {{subIndustry.nombre}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
<!-- SECCIOn 2-->
            <h3 id="div-focus-top" *ngIf="mostrarSeccion2">DEFINICIÓN DEL NEGOCIO</h3>
            <div class="row">
                <!--En mi proyecto...-->
                <div class="col-md-6 mb-3">
                    <div class="sub-tirulo item-right" *ngIf="mostrarSeccion2">En {{createForm.controls.projectName.value}} somos un</div>
                </div>
                <div class="col-md-6 mb-3">
                    <div class="input-group ">
                        <mat-form-field class="w-100" id="inputGroupSelect06" appearance="outline">
                            <mat-label>Modelo de negocio</mat-label>
                            <mat-select formControlName="model">
                                <mat-option *ngFor="let model of listModel" [value]="model.nombre">
                                    {{model.nombre}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <!-- Trabajando con...-->
                <div class="col-md-6 mb-3">
                    <div class="sub-tirulo item-right" *ngIf="mostrarSeccion2">trabajando con</div>
                </div>
                <div class="col-md-6 mb-3">
                    <div class="input-group ">
                        <mat-form-field class="w-100" id="inputGroupSelect07" appearance="outline">
                            <mat-label>Oferta principal</mat-label>
                            <mat-select formControlName="product">
                                <mat-option *ngFor="let product of listProduct" [value]="product.nombre">
                                    {{product.nombre}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <!-- Buscando c...-->
                <div class="col-md-6 mb-3">
                    <div class="sub-tirulo item-right" *ngIf="mostrarSeccion2">buscando conectar y vender a</div>
                </div>
                <div class="col-md-6 mb-3">
                    <div class="input-group ">
                        <mat-form-field class="w-100" id="inputGroupSelect04" appearance="outline">
                            <mat-label>Cliente principal</mat-label>
                            <mat-select formControlName="business" (selectionChange)="onChangeBusiness($event.value)">
                                <mat-option *ngFor="let business of listBusiness" [value]="business.nombre">
                                    {{business.nombre}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <!-- Con un enfoque...-->
                <div class="col-md-6 mb-3">
                    <div class="sub-tirulo item-right" *ngIf="mostrarSeccion2">con un enfoque de negocio</div>
                </div>
                <div class="col-md-6 mb-3">
                    <div class="input-group ">
                        <mat-form-field class="w-100" id="inputGroupSelect05" appearance="outline">
                            <mat-label>Enfoque</mat-label>
                            <mat-select formControlName="focus">
                                <mat-option *ngFor="let focus of listFocus " [value]="focus.nombre">
                                    {{focus.nombre}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row itmqproject" *ngIf="mostrarSeccion2">
                <div class="offset-md-3 col-md-6 mb-3 text-center">
                    <button type="submit" id="btn-registrar.proyecto" class="btn btn-primary mt-3" [disabled]="disabled">Inscribir proyecto</button>
                </div>
            </div>
            </form>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
