import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-funcionamiento-abajo',
  templateUrl: './funcionamiento-abajo.component.html',
  styleUrls: ['./funcionamiento-abajo.component.css']
})
export class FuncionamientoAbajoComponent implements OnInit {

 
  constructor(public router:Router) { 

   }

   ngOnInit(): void {
    
  }

  irAInicio(){
    this.router.navigate(['/init-page']);
  }

  irComoFunciona(){
    this.router.navigate(['/como-funciona']);
  }
 
  irAYoutube(){
    window.open("https://www.youtube.com/@innside-grupoventura34", "_blank");
  }

  irAInsta(){
    
  }

}
