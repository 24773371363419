<div class="row h-100">
    <div class="container h-100">
        <div class="logo">   
            <img src="../../assets/img/logostive.png" alt="Stive"  style="width: 150px;height: 61px;">
        </div>
        <div class="formularioregistro">
            <h3 *ngIf="mostrarSeccion1">DATOS PERSONALES</h3>
            <div class="content1 sub-tirulo centrar-item margen-div-video" *ngIf="mostrarSeccion1">
                Ingresa tus datos personales para crear tu cuenta
                               <button type="submit" class="btn btn-primary fondo-boton-video" style="margin-top: 15px;width: 18%;" (click)="abrirVideoDeApoyo()">
                    <mat-icon matSuffix id="iconoVideo" style="float: left;">video_library</mat-icon>
                         Video de apoyo
                </button>
            </div>
            <br>
            <form class="registroint" [formGroup]="informationForm" (ngSubmit)="onInformationSubmit()">
<!--SECCION UNO -->
                <!--Nombre-->
                <div class="row centrar-item" *ngIf="mostrarSeccion1">
                    <div class="col-md-6 mb-3">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Nombre</mat-label>
                            <input matInput type="text" formControlName="name" placeholder="Nombre" (change)="validarDatosPersonales()"
                            [ngClass]="{ 'is-invalid': informationSubmitted && r.name.errors }">
                        </mat-form-field>
                        <span *ngIf="informationSubmitted && r.name.errors" class="error">
                            <span *ngIf="r.name.errors.required">Nombre es requerido</span>
                            <span *ngIf="r.name.errors.pattern">Nombre solo permite letras</span>
                        </span>
                    </div>
                </div>
                <!--Apellido-->
                <div class="row centrar-item" *ngIf="mostrarSeccion1">
                    <div class="col-md-6 mb-3">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Apellido</mat-label>
                            <input matInput type="text" formControlName="surname" placeholder="Apellido" (change)="validarDatosPersonales()"
                            [ngClass]="{ 'is-invalid': informationSubmitted && r.surname.errors }">
                        </mat-form-field>
                        <span *ngIf="informationSubmitted && r.surname.errors" class="error">
                            <span *ngIf="r.surname.errors.required">Apellido es requerido</span>
                            <span *ngIf="r.surname.errors.pattern">Apellido solo permite letras</span>
                        </span>
                    </div>
                </div>
                <!--Fecha-->
                <div class="row centrar-item" *ngIf="mostrarSeccion1">
                    <div class="col-md-6 mb-3">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Fecha de nacimiento</mat-label>
                            <input matInput type="text" class="form-control" formControlName="age" (change)="validarDatosPersonales()"
                                    style="height: 50px; margin-top: -20px;align-items: flex-end;" 
                                   placeholder="Fecha de nacimiento" onfocus="(this.type='date')" [ngClass]="{ 'is-invalid': dateSubmitted}">
                        </mat-form-field>
                        <span *ngIf="dateSubmitted || dateSubmitted2" class="error">
                            <span *ngIf="dateSubmitted">Fecha vacía o Formato inválido</span>
                            <span *ngIf="dateSubmitted2"> La fecha ingresada no está entre 13 y 100 años</span>
                        </span>
                    </div>
                </div>
                <!--Género-->
                <div class="row centrar-item" *ngIf="mostrarSeccion1">
                    <div class="col-md-6 mb-3">
                        <div class="input-group">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Género</mat-label>
                                <mat-select class="text-center" id="inputGroupSelect01" formControlName="gender" (selectionChange)="validarDatosPersonales()"
                                [ngClass]="{ 'is-invalid': informationSubmitted && r.gender.errors}" style="height: 28px;">
                                    <mat-option *ngFor="let g of listaGeneros" [value]="g.codigo">{{g.nombre}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <span *ngIf="informationSubmitted && r.gender.errors" class="error">
                                <span *ngIf="r.gender.errors.required">Género es requerido</span>
                            </span>
                        </div>
                    </div>
                </div>
                <!--Teléfono-->
                <div class="row centrar-item" *ngIf="mostrarSeccion1">
                    <div class="col-md-6 mb-3">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Teléfono de contacto</mat-label>
                            <input matInput type="number" placeholder="Teléfono de contacto" formControlName="phone" (change)="validarDatosPersonales()"
                            [ngClass]="{ 'is-invalid': informationSubmitted && r.phone.errors }">
                        </mat-form-field>
                        <span *ngIf="informationSubmitted && r.phone.errors" class="error">
                            <span *ngIf="r.phone.errors.required">Teléfono es requerido</span>
                            <span *ngIf="r.phone.errors.pattern">Teléfono no es válido</span>
                        </span>
                    </div>
                </div>
                <!--País-->
                <div class="row centrar-item" *ngIf="mostrarSeccion1">
                    <div class="col-md-6 mb-3">
                        <div class="input-group ">
                            <mat-form-field appearance="outline" class="w-100 ">
                                <mat-label>País</mat-label>
                                <mat-select class="text-center" id="inputGroupSelect02" formControlName="country"
                                [ngClass]="{ 'is-invalid': informationSubmitted && r.country.errors}"
                                (selectionChange)="onChangePais($event.value);validarDatosPersonales();">
                                    <mat-option *ngFor="let p of listaPaises" [value]="p.codigo">{{p.nombre}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <span *ngIf="informationSubmitted && r.country.errors" class="error">
                                <span *ngIf="r.country.errors.required">País es requerido</span>
                            </span>
                        </div>
                    </div>
                </div>
                <!--Ciudad-->
                <div class="row centrar-item" *ngIf="mostrarSeccion1">
                    <div class="col-md-6 mb-3">
                        <div class="input-group ">
                            <mat-form-field appearance="outline" class="w-100 ">
                                <mat-label>Ciudad</mat-label>
                                <input matInput type="text" name="inptNombreCuenta" formControlName="city" [matAutocomplete]="autoNombreCuenta">
                                <mat-autocomplete #autoNombreCuenta="matAutocomplete" (optionSelected)="validarDatosPersonales()">
                                    <mat-option *ngFor="let item of filteredNombreCuentas | async" value="{{item.nombre}}">
                                        {{item.nombre}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <span *ngIf="informationSubmitted && r.city.errors" class="error">
                                <span *ngIf="r.city.errors.required">Ciudad es requerida</span>
                            </span>
                        </div>
                    </div>
                </div><br>
<!--SECCION DOS -->
                <h3 id="div-focus-top" *ngIf="mostrarSeccion2">CUENTA Y SUSCRIPCIÓN</h3>
                <div id="tries" class="content1 sub-tirulo centrar-item" *ngIf="mostrarSeccion2">
                    Para crear tu cuenta debes aceptar los términos de Stive y confirmar tu subscripción.
                </div>
                <div class="row centrar-item" *ngIf="mostrarSeccion2">
                    <div class="form-group form-check checksesion">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1" formControlName="terms"
                        [ngClass]="{ 'is-invalid': informationSubmitted && r.terms.errors }" style="height: 30px !important;width: 30px !important;">
                        <label class="form-check-label" for="exampleCheck1" style="padding-left: inherit;">Acepto los
                            <button type="button" class="btn btn-tyc" (click)="terms();">Términos y Condiciones</button>
                        </label>
                        <span *ngIf="this.informationForm.controls['terms'].value===false" class="error">
                            <br><span>Este campo es requerido</span>
                        </span>
                    </div>
                </div>
                <div class="row centrar-item" *ngIf="mostrarSeccion2">
                    <div class="col-md-6 mb-3">
                        <div class="input-group ">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>¿Cómo conoció a Stive?</mat-label>
                                <mat-select class="text-center" id="inputGroupSelect04" formControlName="ccas"
                                    [ngClass]="{ 'is-invalid': informationSubmitted && r.ccas.errors}">
                                    <mat-option *ngFor="let p of listaStive" [value]="p.opcion">{{p.descripcion}}</mat-option>
                                </mat-select>  
                            </mat-form-field>
                            <span *ngIf="informationSubmitted && r.ccas.errors" class="error">
                                <span *ngIf="r.ccas.errors.required">Este campo es requerido</span>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="content1 sub-tirulo centrar-item" *ngIf="mostrarSeccion2">
                    Ingresa el código de Stive que fue enviado a tu correo por una de nuestras instituciones aliadas.
                 </div><br>
                 <div class="content1 sub-tirulo centrar-item" *ngIf="mostrarSeccion2">
                    *si no tienes el código puedes adquirir un plan de Stive en la pantalla anterior.
                  </div><br>
                 
                <div class="row centrar-item" *ngIf="mostrarSeccion2">
                    <div class="col-md-6 mb-3">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Código de ingreso a Stive</mat-label>
                            <input 
                                #tooltip="matTooltip"
                                matTooltip="Ingresa tu código de Stive que se envió a tu correo"
                                matTooltipPosition="above"
                                matTooltipHideDelay="450"
                                aria-label="mat Tooltip"
                                (click)="tooltip.toggle()"
                                matInput type="text" placeholder="Código de ingreso a Stive" formControlName="cis">
                        </mat-form-field>
                        <span *ngIf="informationSubmitted && r.cis.errors" class="error">
                            <span *ngIf="r.cis.errors.required">Código de Stive es requerido</span>
                        </span>
                    </div>
                </div>
                    <!-- BOTON REGISTRAR -->
                <div class="row centrar-item" *ngIf="mostrarSeccion2">
                    <div class="col-md-6 mb-3 centrar-item">
                        <button type="submit" id="btn-crear-perfil" class="btn btn-primary" style="width: 70%;" [disabled]="disabled">Crear perfil</button>
                    </div>
                </div>
                <div *ngIf="mostrarBRS"><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br></div>
            </form>
        </div>
    </div>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>