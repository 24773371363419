<app-header-layout></app-header-layout>
<div class="row">
    <div class="container">
        <section class="estrategia">
            <article class="row">
                <h4>Perfil Proyecto</h4>
                <!--SEMI - stepper-->
                <app-footer-layout style="width: 100%;"></app-footer-layout>
                <h6>Los enunciados de capacidades del emprendimiento permiten conocer su nivel competitivo actual. Selecciona de 0 a 10 el estado de tu proyecto.</h6>
            </article>
            <article class="row inf">
                <div class="col-md-6 col-lg-4 escalaimg">
                    <img src="../../../../assets/img/escala.png" width="310" height="70">
                </div>
                <div class="col-md-6 col-lg-4 escaladesc">
                    <p style="margin-bottom: 5px;text-align: justify">0 = Bajo</p>
                    <p style="margin-bottom: 5px;text-align: justify">5 =  Mediano</p>
                    <p style="text-align: justify">10 = Alto</p>
                </div>
                <div class="col-md-12 col-lg-4 text-center is">
                    <button type="submit" class="btn btn-primary" (click)="dashboard()">
                        <mat-icon matSuffix id="iconoVideo">keyboard_backspace</mat-icon>
                        Volver al dashboard
                    </button>
                    <button type="submit" class="btn btn-primary fondo-boton-video" style="margin-top: 15px;" (click)="openVideoIntroductorio()">
                        <mat-icon matSuffix id="iconoVideo">video_library</mat-icon>
                        Video de apoyo
                    </button>
                </div>
            </article>
            <form [formGroup]="createForm" (ngSubmit)="onCreateFormSubmitted()">
                <article class="container">
                    <div class="row">
                        <div class="col-md-5 offset-md-1" *ngFor="let c of capacidadesFull; let i=index">
                            <p *ngIf="c.pregunta.codigo=='COTPPRSPCP10'" 
                                #tooltip="matTooltip" matTooltipPosition="above"
                                (click)="tooltip.toggle()"
                                matTooltipHideDelay="250"
                                [matTooltip]="c.pregunta.codigo=='COTPPRSPCP10' ? TT_COTPPRSPCP10 : null">{{c.pregunta.pregunta}}</p>
                            <p *ngIf="c.pregunta.codigo!='COTPPRSPCP10'">{{c.pregunta.pregunta}}</p>
                            <div class="container-slider">
                                <mat-slider thumbLabel value="{{c.valor}}" min="0" max="10" #matslider (input)="onInputChange($event,c)"></mat-slider>
                                <span style="font-size: 8;color:#6ABFD4;">{{c.valor}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="offset-md-3 col-md-6 mb-3 text-center">
                        <button type="submit" id="btn-capacidades" class="btn btn-primary mt-3" [disabled]="disabled" >Continuar</button>
                    </div>
                </article>
            </form>
        </section>
    </div>
</div>
<ngx-spinner></ngx-spinner>
