import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmprendedorService, ListasService } from  '@app/_services';
import { AlertService } from '@app/_modal/alert/alert.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { mensaje} from '@app/_const/alert_error_message';
import { StateService } from '@app/_helpers/state.service';
import { VideoService } from '@app/_modal/video-introductorio/video-introductorio.service';
import { MatSliderChange } from '@angular/material/slider';

@Component({
  selector: 'app-tipoemprendedor-edicion',
  templateUrl: './tipoemprendedor-edicion.component.html',
  styleUrls: ['./tipoemprendedor-edicion.component.css']
})
export class TipoemprendedorEdicionComponent implements OnInit {

   //Definimos la variable del formulario
   createForm: FormGroup = this.formBuilder.group({});

   submitted = false;
   preguntas:any[] = [];
   item:any[] = [];
   value:any;
   codigopais:string;
   codigoEmprendedor:String;
   updateEntrepreneur: any;
   tipo:string = "Emprendedor";
   subTipo:string = "TipoEmprendedor";
   correoEmprendedor:any;
   disabled: boolean = false;
   preguntasFull:any[] = [];
 
   constructor(
     //instanciamos la variable para la construccion del formulario
     private formBuilder: FormBuilder,
     private listaServ: ListasService,
     private entrepreneur: EmprendedorService,
     private alertSrv: AlertService,
     private spinner: NgxSpinnerService,
     private state: StateService,
     private router: Router,
     private videoIntroductorio: VideoService
   ) { }
 
   ngOnInit(): void {
     this.state.updateBasico('footer','emprendedor');
     //obtenemos los datos del setionStorage
     this.codigopais = this.state.getInfo("basico", "codPaisEmprendedor");
     this.codigoEmprendedor = this.state.getInfo("basico", "idEmprendedor");
     //llamamos a nustra función y le pasamos los parametros
     this.getPreguntasFull(this.codigopais, this.tipo, this.subTipo);
   }
 
   //metodo para enviar información
   onCreateFormSubmitted(){
     this.submitted = true;
     //paramos aca si nuestro formulario no cumple con las validaciones
     if(this.createForm.invalid) return;
     this.onSubmit();
   }
 
   onSubmit(){
     this.disabled = true;
     this.spinner.show();
     //creamos nuestro objeto json
     const preguntasList = [];
     for(let i=0; i<this.preguntasFull.length; i++){
       preguntasList.push({
         "codigo": this.preguntasFull[i].pregunta.codigo,
         "valor": this.preguntasFull[i].valor
       });
     }
     this.updateEntrepreneur = {
       "codigoEmprendedor": this.codigoEmprendedor,
       "tipoEmprendedorRespuestasList": preguntasList
     };
     console.log(this.updateEntrepreneur);
     //actualizamos el proyecto
     this.entrepreneur.setActualizar(this.updateEntrepreneur).subscribe(
       response => {
         this.spinner.hide();
         this.disabled = false;
         if(response.error && response.error.codigo){
           this.alertSrv.showAlert(response.error.mensaje, "Error");
           return;
         }
         //Cambiar estado
         this.state.updateEmprendedor("Tipo de emprendedor");
         this.router.navigate(['registered/dashboard']);
         this.consultarEmprendedor();
        
       },
       e => {
         this.spinner.hide();
         this.disabled = false;
         this.alertSrv.showAlert(mensaje.error, "Error");
         console.log(e);
       }
       )
     }
 
     //obtenemos la lista de preguntas
   getPreguntas(codigopais:string, tipo:string, subTipo:string){
     this.spinner.show();
     this.listaServ.getListaPreguntas({"codigopais":codigopais, "tipo":tipo, "subTipo":subTipo}).subscribe(
       res=>{
         this.spinner.hide();
         this.preguntas = res.datos.preguntasList;
         this.createFormValidation(this.preguntas.length);
       }, e=>{
         this.spinner.hide();
         this.alertSrv.showAlert(mensaje.error, "Error");
         console.log(e);
       } 
     );
   }

   getPreguntasFull(codigopais:string, tipo:string, subTipo:string){
    let json = {"codigo": this.codigoEmprendedor}
    //mostramos el spinner en la pantalla de carga
    this.spinner.show();
    this.listaServ.obtenerTipoEmprendedor(json).subscribe(
      res=>{
        this.preguntasFull = res.datos.tipoEmprendedor.preguntasTipoEmprendedorList;
        this.createFormValidation(this.preguntasFull.length);
        this.spinner.hide();
      },
      e=>{
        this.spinner.hide();
        this.alertSrv.showAlert(mensaje.error, "Error");
        console.log(e);
      }
    );
  }
 
   createFormValidation(length: number){
     const arrayFormGroup:any = {};
     for(let i=1; i<=length; i++){
       arrayFormGroup["pregunta"+i] = [this.preguntasFull[i-1].valor, Validators.required];
     }
     this.createForm = this.formBuilder.group(arrayFormGroup);
   }
 
   //abrimos el pop-up de video introductorio
   openVideoIntroductorio(){
     this.videoIntroductorio.showAlert("TipoEmprendedor");
   }
 
   consultarEmprendedor(){
     this.correoEmprendedor = {
       "correo": this.state.getInfo("basico", "emailEmprendedor")
     };
     this.entrepreneur.consultEmprendedor(this.correoEmprendedor).subscribe(
       response =>{
         this.spinner.hide();
         if(response.error && response.error.codigo){
           this.alertSrv.showAlert(response.error.mensaje, "Error");
           return;
         }
         this.state.updateEnterpreneur("datos", response.datos);
       },
       e=>{
         this.spinner.hide();
         this.alertSrv.showAlert(mensaje.error, "Error");
         console.log(e);
       }
     )
   }

   dashboard(){
    this.router.navigate(['registered/dashboard']);
  }
  
  irACirculo(){
    this.router.navigate(['editar/circulo-vida']);
  }

  irACompetencias(){
    this.router.navigate(['editar/competencias']);
  }

  irATipoEmprendedor(){
    this.router.navigate(['editar/tipo-emprendedor']);
  }

  onInputChange(event: MatSliderChange, preguntaFront) {
    let preguntasCVTempo:any[] = [];
    for(let i=0; i<=this.preguntasFull.length; i++){
      if(this.preguntasFull[i] && this.preguntasFull[i].pregunta) {
        if(this.preguntasFull[i].pregunta.codigo != preguntaFront.pregunta.codigo){
          preguntasCVTempo.push(this.preguntasFull[i]);
        }else{
          let nuevaPregunta = preguntaFront;
          nuevaPregunta.valor = event.value;
          preguntasCVTempo.push(nuevaPregunta);
        }
      }
    }
    this.preguntasFull = preguntasCVTempo;
  }

}
