<div>
    <h3 mat-dialog-title>{{data.title}}</h3>
    <div mat-dialog-content>
        <p>Gracias por utilizar Stive.</p>
        <p>
            Recuerda que tu suscripción a Stive es de un solo uso,
            por eso te recomendamos responder a conciencia ya
            que la información que registres no se puede modificar.
        </p>
    </div>
    <div mat-dialog-actions class="button-container">
        <button mat-stroked-button  mat-dialog-close class="button">Continuar</button>
    </div>
</div>
