import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ListasService, ProyectoService } from '@app/_services';
import { AlertService } from '@app/_modal/alert/alert.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { mensaje} from '@app/_const/alert_error_message';
import { StateService } from '@app/_helpers/state.service';
import { VideoService } from '@app/_modal/video-introductorio/video-introductorio.service';
import { MatSliderChange } from '@angular/material/slider';

const TRES = '3';
const COMPLETO = 'completado';
const CERO = 0;
@Component({
  selector: 'app-capacidades-edicion',
  templateUrl: './capacidades-edicion.component.html',
  styleUrls: ['./capacidades-edicion.component.css']
})
export class CapacidadesEdicionComponent implements OnInit {

  public TT_COTPPRSPCP10 : string =  "Marca:  Es un signo distintivo, para diferenciar y hacer únicos a los productos y/o servicios de una empresa frente al resto de competidores";
   //Definimos la variable del formulario
   createForm: FormGroup = this.formBuilder.group({});
  
   //variable para validar si el formulario ha sido enviado
   submitted = false;
  capacidades:any[] = [];
   item:any[] = [];
   value:any;
   codigopais:string;
   codigoProyecto:string;
   codigoEmprendedor:String;
   updateProject: any;
   tipo:string = "Proyecto";
   subTipo:string = "Capacidades";
   disabled: boolean = false;
   capacidadesFull:any[] = [];
   
 
   constructor(
     //instanciamos la variable para la construccion del formulario
     private formBuilder: FormBuilder,
     private listaServ: ListasService,
     private project: ProyectoService,
     private alertSrv: AlertService,
     private spinner: NgxSpinnerService,
     private state: StateService,
     private router: Router,
     private videoIntroductorio: VideoService
   ) { 
     
   }
 
   ngOnInit(): void {
     this.state.updateBasico('footer','proyecto');
     //obtenemos los datos del setionStorage
     this.codigoProyecto = this.state.getInfo("basico", "codProyecto");
     this.codigopais =this.state.getInfo("basico", "codPaisProyecto");
     this.codigoEmprendedor = this.state.getInfo("basico", "idEmprendedor");
     //llamamos a nustra función y le pasamos los parametros
     this.obtenerCapacidades();
   }
 
   //metodo para enviar información
   onCreateFormSubmitted(){
     this.submitted = true;
     //paramos aca si nuestro formulario no cumple con las validaciones
     if(this.createForm.invalid) return;
     this.onSubmit();
   }
 
   onSubmit(){
     this.disabled = true;
     this.spinner.show();
     //creamos nuestro objeto json
     const capacidadesList = [];
     for(let i=0; i<this.capacidadesFull.length; i++){
       capacidadesList.push({
         "codigo": this.capacidadesFull[i].pregunta.codigo,
         "valor": this.capacidadesFull[i].valor
       });
     }
     this.updateProject = {
       "codigoEmprendedor": this.codigoEmprendedor,
       "codigoProyecto": this.codigoProyecto,
       "capacidadesList": capacidadesList
     };
     //actualizamos el proyecto
    this.project.setActualizar(this.updateProject).subscribe(
     response => {
         this.spinner.hide();
         this.disabled = false;
         if(response.error && response.error.codigo){
           this.alertSrv.showAlert(response.error.mensaje, "Error");
           return;
         }
         //Cambiar estado
         this.state.updateProyecto("Capacidad del negocio");
         let estadoClasificacionDelProyecto = this.state.getInfo("basico", "estadoClasificacionDelProyecto");
         estadoClasificacionDelProyecto.filter(item => item.posicion === TRES)[CERO].estado = COMPLETO;
         this.state.updateBasico('estadoClasificacionDelProyecto',  estadoClasificacionDelProyecto);
         this.router.navigate(['editar/propuesta']);
     },
     e => {
       this.spinner.hide();
       this.disabled = false;
       this.alertSrv.showAlert(mensaje.error, "Error");
       console.log(e);
     }
   )
   }
 
   //obtenemos la lista de capacidades
   getCapacidades(codigopais:string, tipo:string, subTipo:string){
     this.spinner.show();
     this.listaServ.getListaPreguntas({"codigopais":codigopais, "tipo":tipo, "subTipo":subTipo}).subscribe(
       res=>{
         this.spinner.hide();
         this.capacidades = res.datos.preguntasList;
         this.createFormValidation(this.capacidades.length);
         console.log(this.capacidades);
       }, e=>{
         this.spinner.hide();
         this.alertSrv.showAlert(mensaje.error, "Error");
         console.log(e);
       } 
     );
   }
 
   createFormValidation(length: number){
     const arrayFormGroup:any = {};
     for(let i=1; i<=length; i++){
       arrayFormGroup["pregunta"+i] = [0, Validators.required];
       arrayFormGroup["pregunta"+i] = [this.capacidadesFull[i-1].valor, Validators.required];
     }
     this.createForm = this.formBuilder.group(arrayFormGroup);
 
   }
 
   //abrimos el pop-up de video introductorio
   openVideoIntroductorio(){
     this.videoIntroductorio.showAlert("Capacidades");
   }

   dashboard(){
    this.router.navigate(['registered/dashboard']);
  }

  obtenerCapacidades(){
    let json = {"codigoEmprendedor": this.codigoEmprendedor}
    //mostramos el spinner en la pantalla de carga
    this.spinner.show();
    this.listaServ.obtenerCapacidades(json).subscribe(
      res=>{
        this.spinner.hide();
        this.capacidadesFull = res.datos.capacidades.preguntasCapacidadesList;
        this.createFormValidation(this.capacidadesFull.length);
      },
      e=>{
        this.spinner.hide();
        this.alertSrv.showAlert(mensaje.error, "Error");
        console.log(e);
      }
    );
  }

  irAProyecto(){
    this.router.navigate(['editar/proyecto']);
  }

  irAFase(){
    this.router.navigate(['editar/fase-momento']);
  }

  irACapacidades(){
    this.router.navigate(['editar/capacidades']);
  }

  irAOceano(){
    this.router.navigate(['editar/propuesta']);
  }

  onInputChange(event: MatSliderChange, preguntaFront) {
    let preguntasCVTempo:any[] = [];
    for(let i=0; i<=this.capacidadesFull.length; i++){
      if(this.capacidadesFull[i] && this.capacidadesFull[i].pregunta) {
        if(this.capacidadesFull[i].pregunta.codigo != preguntaFront.pregunta.codigo){
          preguntasCVTempo.push(this.capacidadesFull[i]);
        }else{
          let nuevaPregunta = preguntaFront;
          nuevaPregunta.valor = event.value;
          preguntasCVTempo.push(nuevaPregunta);
        }
      }
    }
    this.capacidadesFull = preguntasCVTempo;
  }

}
