import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProyectoService } from '@app/_services';
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { mensaje} from '@app/_const/alert_error_message';
import { AlertService } from '@app/_modal/alert/alert.service';
import { StateService } from '@app/_helpers/state.service';
import { VideoService } from '@app/_modal/video-introductorio/video-introductorio.service';

const COMPLETO = 'completado';
const DOS = '2';
const CERO = 0;
@Component({
  selector: 'app-fase-momento-edicion',
  templateUrl: './fase-momento-edicion.component.html',
  styleUrls: ['./fase-momento-edicion.component.css']
})
export class FaseMomentoEdicionComponent implements OnInit {

  idFase:any = "";
  restriccion:any = "";

  momentoForm: FormGroup;
  momentoSubmitted = false;
  answerSubmitted = false;
  updateProject: any;
  codigoProyecto:string;
  codigoEmprendedor:String;

  pregunta:any = {};
  listaPreguntas:any[] =  [];
  listaMomentos:any[] = [];
  boton:any = "btn btn-terceary";
  disabled: boolean = false;
  momentoDB: string = "";
  momentoDBStr: string = "";
  faseDB: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private proyectoServ: ProyectoService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private alertSrv: AlertService,
    private state: StateService,
    private router: Router,
    private videoIntroductorio: VideoService
  ) { }

  ngOnInit(): void {
    this.state.updateBasico('footer','proyecto');
    //obtenemos los datos del setionStorage
    this.codigoProyecto = this.state.getInfo("basico", "codProyecto");
    this.codigoEmprendedor = this.state.getInfo("basico", "idEmprendedor");
    
    this.momentoForm = this.formBuilder.group({
      momento: ['', Validators.required]
    });
     
    this.getMomentos();
    this.pregunta = this.getPreguntas();
    //--
    this.consultarProyecto();
  }

  get r() { return this.momentoForm.controls; }

  onMomentoSubmit() {
    this.momentoSubmitted = true;
    if (this.momentoForm.invalid || this.idFase.substring(0,2)!="FS") {
        this.restriccion = "Aún no has llegado a una fase de desarrollo";
        return;
      }else{
        this.disabled = true;
        this.spinner.show();
        let datosFase = {
          idFase: this.idFase,
          codigoMomento: this.momentoForm.value.momento
        }
        console.log(datosFase);
        this.updateProject = {
          "codigoEmprendedor": this.codigoEmprendedor,
          "codigoProyecto": this.codigoProyecto,
          "codigoFase": datosFase.idFase,
          "codigoMomento": datosFase.codigoMomento
        }
        console.log(this.updateProject);
        this.proyectoServ.setActualizar(this.updateProject).subscribe(
          response => {
            this.disabled = false;
              this.spinner.hide();
              if(response.error && response.error.codigo){
                this.alertSrv.showAlert(response.error.mensaje, "Error");
                return;
              }
              //Cambiar estado
              this.state.updateProyecto("Fase y momento de negocio");
              let estadoClasificacionDelProyecto = this.state.getInfo("basico", "estadoClasificacionDelProyecto");
              estadoClasificacionDelProyecto.filter(item => item.posicion === DOS)[CERO].estado = COMPLETO;
              this.state.updateBasico('estadoClasificacionDelProyecto',  estadoClasificacionDelProyecto);
              this.router.navigate(['editar/capacidades']);
          },
          e => {
            this.disabled = false;
            this.spinner.hide();
            this.alertSrv.showAlert(mensaje.error, "Error");
            console.log(e);
          });
          this.restriccion = "";
      }
  }

  //Metodo para traer los momentos de negocio
  getMomentos(){
    this.spinner.show();
    this.proyectoServ.getListaMomentos().subscribe(
      res=>{
        this.listaMomentos = res.datos.momentosList;
        this.spinner.hide();
      }, e=>{
        this.spinner.hide();
        this.alertSrv.showAlert(mensaje.error, "Registro Exitoso");
        console.log(e);
      } 
    );
  }

  //Metodo para traer las preguntas y las fases
  getPreguntas(){
    this.spinner.show();
    return this.proyectoServ.getListaPreguntas().subscribe(
      res=>{
        this.listaPreguntas = res.datos.preguntas;
        this.pregunta = this.listaPreguntas[0];
        this.spinner.hide();
      }, e=>{
        this.spinner.hide();
        this.alertSrv.showAlert(mensaje.error, "Mensaje Error");
        console.log(e);
      } 
    );
  }

  reset(){
    this.pregunta = this.listaPreguntas[0];
    this.restriccion = "";
    this.idFase = "";
  }

  answer(idPregunta:any){
    if(idPregunta){
      if(idPregunta.substring(0,2)=="FS"){this.boton="btn btn-terceary-2"}
      this.idFase = idPregunta;
      this.pregunta = this.listaPreguntas.filter(p=>p.codigo==idPregunta)[0];
    }
  }

  //abrimos el pop-up de video introductorio
  openVideoIntroductorio(){
    this.videoIntroductorio.showAlert("FaseMomento");
  }

  dashboard(){
    this.router.navigate(['registered/dashboard']);
  }

  irAProyecto(){
    this.router.navigate(['editar/proyecto']);
  }

  irAFase(){
    this.router.navigate(['editar/fase-momento']);
  }

  irACapacidades(){
    this.router.navigate(['editar/capacidades']);
  }

  irAOceano(){
    this.router.navigate(['editar/propuesta']);
  }


  consultarProyecto(){
    let idUser = this.state.getInfo("basico", "idEmprendedor");
    this.proyectoServ.consultProyecto(idUser).subscribe(
      response =>{
        this.momentoDB    = response.datos.proyectos[0] && response.datos.proyectos[0].momento ? response.datos.proyectos[0].momento.codigo : '';
        this.momentoDBStr = response.datos.proyectos[0] && response.datos.proyectos[0].momento ? response.datos.proyectos[0].momento.momento : '';
        this.faseDB    = response.datos.proyectos[0] && response.datos.proyectos[0].fase ? response.datos.proyectos[0].fase.nombre : '';
        this.momentoForm.get('momento').value;
        this.momentoForm.controls['momento'].setValue(this.momentoDB);
        this.spinner.hide();
        if(response.error && response.error.codigo){
          this.alertSrv.showAlert(response.error.mensaje, "Error");
          return;
        }
        this.state.updateProject("datos", response.datos.proyectos[0]);
      },
      e=>{
        this.spinner.hide();
        this.alertSrv.showAlert(mensaje.error, "Error");
        console.log(e);
      }
    )
  }

}
