import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmprendedorService, ProyectoService } from '@app/_services';
import { AlertService } from '@app/_modal/alert/alert.service';
import { mensaje } from '@app/_const/alert_error_message';
import { StateService } from '@app/_helpers/state.service';
import { ListasService } from '@services/index';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ForgotPwdComponent } from "@app/login/popup/forgot-pwd/forgot-pwd.component";
import { LoginPlanesSubsComponent } from './popup/subs/login-planes-subs/login-planes-subs.component';


const COMPLETADO = 'completado';
const CERO = 0;
const CEROSTR = '0';
const UNOSTR = '1';
const DOSSTR = '2';
const TRESSTR = '3';
const CUATROSTR = '4';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  // Datos de prueba para pasar ID por URL
  idUsuario: String;

  loginForm: FormGroup;
  registerForm: FormGroup;
  loginSubmitted = false;
  registerSubmitted = false;
  vp: String = 'password';
  vpc = false;
  responseTactics:any[];

  videoURL:any;
  videoReady:boolean = false;

  estadoProyecto: any[];
  estadoEstrategias: any[];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private alertSrv: AlertService,
    private state: StateService,
    private proySrv: ProyectoService,
    private emprendedorServ: EmprendedorService,
    public listSrv: ListasService,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog

  ) { 

  }


  ngOnInit() {
    this.state.updateBasico('editando', false);
    this.crearEstadoProyectoYEstrategia();
    this.loginForm = this.formBuilder.group({
        useremail: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(8)]]
    });
    this.registerForm = this.formBuilder.group({
      useremail: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required,
                      Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.$@$!%*?&-_#])[A-Za-z\d.$@$!%*?&-_#].{8,}')]],
      password2: ['', Validators.required]
    }, {validator: this.passwordConfirming});
    this.getVideo();
  }

  /*Creamos un nuevo validador para confirmar pasdword con password2, para asi asegurarnos
  que el campo contraseña confirma con confirmar contraseña*/
  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('password2').value) {
        return {invalid: true};
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }
  get r() { return this.registerForm.controls; }

  onLoginSubmit() {

    this.loginSubmitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }
    const request = {
      "correo": this.loginForm.value.useremail,
      "password": this.loginForm.value.password
    };
    this.spinner.show();
    this.emprendedorServ.inicioSesionEmprendedor(request).subscribe(
      response => {
        // Eliminamos los datos del localStorage
        localStorage.removeItem('STATE');
        // Eliminamos datos guardados en state
        this.state.init();
        this.state.updateBasico('editando', false);
        if (response.datos !== null){
          this.state.updateBasico('subVencida',false);
          if (response.datos.error && response.datos.error.codigo && response.datos.error.codigo === 1033){
            //el emprendedor tiene la sub vencida
            this.state.updateBasico('subVencida',true);
          }
         
          // Cargamos los datos basicos del emprendedor
          this.state.updateBasico('idEmprendedor',      response.datos.basico.idEmprendedor);
          this.state.updateBasico('emailEmprendedor',   response.datos.basico.emailEmprendedor);
          this.state.updateBasico('codPaisEmprendedor', response.datos.basico.codPaisEmprendedor);
          this.state.updateBasico('nombres',            response.datos.basico.nombres);
          this.state.updateBasico('apellidos',          response.datos.basico.apellidos);
          //--
          this.state.updateBasico('tipoEmprendedor',                  response.datos.tipoSubscripcion.toLowerCase());
          this.state.updateBasico('diasValidosSub',                   response.datos.diasValidosSub);
          this.state.updateBasico('fechaVencimientoSub',              response.datos.fechaVencimientoSub);
          this.state.updateBasico('tipoSubscripcionModificadaActiva', response.datos.tipoSubscripcionModificadaActiva);
          this.state.updateBasico('codigoSub',                        response.datos.codigoSub);
          
          this.validarEstadoClasificacionDelProyecto(response.datos.basico.proyecto);

          // Cargamos los datos del emprendedor
          this.state.updateEnterpreneur('datos', response.datos.basico.emprendedor);
          // Verificamos el proceso del registro del emprendedor
          if (response.datos.basico.emprendedor.competenciasList !== null){
            this.state.updateEmprendedor('Competencias de emprendedor');
          }
          if (response.datos.basico.emprendedor.tipoEmprendedorList !== null){
            this.state.updateEmprendedor('Tipo de emprendedor');
          }
          if (response.datos.basico.nombreProyecto !== null){
            this.state.updateBasico('codProyecto', response.datos.basico.codProyecto);
            this.state.updateBasico('nombreProyecto', response.datos.basico.nombreProyecto);
            this.state.updateBasico('codPaisProyecto', response.datos.basico.codPaisProyecto);
            this.state.updateProyecto('Inscripción de proyecto');

            // Cargamos los datos del proyecto
            this.state.updateProject('datos', response.datos.basico.proyecto);
            // Verificamos el proceso del registro del proyecto
            if (response.datos.basico.proyecto.fase !== null && response.datos.basico.proyecto.momento !== null){
              this.state.updateProyecto('Fase y momento de negocio');
            }
            if (response.datos.basico.proyecto.perfilCapacidades.sistemaComercial !== null &&
              response.datos.basico.proyecto.perfilCapacidades.sistemaEmpresarial !== null &&
              response.datos.basico.proyecto.perfilCapacidades.sistemaInnovacion !== null ){
              this.state.updateProyecto('Capacidad del negocio');
            }
            if (response.datos.basico.proyecto.propuestaDeValor !== ''){
              this.state.updateProyecto('Posición competitiva');
            }
          }
          // Cargamos los datos de las tacticas
          this.getEstrategia();
        }else {
          this.spinner.hide();
          this.alertSrv.showAlert(response.error.mensaje, 'Error');
          return;
        }
      },
      e => {
        this.spinner.hide();
        this.alertSrv.showAlert(e.error.mensaje, 'Error');
        return;
      }
    );
  }

  getEstrategia(){
    const requestTactics = {
      "codigoEmprendedor": this.state.getInfo('basico', 'idEmprendedor'),
      "codigoProyecto": this.state.getInfo('basico', 'codProyecto')
    };
    this.proySrv.getTacticas(requestTactics).subscribe(
      response => {
        if (!(response.datos)){
          console.log('No se han priorizado los sistemas estrategicos');
        }else{
          this.responseTactics = response.datos.sistemasList;
          this.state.updateProject("plan", this.responseTactics);
          this.state.updateEstrategia("Prioridades estratégicas");
        }
        this.spinner.hide();
        this.router.navigate(['/registered/dashboard']);
      },
      error => {
        this.spinner.hide();
        this.alertSrv.showAlert(mensaje.error, 'Error');
        console.log(error);
      }
    );
    this.proySrv.consultarTacticas(requestTactics).subscribe(
      response => {
        const sdn = this.state.getInfo('estadoEstrategia');
        if (!(response.datos)) {
          console.log('No se han creado las tacticas para el proyecto');
          for (let i = 2; i < sdn.length; i++) {
            sdn[i].estado = false;
          }console.log(sdn);
        } else {
          const long = response.datos.sistemasList.length;
          for (let i = 0; i < long; i++) {
            if (sdn[i+1].nombre !== response.datos.sistemasList[i].nombre) {
              sdn[i+1].nombre = response.datos.sistemasList[i].nombre;
            }
            if (response.datos.sistemasList[i].clusterList.length > 0) {
              this.state.updateEstrategia(sdn[i+1].nombre);
            } else {
              sdn[i+1].estado = false;
            }
          }console.log(sdn);
          if (response.datos.sistemasList[response.datos.sistemasList.length-1].clusterList.length > 0) {
            this.state.updateProject("resumenTacticas", response.datos.sistemasList);
          }
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        this.alertSrv.showAlert(mensaje.error, 'Error');
        console.log(error);
      }
    );
  }

  onRegisterSubmit() {
    this.registerSubmitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }
    this.spinner.show();
    this.emprendedorServ.consultEmprendedor({ "correo": this.registerForm.value.useremail}).subscribe(
      response => {
        this.spinner.hide();
        if (response.datos !== null){
          this.alertSrv.showAlert('email ya existe', 'Error');
          return;
        }else {
          
          this.validarEstadoClasificacionDelProyecto(null);
          const newUser = {
            email: this.registerForm.value.useremail,
            password: this.registerForm.value.password
          };
          this.proySrv.nuevoUsuario = newUser;
          this.router.navigate(['/register']);
        }
      },
      e => {
        this.spinner.hide();
        this.alertSrv.showAlert(mensaje.error, 'Error');
        return;
      }
    );
  }

  viewPassword(){
    if (this.vpc === true){
      this.vp = 'password';
      this.vpc = false;
    }
    else {
      this.vp = 'text';
      this.vpc = true;
    }
  }

  getVideo(){
    this.listSrv.getVideos().subscribe(
      res=>{
        res.datos.videosList.filter(v => {
          if(v.clave === "DashboardEmpresarial"){
            this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(v.url);
            this.videoReady = true;
          }
        });
      },
      error=>{
        this.alertSrv.showAlert(mensaje.error, "Error");
      }
    );
  }

  recoverPwd() {
    this.dialog.open(ForgotPwdComponent, {
      panelClass: 'popup-ovlide-contrasenia',
      data: {
        title: 'Olvidé mi contraseña'
      }
    });
  }

  crearEstadoProyectoYEstrategia(){
    this.estadoEstrategias =  [
      {
        'posicion': '1',
        'nombre': 'Prioridades estratégicas',
        'activo': false,
        'route': 'registered/project/strategy',
        'color': '',
        'estado': 'incompleto'
      }
    ];
    this.estadoProyecto =  [
        {
          'posicion': '1',
          'nombre': 'Inscripción de proyecto',
          'activo': false,
          'route': 'registered/project/create',
          'color': '',
          'estado': 'incompleto'
        },
        {
          'posicion': '2',
          'nombre': 'Fase y momento de negocio',
          'activo': false,
          'route': 'registered/project/phase',
          'color': '',
          'estado': 'incompleto'
        },
        {
          'posicion': '3',
          'nombre': 'Capacidad del negocio',
          'activo': false,
          'route': 'registered/project/capacities',
          'color': '',
          'estado': 'incompleto'
        },
        {
          'posicion': '4',
          'nombre': 'Posición competitiva',
          'activo': false,
          'route': 'registered/project/ocean',
          'color': '',
          'estado': 'incompleto'
        }
      ];
  }

  validarEstadoClasificacionDelProyecto(proyecto){
    this.state.updateBasico('estadoCreacionDelaEstrategia',  this.estadoEstrategias);
    this.state.updateBasico('estadoClasificacionDelProyecto',  this.estadoProyecto);
    if(proyecto){
      const request = {
        "codigoEmprendedor":proyecto.codigoEmprendedor,
        "codigoProyecto":proyecto.codigoProyecto
      };
      //validar estadoEstrategias
      this.proySrv.consultarTacticas(request).subscribe(
        response => {
          if(response && response.datos && response.datos.sistemasList && response.datos.sistemasList.length>CERO
            && response.datos.sistemasList[CERO].clusterList && response.datos.sistemasList[CERO].clusterList.length>CERO
            && response.datos.sistemasList[1].clusterList && response.datos.sistemasList[1].clusterList.length>CERO
            && response.datos.sistemasList[2].clusterList && response.datos.sistemasList[2].clusterList.length>CERO){
            this.estadoEstrategias.filter(item => item.posicion === UNOSTR)[CERO].estado = COMPLETADO;
            this.state.updateBasico('estadoCreacionDelaEstrategia',  this.estadoEstrategias);
          }else{
            this.state.updateBasico('estadoCreacionDelaEstrategia',  this.estadoEstrategias);
          }
        },
        e => {
          console.log(e);
          return;
        }
      );
      //validar estadoProyecto
      if(proyecto.codigoProyecto>CERO){
        this.estadoProyecto.filter(item => item.posicion === UNOSTR)[CERO].estado = COMPLETADO;
      }
      if(proyecto.fase && proyecto.momento){
        this.estadoProyecto.filter(item => item.posicion === DOSSTR)[CERO].estado = COMPLETADO;
      }
      if(proyecto.perfilCapacidades && proyecto.perfilCapacidades.sistemaComercial){
        this.estadoProyecto.filter(item => item.posicion === TRESSTR)[CERO].estado = COMPLETADO;
      }
      if(proyecto.propuestaDeValor.length>CERO){
        this.estadoProyecto.filter(item => item.posicion === CUATROSTR)[CERO].estado = COMPLETADO;
      }
      this.state.updateBasico('estadoClasificacionDelProyecto',  this.estadoProyecto);
    }else{
      this.state.updateBasico('estadoCreacionDelaEstrategia',  this.estadoEstrategias);
      this.state.updateBasico('estadoClasificacionDelProyecto',  this.estadoProyecto);
    }
  }
  
  abrirSubsTipoDeSubs(){
    let dialogBoxSettings = {
      height: '675px',
      width: '1050px',
      margin: '0 auto',
      disableClose: false,
      hasBackdrop: true,
      panelClass: 'popup-tipo-subs',
    };
    const dialogRef = this.dialog.open(
      LoginPlanesSubsComponent,
      dialogBoxSettings,
    );
   }


}