import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmprendedorService {

  constructor(private http: HttpClient) { }

  registrarEmprendedor  = environment.BASE_URL + '/v1/emprendedor/registrar';
  consultarEmprendedor  = environment.BASE_URL + '/v1/emprendedor/buscarPorCorreo';
  actualizarEmprendedor = environment.BASE_URL + '/v1/emprendedor/actualizar';
  loginEmprendedor                    = environment.BASE_URL + '/v1/emprendedor/login';
  claveEmprendedor                    = environment.BASE_URL + '/v1/emprendedor/recordarclave';
  actualizarPerfilEmprendedorEndPoint = environment.BASE_URL + '/v1/emprendedor/actualizarperfil';
  desactivarCambioDePlanEndPoint      = environment.BASE_URL + '/v1/licencia/desactivarCambioDePlan';

  private extractData(res: Response) {
    let body = res;
    return body || { };
  }

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
    })
  }

  // Error handling
  errorHandl(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }

  setNewEmprendedor(newEmprendedor): Observable<any>{
    let json = JSON.stringify(newEmprendedor);
      return this.http.post(this.registrarEmprendedor, json , this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.errorHandl)
      );
  }

  consultEmprendedor(consultarEmprendedor): Observable<any>{
      return this.http.post(this.consultarEmprendedor, consultarEmprendedor, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.errorHandl)
      );
  }

  setActualizar(request): Observable<any> {
    let json = JSON.stringify(request);
    return this.http.post(this.actualizarEmprendedor, json, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.errorHandl)
    );
  }

  inicioSesionEmprendedor(request): Observable<any> {
    let json = JSON.stringify(request);
    return this.http.post(this.loginEmprendedor, json, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.errorHandl)
    );
  }

  recuperarClaveEmprendedor(request): Observable<any> {
    let json = JSON.stringify(request);
    return this.http.post(this.claveEmprendedor, json, this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.errorHandl)
    );
  }

  actualizarPerfilEmprendedor(newEmprendedor): Observable<any>{
    let json = JSON.stringify(newEmprendedor);
      return this.http.post(this.actualizarPerfilEmprendedorEndPoint, json , this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.errorHandl)
      );
  }

  desactivarCambioDePlan(licencia: any): Observable<any> {
    let json = JSON.stringify(licencia);
    return this.http.post(this.desactivarCambioDePlanEndPoint, json, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.errorHandl)
      );
  }


}
