import { Injectable} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VideoIntroductorioComponent } from './video-introductorio.component';
import { ListasService } from '../../_services/listas.service';
import { AlertService } from '../../_modal/alert/alert.service';
import { mensaje } from '../../_const/alert_error_message'

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  constructor
  (public dialog: MatDialog,
    private video: ListasService,
    private alertSrv: AlertService
    ) { }

    listaVideos:any []=[];

  showAlert (clave:String){
    this.video.getCurrentVideos().subscribe(
      res=>{
        this.listaVideos = res.datos.videosList;
        let v:any = this.listaVideos.filter(item=>item.clave == clave);
        this.dialog.open(VideoIntroductorioComponent, {
          panelClass: 'video-introductorio',
          data: {
          url: v[0].url,
          descripcion: v[0].descripcion
        }
        });
      },
      e=>{
        this.alertSrv.showAlert(mensaje.error, "Error");
      }
    );
  }
}