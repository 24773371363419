import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-estrategias-edicion',
  templateUrl: './estrategias-edicion.component.html',
  styleUrls: ['./estrategias-edicion.component.css']
})
export class EstrategiasEdicionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
