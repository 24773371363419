import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent} from './alert.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(public dialog: MatDialog) { }

  showAlert (msg:string, title:String = ''){
    return this.dialog.open(AlertComponent, {
      panelClass: 'my-class',
      data: {
        msg: msg,
        title: title
      }
    });
  }
}