<app-header-layout></app-header-layout>
<div class="row">
    <div class="container">
        <section class="estrategia">
            <article class="row">
                <h4>Perfil emprendedor</h4>
                <!--SEMI - stepper-->
                <app-footer-layout style="width: 100%;"></app-footer-layout>
                <h6>Los siguientes enunciados se relacionan con conceptos de negocio. Algunos son correctos y otros incorrectos. 
                    Por favor elija que tan de acuerdo está con el enunciado.</h6>
            </article>
            <article class="row inf">
                <div class="col-md-6 col-lg-4 escalaimg">
                    <img src="../../../../assets/img/escala.png" width="310" height="70">
                </div>
                <div class="col-md-6 col-lg-4 escaladesc">
                    <p style="margin-bottom: 5px;text-align: justify">0 = Poco de acuerdo</p>
                    <p style="margin-bottom: 5px;text-align: justify">5 = Medianamente de acuerdo</p>
                    <p style="text-align: justify">10 = Muy de acuerdo</p>
                </div>
                <div class="col-md-12 col-lg-4 text-center is">
                    <button type="submit" class="btn btn-primary" (click)="dashboard()">
                        <mat-icon matSuffix id="iconoVideo">keyboard_backspace</mat-icon>
                        Volver al dashboard
                    </button>
                    <button type="submit" class="btn btn-primary fondo-boton-video" (click)="openVideoIntroductorio()">
                        <mat-icon matSuffix id="iconoVideo">video_library</mat-icon>
                        Video de apoyo
                    </button>
                </div>
            </article>
            <form [formGroup]="skillsForm" (ngSubmit)="onSkillsFormSubmitted()">
                <article class="container">
                    <div class="row ">
                        <div class="col-lg-4 col-md-6" *ngFor="let c of competenciasFull; let i=index">
                            <p *ngIf="c.pregunta.codigo=='COTPEMSPCO13'" 
                                #tooltip="matTooltip" matTooltipPosition="above"
                                (click)="tooltip.toggle()"
                                matTooltipHideDelay="250"
                                [matTooltip]="c.pregunta.codigo=='COTPEMSPCO13' ? TT_COTPEMSPCO13 : null">{{c.pregunta.pregunta}}</p>
                            <p *ngIf="c.pregunta.codigo=='COTPEMSPCO15'" 
                                #tooltip="matTooltip" matTooltipPosition="above" 
                                (click)="tooltip.toggle()"
                                matTooltipHideDelay="250"
                                [matTooltip]="c.pregunta.codigo=='COTPEMSPCO15' ? TT_COTPEMSPCO15 : null">{{c.pregunta.pregunta}}</p>
                            <p *ngIf="c.pregunta.codigo=='COTPEMSPCO19'" 
                                #tooltip="matTooltip" matTooltipPosition="above" 
                                (click)="tooltip.toggle()"
                                matTooltipHideDelay="250"
                                [matTooltip]="c.pregunta.codigo=='COTPEMSPCO19' ? TT_COTPEMSPCO19 : null">{{c.pregunta.pregunta}}</p>
                            <p *ngIf="c.pregunta.codigo=='COTPEMSPCO21'" 
                                #tooltip="matTooltip" matTooltipPosition="above" 
                                (click)="tooltip.toggle()"
                                matTooltipHideDelay="250"
                                [matTooltip]="c.pregunta.codigo=='COTPEMSPCO21' ? TT_COTPEMSPCO21 : null">{{c.pregunta.pregunta}}</p>
                            <p *ngIf="c.pregunta.codigo!='COTPEMSPCO13' && c.pregunta.codigo!='COTPEMSPCO15' && 
                                      c.pregunta.codigo!='COTPEMSPCO19' && c.pregunta.codigo!='COTPEMSPCO21'">{{c.pregunta.pregunta}}</p>
                            <div class="container-slider">
                                <mat-slider thumbLabel value="{{c.valor}}" min="0" max="10" #matslider (input)="onInputChange($event,c)"></mat-slider>
                                <span style="font-size: 8;color:#6ABFD4;">{{c.valor}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="offset-md-3 col-md-6 mb-3 text-center">
                            <button type="submit" id="btn-competencias-emprendedor" [disabled]="disabled" class="btn btn-primary">Guardar</button>
                        </div>
                    </div>
                </article>
            </form>
        </section>
    </div>
</div>
<ngx-spinner></ngx-spinner>