                                                                        <!-- STEPPER PARA CREAR -->
    <div class="container" *ngIf="!editando">
        <!-- stepper para el perfil del emprendedor -->
        <div class="row" *ngIf="footer==='emprendedor'; else project">
            <div class="col text-center" *ngFor="let em of arrayFaseEmprendedor; let i = index">
                <div *ngIf="em.estado === true; else estado2">
                    <h1><span class="actual"><mat-icon style="font-size: 18px;">check</mat-icon></span></h1>
                    <div id="pintar" style="color: black;">{{em.nombre}}</div>
                </div>
                <ng-template #estado2>
                    <div *ngIf="em.color === true; else normal">
                        <h1><span class="actual">{{i+1}}</span></h1>
                        <div id="pintar">{{em.nombre}}</div>
                    </div>
                    <ng-template #normal>
                        <div>
                            <h1><span class="incompleto">{{i+1}}</span></h1>
                            <div>{{em.nombre}}</div>
                        </div>
                    </ng-template>
                </ng-template>
            </div>
        </div>

        <!-- stepper para el proyecto-->
        <ng-template #project>
            <div class="row" *ngIf="footer==='proyecto'; else strategy">
                <div class="col text-center" *ngFor="let pr of arrayFaseProyecto; let i = index">
                    <div *ngIf="pr.estado === true; else estado2">
                        <h1><span class="actual"><mat-icon style="font-size: 18px;">check</mat-icon></span></h1>
                        <div id="pintar" style="color: black;">{{pr.nombre}}</div>
                    </div>
                    <ng-template #estado2>
                        <div *ngIf="pr.color === true; else normal">
                            <h1><span class="actual">{{i+1}}</span></h1>
                            <div id="pintar">{{pr.nombre}}</div>
                        </div>
                        <ng-template #normal>
                            <div>
                                <h1><span class="incompleto">{{i+1}}</span></h1>
                                <div>{{pr.nombre}}</div>
                            </div>
                        </ng-template>
                    </ng-template> 
                </div>
            </div>
        </ng-template>

        <!-- stepper para las tacticas -->
        <ng-template #strategy>
            <div class="row" *ngIf="footer==='estrategia'">
                <div class="col text-center" *ngFor="let es of arrayFaseEstrategia; let i = index">
                    <div *ngIf="es.estado === true; else estado2">
                        <h1><span class="actual"><mat-icon style="font-size: 18px;">check</mat-icon></span></h1>
                        <div id="pintar"  style="color: black;">{{es.nombre}}</div>
                    </div>
                    <ng-template #estado2>
                        <div *ngIf="es.color === true; else normal">
                            <h1><span class="actual">{{i+1}}</span></h1>
                            <div id="pintar">{{es.nombre}}</div>
                        </div>
                        <ng-template #normal>
                            <div>
                                <h1><span class="incompleto">{{i+1}}</span></h1>
                                <div>{{es.nombre}}</div>
                            </div>
                        </ng-template>
                    </ng-template>
                </div>
            </div>
        </ng-template>
        <br>
    </div>

                                                                        <!-- STEPPER PARA EDITAR -->
    <div class="container" *ngIf="editando">
        <!-- stepper para el perfil del emprendedor -->
        <div class="row" *ngIf="footer==='emprendedor'; else project">
            <div class="col text-center" *ngFor="let em of arrayFaseEmprendedor; let i = index">
                <div *ngIf="(router.url.includes('/tipo-emprendedor') && em.nombre.includes('Tipo de emprendedor')) 
                            || (router.url.includes('/competencias') && em.nombre.includes('Competencias de emprendedor')); else normal">
                    <h1><span class="actual">{{i+1}}</span></h1>
                    <div id="pintar">Editar {{em.nombre}}</div>
                </div>
                <ng-template #normal>
                    <div>
                        <h1><span class="incompleto">{{i+1}}</span></h1>
                        <div class="pointer" (click)="irA()">Editar {{em.nombre}}</div>
                    </div>
                </ng-template>
            </div>
        </div>

        <!-- stepper para el proyecto-->
        <ng-template #project>
            <div class="row" *ngIf="footer==='proyecto'; else strategy">
                <div class="col text-center" *ngFor="let pr of arrayFaseProyecto; let i = index">
                    <div *ngIf="(router.url.includes('editar/proyecto') && pr.nombre.includes('Inscripción de proyecto')) 
                        || (router.url.includes('editar/fase-momento') && pr.nombre.includes('Fase y momento de negocio'))
                        || (router.url.includes('editar/capacidades') && pr.nombre.includes('Capacidad del negocio'))
                        || (router.url.includes('/editar/propuesta') && pr.nombre.includes('Posición competitiva')); 
                        else normal">
                        <h1><span class="actual">{{i+1}}</span></h1>
                        <div id="pintar">Editar {{pr.nombre}}</div>
                    </div>
                <ng-template #normal>
                    <div>
                        <h1><span class="incompleto">{{i+1}}</span></h1>
                        <div class="pointer" (click)="proyectoIrA(pr.nombre)">Editar {{pr.nombre}}</div>
                    </div>
                </ng-template>
                </div>
            </div>
        </ng-template>

        <!-- stepper para las tacticas  Editar Propiedades estratégicas -Editar selección de tácticas  -->
        <ng-template #strategy>
            <div class="row" *ngIf="footer==='estrategia'">
                    <div class="col text-center" *ngFor="let item of arrayFaseEstrategiaEdicion; let i = index">
                        <div *ngIf="(item.nombre === 'Editar Prioridades estratégicas' && router.url.includes('project/strategy')) || 
                                    (item.nombre === 'Editar selección de tácticas' && router.url.includes('registered/project/tactics')); 
                                    else normal">
                                    <h1><span class="actual">{{i+1}}</span></h1>
                            <div id="pintar">{{item.nombre}}</div>
                        </div>
                    <ng-template #normal>
                        <div>
                            <h1><span class="incompleto">{{i+1}}</span></h1>
                            <div class="pointer" (click)="irASeleccionTacticas(item.route)">{{item.nombre}}</div>
                        </div>
                    </ng-template>
                    </div>
            </div>
        </ng-template>
        <br>
    </div>