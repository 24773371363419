import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProyectoService, ListasService, ListasIndustriasService } from  '@app/_services';
import { AlertService } from '@app/_modal/alert/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { mensaje } from '@app/_const/alert_error_message';
import { StateService } from '@app/_helpers/state.service';
import { VideoService } from '@app/_modal/video-introductorio/video-introductorio.service';

const UNO = '1';
const CERO = 0;
const COMPLETO = 'completado';
@Component({
  selector: 'app-proyecto-edicion',
  templateUrl: './proyecto-edicion.component.html',
  styleUrls: ['./proyecto-edicion.component.css']
})
export class ProyectoEdicionComponent implements OnInit {

 //Definimos la variable del formulario
 createForm: FormGroup;
 //variable para validar si el formulario ha sido enviado
 submitted = false;
 //creamos nuestras listas para luego llenarlas con un servicio
 listCountry: any []=[];
 listIndustry: any []=[];
 listSubIndustry: any []=[];
 listBusiness: any []=[];
 listFocus: any []=[];
 listModel: any []=[];
 listProduct: any []=[]; 
 listAux: any []=[]; 
 newProject: any;
 datosForm: any;
 disabled: boolean;
 projectSummary:any;
 selected = 'Colombia';
 updateProject: any;
 mostrarSeccion1 = true;
 mostrarSeccion2 = true;

 constructor(
   //instanciamos la variable para la construccion del formulario
   private formBuilder: FormBuilder,
   private listasServ: ListasIndustriasService,
   private listaCountryServ: ListasService,
   public dialog: MatDialog,
   private spinner: NgxSpinnerService,
   private project: ProyectoService,
   private alertSrv: AlertService,
   private state: StateService,
   private router: Router,
   private videoIntroductorio: VideoService
 ) { }

 ngOnInit(): void {
   this.state.updateBasico('footer','proyecto');
   //inicializamos nuestro formulario y aplicamos validaciones.
   this.createForm = this.formBuilder.group({
     projectName: ['', Validators.required],
     description: ['', Validators.required],
     country: ['', Validators.required],
     industry: ['', Validators.required],
     subIndustry: ['', Validators.required],
     business: ['', Validators.required],
     focus: ['', Validators.required],
     model: ['', Validators.required],
     product: ['', Validators.required],
   });

   this.getListas();
   this.getListasCountry();

   //Inicializamos los vectores
   this.listSubIndustry=[{ nombre: "Debe seleccionar primero una industria"}];
   this.listBusiness= [{ nombre: "Debe seleccionar primero una subindustria"}];
   this.listFocus= [{ nombre: "Debe seleccionar primero un negocio"}];

   //Creamos un nuevo JSON para crear el proyecto.
   this.newProject={
     "codigoEmprendedor": "",
     "nombre": "",
     "codigopais": "",
     "descripcion": "",
     "codigoTipoProducto": "",
     "codigoTipoModelo": "",
     "codigoIndustria": "",
     "codigoSubIndustria": "",
     "codigoTipoNegocio": "",
     "codigoEnfoque": ""
   }

   this.projectSummary={
    "nombre": "",
    "pais": "",
    "descripcion": "",
    "tipoProducto": "",
    "tipoModelo": "",
    "industria": "",
    "subIndustria": "",
    "tipoNegocio": "",
    "enfoque": ""
  }

   this.datosProyecto();
 }


 
 //implementamos la eleccion solo para colombia por el momento
 changeCountry(codigopais:String){
   if(codigopais !== 'CO'){
     this.alertSrv.showAlert(mensaje.disponibilidadListasPais, "Error");
     this.disabled = true;
   }
   else{
     this.disabled = false;
   }
 }
 //seleccionamos la industria e implementamos la subindustria
 onChangeIndustry(industryCode): void {
   this.listAux = this.listIndustry.filter(item => item.nombre === industryCode);
   this.listSubIndustry = this.listAux[0].subIndustriasList;
   
 }

 //seleccionamos la subindustria
 onChangeSubIndustry(subIndustryCode):void {
   this.listAux = this.listSubIndustry.filter(item => item.nombre === subIndustryCode);
   this.listBusiness = this.listAux[0].tipoNegociosList;
 }

 //Seleccionamos el tipo de negocio.
 onChangeBusiness(codeBisness): void{
   this.listAux = this.listBusiness.filter(item => item.nombre === codeBisness);
   this.listFocus = this.listAux[0].enfoquesList;
   console.log(this.listFocus);
 }


 //Obtenemos acceso al control de nuestro formulario
 get f(){
   return this.createForm.controls;
 }

 //obtenemos las listas
 getListas(){
   this.spinner.show();
   this.listasServ.getListas().subscribe(
     //poblamos las listas
     res=>{
       this.listIndustry = res.datos.industriasList;
       this.listProduct = res.datos.tipoProductoList;
       this.listModel = res.datos.tipoModeloList;
       this.spinner.hide();
     }, e=>{
       console.log(e);
       this.spinner.hide();
       this.alertSrv.showAlert(mensaje.error, "Error");
     } 
   );
 }

 //Obtenemos la lista de pais
 getListasCountry(){
   this.spinner.show();
   this.listaCountryServ.getListas().subscribe(
     res=>{
       this.listCountry = res.datos.paises;
       this.spinner.hide();
     }, e=>{
       this.spinner.hide();
       this.alertSrv.showAlert(mensaje.error, "Error");
       console.log(e);
     } 
   );
 }

 //validamos el envio de nuestro formulario
 onCreateFormSubmitted(){
   this.submitted = true;
   //paramos aca si nuestro formulario no cumple con las validaciones
   if(this.createForm.invalid) return;
   this.onSubmitRegistrar();
 }

 //Enviamos la información del nuevo proyecto
 onSubmitRegistrar(){
   this.disabled = true;
   this.spinner.show();
   //Traemos todos los datos del formulario
   this.datosForm = this.createForm.value;
   //Llenamos nuestro objeto JSON con los datos obtenidos
   this.newProject.nombre = this.datosForm.projectName;  
   this.newProject.codigopais =         this.obtenerCodigo(this.listCountry, this.datosForm.country);   
   this.newProject.descripcion =        this.datosForm.description;
   this.newProject.codigoTipoProducto = this.obtenerCodigo(this.listProduct, this.datosForm.product);
   this.newProject.codigoTipoModelo =   this.obtenerCodigo(this.listModel, this.datosForm.model);
   this.newProject.codigoIndustria =    this.obtenerCodigo(this.listIndustry, this.datosForm.industry);
   this.newProject.codigoSubIndustria = this.obtenerCodigo(this.listSubIndustry, this.datosForm.subIndustry);
   this.newProject.codigoTipoNegocio =  this.obtenerCodigo(this.listBusiness, this.datosForm.business);
   this.newProject.codigoEnfoque =      this.obtenerCodigo(this.listFocus, this.datosForm.focus); 
   
   let idUser = this.state.getInfo("basico", "idEmprendedor");
   this.newProject.codigoEmprendedor = idUser;

   //creamos la peticion http
   this.project.setNewProject(this.newProject).subscribe(
     response =>{
       this.disabled = false;
         this.spinner.hide();
         if(response.error && response.error.codigo){
           this.alertSrv.showAlert(response.error.mensaje, "Error");
           return;
         }
         //resetaerFaseYMomento
         this.resetaerFaseYMomento();
         //limpiamos los campos del formulario
         this.createForm.reset;
         //enviamos el idUsuario al localStorage
         let data:any = {
           pais: this.datosForm.country,
           codigoProyecto: response.datos.codigoProyecto
         }
         this.state.updateBasico("codPaisProyecto", this.obtenerCodigo(this.listCountry, this.datosForm.country));
         this.state.updateBasico("codProyecto",     response.datos.codigoProyecto);
         this.state.updateBasico("nombreProyecto",  this.datosForm.projectName);
          //Cambiar estado
         this.state.updateProyecto("Inscripción de proyecto");
         let estadoClasificacionDelProyecto = this.state.getInfo("basico", "estadoClasificacionDelProyecto");
         estadoClasificacionDelProyecto.filter(item => item.posicion === UNO)[CERO].estado = COMPLETO;
         this.state.updateBasico('estadoClasificacionDelProyecto',  estadoClasificacionDelProyecto);
         this.router.navigate(['editar/fase-momento']);
     },
     e => {
       this.disabled = false;
       this.spinner.hide();
       this.alertSrv.showAlert(mensaje.error, "Error");
       console.log(e);
     }
   )
 }

 //abrimos el pop-up de video introductorio
 openVideoIntroductorio(){
   this.videoIntroductorio.showAlert("RegistroProyecto");
 }

 obtenerCodigo(listaX, nombre){
  const campo = listaX.filter(item => item.nombre === nombre);
  return campo[0].codigo;
 }

datosProyecto(){
  let idUser = this.state.getInfo("basico", "idEmprendedor");
  this.spinner.show();
  this.project.consultProyecto(idUser).subscribe(
    res=>{
      this.projectSummary.nombre = res.datos.proyectos[0].nombre;
      this.projectSummary.pais = res.datos.proyectos[0].pais;
      this.projectSummary.descripcion = res.datos.proyectos[0].descripcion;
      this.projectSummary.industria = res.datos.proyectos[0].industria;
      this.projectSummary.subIndustria = res.datos.proyectos[0].subIndustria;
      this.projectSummary.tipoNegocio = res.datos.proyectos[0].tipoNegocio;
      this.projectSummary.enfoque = res.datos.proyectos[0].enfoque;
      this.projectSummary.tipoModelo = res.datos.proyectos[0].tipoModelo;
      this.projectSummary.tipoProducto = res.datos.proyectos[0].tipoProducto;
      this.spinner.hide();
      //--
      this.createForm.controls.projectName.setValue(this.projectSummary.nombre);
      this.createForm.get('country').setValue(res.datos.proyectos[0].pais);
      this.createForm.get('description').setValue(res.datos.proyectos[0].descripcion);
      this.createForm.get('industry').setValue(res.datos.proyectos[0].industria);
        //--
      const industria = this.listIndustry.filter(item => item.nombre === res.datos.proyectos[0].industria);
      this.listSubIndustry = industria[0] ? industria[0].subIndustriasList : [];
      this.createForm.get('subIndustry').setValue(res.datos.proyectos[0].subIndustria);
          //--
      const sunIndustria = this.listSubIndustry.filter(item => item.nombre === res.datos.proyectos[0].subIndustria);
      this.listBusiness = sunIndustria[0] ? sunIndustria[0].tipoNegociosList : [];;
      this.createForm.get('business').setValue(res.datos.proyectos[0].tipoNegocio);
            //--
      const negocio = this.listBusiness.filter(item => item.nombre === res.datos.proyectos[0].tipoNegocio);
      this.listFocus = negocio[0] ? negocio[0].enfoquesList : [];;
      this.createForm.get('focus').setValue(res.datos.proyectos[0].enfoque);
      //--
      this.createForm.get('model').setValue(res.datos.proyectos[0].tipoModelo);
      this.createForm.get('product').setValue(res.datos.proyectos[0].tipoProducto);
      //--manetener fase y momento
      this.updateProject = {
        "codigoEmprendedor": idUser,
        "codigoProyecto":    res.datos.proyectos[0].codigoProyecto,
        "codigoFase":        res.datos.proyectos[0].fase.codigo,
        "codigoMomento":     res.datos.proyectos[0].momento.codigo,
      }
    },
    e=>{
      this.spinner.hide();
      this.alertSrv.showAlert(mensaje.error, "Error");
      console.log(e);
    }
  );
}

resetaerFaseYMomento(){
  this.project.setActualizar(this.updateProject).subscribe(
    response => {
        if(response.error && response.error.codigo){
          console.error('error en resetaerFaseYMomento()');
          return;
        }else{
          console.info('resetaerFaseYMomento ok');
        }
    },
    e => {
      console.error('exception en resetaerFaseYMomento()');
    });
}

  dashboard(){
    this.router.navigate(['registered/dashboard']);
  }

  irAProyecto(){
    this.router.navigate(['editar/proyecto']);
  }

  irAFase(){
    this.router.navigate(['editar/fase-momento']);
  }

  irACapacidades(){
    this.router.navigate(['editar/capacidades']);
  }

  irAOceano(){
    this.router.navigate(['editar/propuesta']);
  }

  format_number(number, prefix) {
    let thousand_separator = ',',
      decimal_separator = '.',
      regex = new RegExp('[^' + decimal_separator + '\\d]', 'g'),
      number_string = number.replace(regex, '').toString(),
      split = number_string.split(decimal_separator),
      rest = split[0].length % 3,
      result = split[0].substr(0, rest),
      thousands = split[0].substr(rest).match(/\d{3}/g);

    if (thousands) {
      let separator = rest ? thousand_separator : '';
      result += separator + thousands.join(thousand_separator);
    }
    result = split[1] != undefined ? result + decimal_separator + split[1] : result;
    return prefix == undefined ? result : (result ? prefix + result : '');
  }

}
