<app-header-layout></app-header-layout>
<div class="row">
    <div class="container">
        <section class="estrategia">
            <article class="row">
                <h4>Perfil emprendedor</h4>
                <h5 style="margin-bottom: 25px;">Edición Círculo de la vida</h5>
                <!--  SEMI MENU de edición-->
                <div class="container" style="margin-bottom: 25px;">
                    <div class="row">
                        <div class="col text-center ng-star-inserted"><div class="ng-star-inserted"><div >Circulo de la vida</div></div></div>
                        <div class="col text-center ng-star-inserted"><div class="ng-star-inserted"><div id="pintar" class="pointer" (click)="irACompetencias()">Editar Competencias del emprendedor</div></div></div>
                        <div class="col text-center ng-star-inserted"><div class="ng-star-inserted"><div id="pintar" class="pointer"  (click)="irATipoEmprendedor()">Editar Tipo de emprendedor</div></div></div>
                    </div>
                </div>
                <h6>El círculo de vida es una herramienta para conocer el interés o importancia que le das a las áreas que componen nuestra vida como emprendedores y dueños de nuestro negocio. Califica de 0 a 10 qué importancia le das en tu vida a cada uno
                    de los siguientes aspectos.</h6>
            </article>
            <article class="row inf">
                <div class="col-md-6 col-lg-4 escalaimg">
                    <img src="../../../../assets/img/escala.png" width="310" height="70">
                </div>
                <div class="col-md-6 col-lg-4 escaladesc">
                    <p style="margin-bottom: 5px;text-align: justify; margin-left: 40px;">0 = No le doy importancia</p>
                    <p style="margin-bottom: 5px;text-align: justify; margin-left: 40px;">5 = Le doy poca importancia</p>
                    <p style="text-align: justify; margin-left: 40px;">10 = Me importa mucho</p>
                </div>
                <div class="col-md-12 col-lg-4 text-center is">
                    <button type="submit" class="btn btn-primary" (click)="dashboard()">
                        <mat-icon matSuffix id="iconoVideo">keyboard_backspace</mat-icon>
                        Volver al dashboard
                    </button>
                    <button type="submit" class="btn btn-primary fondo-boton-video" (click)="openVideoIntroductorio()">
                        <mat-icon matSuffix id="iconoVideo">video_library</mat-icon>
                        Video de apoyo
                    </button>
                </div>
            </article>
            <form [formGroup]="createForm" (ngSubmit)="onCreateFormSubmitted()">
                <article class="container">
                    <div class="row ">
                        <div class="col-lg-4 col-md-6" *ngFor="let p of preguntasCV; let i=index">
                            <p>{{p.pregunta.pregunta}}</p>
                            <div class="container-slider">
                                <mat-slider thumbLabel value="{{p.valor}}" min="0" max="10" #matslider (input)="onInputChange($event,p)"></mat-slider>
                                <span style="font-size: 8;color:#6ABFD4;">{{p.valor}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="offset-md-3 col-md-6 mb-3 text-center">
                            <button type="submit" id="btn-circulo-vida" class="btn btn-primary"  [disabled]="disabled">Guardar</button>
                        </div>
                    </div>
                </article>
            </form>
        </section>
    </div>
</div>
<app-footer-layout></app-footer-layout>
<ngx-spinner></ngx-spinner>