import { Component, OnInit } from '@angular/core';
import { StateService } from '@app/_helpers/state.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-layout',
  templateUrl: './header-layout.component.html',
  styleUrls: ['./header-layout.component.css']
})
export class HeaderLayoutComponent implements OnInit {

  name: String;
  nameProject: String;
  readyEntrepreneur: boolean=false;
  readyProject: boolean=false;
  readyStrategy: boolean=false;
  tipoEmprendedor: string = "";

  constructor(
    private state: StateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.tipoEmprendedor = this.state.getInfo("basico", "tipoEmprendedor");
    this.getInfo();
    this.readyEmprendedor();
    this.readyProyecto();
    this.readyEstrategia();
  }

  getInfo(){
    this.name = this.state.getInfo("basico", "nombres");
    this.nameProject = this.state.getInfo("basico", "nombreProyecto");
  }

  readyEmprendedor(){
    let ready:any[]= this.state.getInfo("estadoEmprendedor");
    for(let i=0; i<ready.length; i++){
      if(ready[i].estado === false){
        this.readyEntrepreneur = false;
        return
      }
    }
    this.readyEntrepreneur = true;
  }

  readyProyecto(){
    let ready:any[]= this.state.getInfo("estadoProyecto");
    for(let i=0; i<ready.length; i++){
      if(ready[i].estado === false){
        this.readyProject = false;
        return
      }
    }
    this.readyProject = true;
  }

  readyEstrategia(){
    let ready:any[]= this.state.getInfo("estadoEstrategia");
    for(let i=0; i<ready.length; i++){
      if(ready[i].estado === false){
        this.readyStrategy = false;
        return
      }
    }
    this.readyStrategy = true;
  }

  close(){
    localStorage.removeItem('STATE');
    this.router.navigate(['/login']);
  }

  dashboard(){
    this.router.navigate(['registered/dashboard']);
  }

  editarPerfil(){
    this.router.navigate(['/editar/registro']);
  }

  gestionarPlan(){
    this.router.navigate(['/gestionar-plan']);
  }

}
