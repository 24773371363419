<div class="contenedor-detalles">
    <div class="detalle">
        <div class="modal-body example-box">

            <div class="col-md-11 col-lg-4 offset-md-1 p-0">
                <div class="w-100 margen-video" *ngIf="videoReady; else elseBlock">
                    <iframe class="responsive-iframe" [src]="videoURL" frameborder="4"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe>
                    <p style="size: 15px; color: #696868;width: 275px;">Conoce el proceso para restablecer tu contraseña de stive aquí.</p>
                </div>
                <div class="w-100">
                    <ng-template #elseBlock>
                        <mat-spinner style="margin-left: 30%;"></mat-spinner>
                    </ng-template>
                </div>
            </div>


            <form class="text-center" [formGroup]="pwdForm" (ngSubmit)="onPwdSubmit()">
                <!--Email-->
                <div class="form-group">
                    <mat-form-field  appearance="outline" class="w-100">
                        <mat-label>Ingresar email</mat-label>
                        <input  matInput type="email" aria-describedby="emailHelp" placeholder="Correo electrónico"
                                formControlName="useremail" [ngClass]="{ 'is-invalid': pwdSubmitted && f.useremail.errors }">
                        <mat-icon matSuffix>alternate_email</mat-icon>
                    </mat-form-field>
                    <span *ngIf="pwdSubmitted && f.useremail.errors">
                        <span *ngIf="f.useremail.errors.email" class="error">Debes ingresar un Email valido</span>
                    </span>
                </div>
                <!--Contraseña-->
                <div class="form-group">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label> Código de Stive</mat-label>
                        <input 
                            #tooltip="matTooltip"
                            matTooltip="Ingresa tu código de Stive que se envió a tu correo"
                            matTooltipPosition="above"
                            matTooltipHideDelay="450"
                            aria-label="mat Tooltip"
                            (click)="tooltip.toggle()"
                            matInput matInput type="text" placeholder="Código de Stive" formControlName="stivecode"
                        [ngClass]="{ 'is-invalid': pwdSubmitted && f.stivecode.errors }">
                        <mat-icon matSuffix>qr_code</mat-icon>
                    </mat-form-field>
                </div>
                <button type="submit" class="btn btn-primary" [disabled]='disable' [style.background-color]="color">Restablecer</button>
                <p>{{mensaje}}</p>
            </form>
        </div>
    </div>
</div>
