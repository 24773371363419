import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { EmprendedorService } from "@app/_services/emprendedor.service";
import { ListasService } from '@app/_services/listas.service';

@Component({
  selector: 'app-forgot-pwd',
  templateUrl: './forgot-pwd.component.html',
  styleUrls: ['./forgot-pwd.component.css']
})
export class ForgotPwdComponent implements OnInit {

  pwdForm: FormGroup;
  pwdSubmitted = true;
  disable = false;
  color = '#6abfd4';
  mensaje = '';
  videoURL:any;
  videoReady:boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private emprendedorServ: EmprendedorService,
    public listSrv: ListasService,
    private sanitizer: DomSanitizer,
  ) { 

  }

  ngOnInit(): void {
    this.pwdForm = this.formBuilder.group({
      useremail: ['', [Validators.required, Validators.email]],
      stivecode: ['', Validators.required]
    });
    this.obtenerVideoDeApoyo();
  }

  get f() { return this.pwdForm.controls; }

  onPwdSubmit() {
    this.pwdSubmitted = true;
    if (this.pwdForm.invalid) {
        return;
    }

    const request = {
      "codigoStive": this.pwdForm.value.stivecode,
      "correo": this.pwdForm.value.useremail
    };

    this.emprendedorServ.recuperarClaveEmprendedor(request).subscribe(
      response => {
        if(response.datos === null) {
          this.mensaje = response.error.mensaje;
        }else if(response.datos.estatus === true){
          this.disable = true;
          this.color = '#707070';
          this.mensaje = 'Se envió un mensaje con la nueva contraseña a tu correo electrónico. Si no lo ves, revisa la carpeta Spam';
        }
      },
      e => {
        console.log(e);
      }
    )
  }

  obtenerVideoDeApoyo(){
    this.listSrv.getVideos().subscribe(
      res=>{
        res.datos.videosList.filter(v => {
          if(v.clave === "RestablecerClave"){
            this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(v.url);
            this.videoReady = true;
          }
        });
      },
      error=>{
        console.error(error);
      }
    );
  }

}
