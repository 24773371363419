import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ListasService, EmprendedorService, ProyectoService } from '@app/_services';
import { AlertService } from '@app/_modal/alert/alert.service';
import { StateService } from '@app/_helpers/state.service';
import { mensaje } from '@app/_const/alert_error_message';
import { MatDialog } from '@angular/material/dialog';
import { TermsComponent } from '@app/register/popup/terms/terms.component';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { VideoService } from '@app/_modal/video-introductorio/video-introductorio.service';

@Component({
  selector: 'app-register-payu',
  templateUrl: './register-payu.component.html',
  styleUrls: ['./register-payu.component.css']
})
export class RegisterPayuComponent implements OnInit {

  informationForm: FormGroup;
  informationSubmitted = false;
  newEmprendedor: any;
  dateSubmitted = false;
  dateSubmitted2 = false;
  mostrarSeccion1 = true;
  mostrarSeccion2 = false;
  mostrarBRS = false;
  elSI = false;
  elNO = false;
  registrarUsuarioDesdePayu: boolean = false;
  idUsuario: any = "";
  listaGeneros: any[] = [];
  listaPaises: any[] = [];
  listaStive: any[] = [];
  listaCiudades: any[] = [];
  emprendedor: any[] = [];
  paisActual: boolean;
  datosForm: any;
  disabled: boolean = false;
  credentials: any;
  public filteredNombreCuentas: Observable<any[]>;
  vp: String = 'password';
  vpc = false;

  constructor(
    //Inyectamos en nuestro constructor para recibir los datos enviados por URL.
    private formBuilder: FormBuilder,
    private listasServ: ListasService,
    private router: Router,
    private proySrv: ProyectoService,
    private emprendedorServ: EmprendedorService,
    private spinner: NgxSpinnerService,
    private alertSrv: AlertService,
    private stateSrv: StateService,
    public dialog: MatDialog,
    private state: StateService,
    private activatedRoute: ActivatedRoute,
    private videoDeApoyo: VideoService,
  ) {

  }

  ngOnInit(): void {
    let cus: number = 0;
    cus = Number(this.activatedRoute.snapshot.queryParamMap.get('cus'));
    if (cus && cus > 0) {
      this.registrarUsuarioDesdePayu = true;
    }
    this.credentials = this.proySrv.nuevoUsuario;
    this.informationForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(/^[ÑÁÉÍÓÚA-Z ]?[a-záéíóúñ ]+(\s+[ÑÁÉÍÓÚA-Z ]?[a-záéíóúñ ]+)*$/), Validators.maxLength(100)]],
      surname: ['', [Validators.required, Validators.pattern(/^[ÑÁÉÍÓÚA-Z ]?[a-záéíóúñ ]+(\s+[ÑÁÉÍÓÚA-Z ]?[a-záéíóúñ ]+)*$/), Validators.maxLength(100)]],
      age: ['', [Validators.required,]],
      phone: ['', [Validators.required, Validators.pattern('[1-9]{1}[0-9]{9}|[1-9]{1}[0-9]{6}'), Validators.maxLength(10)]],
      terms: ['', [Validators.required, this.termsValidator]],
      gender: ['', Validators.required],
      country: ['', Validators.required],
      city: ['', Validators.required],
      ccas: ['', Validators.required],
      useremail: ['', [Validators.required, Validators.email]],
      cis: ['', Validators.required],
      password: ['', [Validators.required,
      Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.$@$!%*?&-_#])[A-Za-z\d.$@$!%*?&-_#].{8,}')]],
      password2: ['', Validators.required]
    }, { validator: this.passwordConfirming });

    //Definimos nuestro Objeto JSON.
    this.newEmprendedor = {
      "nombres": "",
      "apellidos": "",
      "edad": "",
      "codigogenero": "",
      "codigopais": "",
      "codigociudad": "",
      "opcionComoConocioStive": "",
      "telefono": "",
      //quemamos el valor codigoStive para una futura implementación.
      "codigoStive": "",
      "aceptaTerminos": "",
      "correo": "",
      "password": "",
      "fechaNacimiento": ""
    };
    this.getListas();
    // Eliminamos los datos del localStorage
    localStorage.removeItem('STATE');
    // Eliminamos datos guardados en state
    this.state.init();
    this.state.updateBasico('editando', false);
  }

  /*Creamos un nuevo validador para confirmar pasdword con password2, para asi asegurarnos
  que el campo contraseña confirma con confirmar contraseña*/
  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('password2').value) {
      return { invalid: true };
    }
    if (c.get('terms').value !== true) {
      return { invalid: true };
    }
  }

  termsValidator(c: AbstractControl): { invalid: boolean } {
    if (c.get('terms') && c.get('terms').value !== true) {
      return { invalid: true };
    }
  }

  viewPassword() {
    if (this.vpc === true) {
      this.vp = 'password';
      this.vpc = false;
    }
    else {
      this.vp = 'text';
      this.vpc = true;
    }
  }

  get r() { return this.informationForm.controls; }

  //Validacion del formulario de datos personales.
  onInformationSubmit() {
    if (this.informationForm.value.terms === '') {
      this.informationForm.controls['terms'].setValue(false);
      return;
    }
    if (this.informationForm.value.terms !== true) {
      this.informationForm.controls['terms'].errors;
      this.informationForm.controls['terms'].value;
      return;
    }
    this.informationSubmitted = true;
    if (this.informationForm.invalid || (this.informationForm.value.terms) !== true) {
      //--validar fecha
      this.validarFormatoFecha();
      return;
    }
    this.validarFormatoFecha();
    if (this.dateSubmitted || this.dateSubmitted2) {
      return;
    }
    this.onSubmitRegistrar();
  }

  validarFormatoFecha() {
    let fechaAValidar = this.informationForm.value.age;
    let expreg_mmddyyyy = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;
    let expreg_ddmmyyyy = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/[0-9]{4}$/
    if (!expreg_mmddyyyy.test(fechaAValidar) && !expreg_ddmmyyyy.test(fechaAValidar)) {
      this.dateSubmitted = true;
      return;
    } else {
      this.dateSubmitted = false;
    }
    this.validarEdadEnLaFecha(this.informationForm.value.age);
  }

  validarEdadEnLaFecha(fechaAValidar) {
    let fechaAValidarDate = new Date(fechaAValidar);
    let monthDiff = Date.now() - fechaAValidarDate.getTime();
    //convert the calculated difference in date format
    let ageDt = new Date(monthDiff);
    //extract year from date    
    let year = ageDt.getUTCFullYear();
    //now calculate the age of the user
    let age = Math.abs(year - 1970);
    //--
    let anoFechaAValidarDate = fechaAValidarDate.getFullYear();
    let anoFechaActualDate = new Date().getFullYear();
    let edadCalculada = anoFechaActualDate - anoFechaAValidarDate;
    //--
    if (age > 100 || age < 13) {
      this.dateSubmitted2 = true;
      return false;
    } else {
      this.dateSubmitted2 = false;
    }
  }

  onSubmitRegistrar() {
    //Aqui se construye el objeto y se consume servicio
    this.datosForm = this.informationForm.value;
    //llenamos nuestro JSON con los datos del formulario.
    this.newEmprendedor.nombres = this.datosForm.name;
    this.newEmprendedor.apellidos = this.datosForm.surname;
    this.newEmprendedor.codigogenero = this.datosForm.gender;
    this.newEmprendedor.codigopais = this.datosForm.country;
    this.newEmprendedor.codigociudad = this.getValorCiudadPorNombre(this.listaCiudades, this.datosForm.city);
    this.newEmprendedor.opcionComoConocioStive = parseInt(this.datosForm.ccas);
    this.newEmprendedor.telefono = this.datosForm.phone;
    this.newEmprendedor.codigoStive = this.datosForm.cis;
    this.newEmprendedor.aceptaTerminos = this.datosForm.terms;
    //--
    this.newEmprendedor.correo = this.datosForm.useremail;
    this.newEmprendedor.password = this.datosForm.password;
    //--
    this.newEmprendedor.fechaNacimiento = this.datosForm.age;
    this.newEmprendedor.edad = 0;

    this.disabled = true;
    this.spinner.show();
    this.emprendedorServ.setNewEmprendedor(this.newEmprendedor).subscribe(
      response => {

        if (response.error && response.error.codigo) {
          this.alertSrv.showAlert(response.error.mensaje, "Error");
          this.spinner.hide();
          this.disabled = false;
          return;
        }
        let estadoClasificacionDelProyecto = this.state.getInfo("basico", "estadoClasificacionDelProyecto");
        let estadoCreacionDelaEstrategia = this.state.getInfo("basico", "estadoCreacionDelaEstrategia");
        //limpiamos los campos del formulario
        this.informationForm.reset;
        this.stateSrv.validateNewUser(this.newEmprendedor.correo);
        this.state.updateBasico('estadoClasificacionDelProyecto', estadoClasificacionDelProyecto);
        this.state.updateBasico('estadoCreacionDelaEstrategia', estadoCreacionDelaEstrategia);
        this.stateSrv.updateBasico("idEmprendedor", response.datos.idUsuario);
        this.stateSrv.updateBasico("codPaisEmprendedor", this.informationForm.value.country);
        this.stateSrv.updateBasico("nombres", this.informationForm.value.name);
        this.stateSrv.updateBasico("apellidos", this.informationForm.value.surname);
        this.state.updateBasico('tipoEmprendedor', response.datos.tipoSubscripcion.toLowerCase());
        this.state.updateBasico('fechaVencimientoSub', this.calulcarVencimiento(response.datos.tipoSubscripcion.toLowerCase()));
        this.state.updateBasico('diasValidosSub', response.datos.tipoSubscripcion.toLowerCase() === 'pro' ? 120 : 90);
        this.state.updateBasico('emailEmprendedor', this.informationForm.value.useremail);

        this.disabled = false;
        this.spinner.hide();
        this.router.navigate(['/registered/dashboard']);
      }, e => {
        this.disabled = false;
        this.spinner.hide();
        this.alertSrv.showAlert(mensaje.error, "Error");
        console.log(e);
      }
    );

  }

  //metodo para traer las listas.
  getListas() {
    this.spinner.show();
    this.listasServ.getListas().subscribe(
      res => {
        this.listaGeneros = res.datos.generos;
        this.listaPaises = res.datos.paises;
        this.listaStive = res.datos.comoConocioAstive;
        this.spinner.hide();
      }, e => {
        this.spinner.hide();
        this.alertSrv.showAlert(mensaje.error, "Error");
        console.log(e);
      }
    );
  }

  //metodo para traer la lista de ciudades.
  getListaCiudad(pais: string) {
    this.spinner.show();
    this.listasServ.getListasCiudades({ "pais": pais }).subscribe(
      res => {
        this.spinner.hide();
        this.listaCiudades = res.datos.ciudades;
        this.filteredDescripcionCuenta();
      }, e => {
        this.spinner.hide();
        this.alertSrv.showAlert(mensaje.error, "Error");
        console.log(e);
      }
    );
  }

  //metodo para mostrar ciudades segun el pais.
  onChangePais(pais) {

    if (pais == "") {
      this.listaCiudades = [];

    } else {
      //se llama el servicio de lista solo si la variable es igual a pais.
      this.getListaCiudad(pais);
    }
    if (pais !== 'CO') {
      this.alertSrv.showAlert(mensaje.disponibilidadListasPais, "Error");
      this.disabled = true;
    }
    else {
      this.disabled = false;
    }

  }

  terms() {
    this.dialog.open(TermsComponent, {
      panelClass: 'popup-terminos-condiciones',
      data: {
        title: 'Términos y Condiciones'
      }
    });
  }

  private filteredDescripcionCuenta(): void {
    if (this.listaCiudades) {
      this.filteredNombreCuentas = this.informationForm.controls['city'].valueChanges.pipe(
        startWith(""), map(value => this.filterNombreCuenta(value)));
    }
  }
  private filterNombreCuenta(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.listaCiudades.filter(option => option && option.nombre
      && option.nombre.toLowerCase().indexOf(filterValue) === 0);
  }

  getValorCiudadPorNombre(listaGenerica, nombreFiltro) {
    for (let i = 0; i < listaGenerica.length; i++) {
      if (listaGenerica[i].nombre === nombreFiltro) {
        return listaGenerica[i].codigo;
      }
    }
  }

  validarDatosPersonales() {
    if (this.informationForm.controls.name.status == 'INVALID' ||
      this.informationForm.controls.surname.status == 'INVALID' ||
      this.informationForm.controls.age.status == 'INVALID' ||
      this.informationForm.controls.gender.status == 'INVALID' ||
      this.informationForm.controls.phone.status == 'INVALID' ||
      this.informationForm.controls.country.status == 'INVALID' ||
      this.informationForm.controls.city.status == 'INVALID' ||
      this.informationForm.controls.useremail.status == 'INVALID' ||
      this.informationForm.controls.password.status == 'INVALID') {
      this.mostrarSeccion2 = false;
      this.mostrarBRS = false;
    } else {
      if (this.informationForm.controls.password.value === this.informationForm.controls.password2.value) {
        this.mostrarSeccion2 = true;
        setTimeout(() => document.getElementById('div-focus-top').scrollIntoView());
        this.mostrarBRS = true;
      } else {
        this.mostrarSeccion2 = false;
        this.mostrarBRS = false;
      }
    }
  }

  validarElSINO(sino) {
    if (sino.value == '2') {
      this.elSI = true;
      this.elNO = false;
    } else if (sino.value == '1') {
      this.elSI = false;
      this.elNO = true;
    }
    this.mostrarBRS = false;
    setTimeout(() => document.getElementById('div-focus-top').scrollIntoView());
  }

  calulcarVencimiento(tipoSub) {
    let tmpDate = new Date(); // Today
    return this.addDaysToDate(tmpDate, tipoSub === 'pro' ? 120 : 90);
  }

  addDaysToDate(date, days) {
    let res = new Date(date);
    res.setDate(res.getDate() + days);
    let datestring = res.getDate() + "-" + (res.getMonth() + 1) + "-" + res.getFullYear();
    return this.dateToDDMMYYYY(res);
  }

  dateToDDMMYYYY(date) {
    let d = date.getDate();
    let m = date.getMonth() + 1; //Month from 0 to 11
    let y = date.getFullYear();
    return '' + (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y;
  }

  abrirVideoDeApoyo() {
    this.videoDeApoyo.showAlert("Registro");
  }


}
