import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import md5 from 'crypto-js/md5';



@Component({
  selector: 'app-planes-subs',
  templateUrl: './planes-subs.component.html',
  styleUrls: ['./planes-subs.component.css']
})
export class PlanesSubsComponent implements OnInit {

  @ViewChild('formLITE') formLite: ElementRef;
  @ViewChild('formPRO')  formPro: ElementRef;
  @ViewChild("signatureLiteTest") signatureLiteTest:  ElementRef;
  @ViewChild("referenceLiteTest") referenceLiteTest:  ElementRef;
  @ViewChild("referencePro")      referencePro:       ElementRef;
  @ViewChild("signaturePro")      signaturePro:       ElementRef;
  //--
  @ViewChild("amountPro")  amountPro: ElementRef; 
  @ViewChild("amountLite") amountLite: ElementRef;
  

  apiKey:                   string = "NDBrUH8i1KtFiGX6C4Cfw3di2c";
  merchantId:               string = "962611";
  referenceCodeLiteDe12mil: string = "SUBSCRIPCION-LITE-";
  amountLiteDePDN:          string = "250000";
  amountProDePDN:           string = "350000";
  currency:                 string = "COP";
  mostrarPrecios: boolean = true;
  //producto PRO
  referenceCodePro:        string = "SUBSCRIPCION-PRO-";
  //--
  URL_RTA_PAYU:     string = "https://stivedev-front.com/register-payu";
  URL_CONF_PAYU:    string = "https://apirest.stivedev-back.com:8100/v1/emprendedor/confirmacionPago";
  URL_RTA_PAYU_PDN:     string = "https://stiveai.com/register-payu";
  URL_CONF_PAYU_PDN:    string = "https://api.stiveai.com:8100/v1/emprendedor/confirmacionPago";

  
  constructor( private spinner: NgxSpinnerService,) { 

  }

  ngOnInit(): void {
    setTimeout(()=>{
      setTimeout(() =>this.mostrarPrecios = false );
    }, 50);
  }

  submitFormLite(){
    this.amountLite.nativeElement.value  = this.amountLiteDePDN;
    let referenciaRandomica = this.calculoRandomico();
    let referenciaCompleta = this.referenceCodeLiteDe12mil + referenciaRandomica;
    this.referenceLiteTest.nativeElement.value = referenciaCompleta;
    this.signatureLiteTest.nativeElement.value = this.crearFirmaCompra(referenciaCompleta,this.amountLiteDePDN);
    this.formLite.nativeElement.submit();
    this.spinner.show();
  }

  submitFormPro(){
    this.amountPro.nativeElement.value    = this.amountProDePDN;
    let referenciaRandomica               = this.calculoRandomico();
    let referenciaCompleta                = this.referenceCodePro + referenciaRandomica;
    this.referencePro.nativeElement.value = referenciaCompleta;
    this.signaturePro.nativeElement.value = this.crearFirmaCompra(referenciaCompleta,this.amountProDePDN);
    this.formPro.nativeElement.submit();
    this.spinner.show();
  }

  crearFirmaCompra(referenciaCompleta: string, amount: string) {  
    let hash = md5(
        this.apiKey                 + "~" +
        this.merchantId             + "~" +
        referenciaCompleta          + "~" +
        amount                      + "~" +
        this.currency
    ).toString();
    return hash;
  }

  calculoRandomico(){
    return Math.random().toString(36).substr(2, 9);
  }

  validarMostrarPrecios(){
    this.mostrarPrecios = true;
    let top = document.getElementById('div-focus-top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
    setTimeout(()=>{
      setTimeout(() => document.getElementById('div-focus-top').scrollIntoView());
    }, 50);
  }

  mostrarResumen(){
    let top = document.getElementById('div-principal');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
    setTimeout(()=>{
      setTimeout(() => document.getElementById('div-principal').scrollIntoView());
    }, 50);
  }

}
