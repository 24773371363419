import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginPlanesSubsComponent } from '@app/login/popup/subs/login-planes-subs/login-planes-subs.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-init-page-center',
  templateUrl: './init-page-center.component.html',
  styleUrls: ['./init-page-center.component.css']
})
export class InitPageCenterComponent implements OnInit {

  videoURL:any;
  videoReady:boolean = false;
  mostrarInfoEmprendedores = false;
  mostrarInfoInstituciones = false;
  mostrarInfoGobierno = false;

  constructor(
    public router:Router,
    public dialog: MatDialog) { 

  }

  ngOnInit(): void {
    this.clicEnEmprendedores();
  }

  clicEnEmprendedores(){
    this.mostrarInfoEmprendedores = true;
    this.mostrarInfoInstituciones = false;
    this.mostrarInfoGobierno = false;
  }

  clicEnInstitucioness(){
    this.mostrarInfoEmprendedores = false;
    this.mostrarInfoInstituciones = true;
    this.mostrarInfoGobierno = false;
  }

  clicEnGobierno(){
    this.mostrarInfoEmprendedores = false;
    this.mostrarInfoInstituciones = false;
    this.mostrarInfoGobierno = true;
  }

  irAIniciarS(){
    this.router.navigate(['/login']);
  }

  irARegister(){
    this.router.navigate(['/register-payu']);
  }

  irAAdquiereTuSub(){
    let dialogBoxSettings = {
      height: '675px',
      width: '1050px',
      margin: '0 auto',
      disableClose: false,
      hasBackdrop: true,
      panelClass: 'popup-tipo-subs',
    };
    const dialogRef = this.dialog.open(
      LoginPlanesSubsComponent,
      dialogBoxSettings,
    );
  }

}
