<nav>
  <a class="nav-enlace como-funciona-text">
    <img src="../../../../assets/img/iconos-ver-1/Stive 1.png" (click)="irAInitP();" class="logo-arriba" title="home">
  </a>
  <a class="nav-enlace"><span class="como-funciona-text" (click)="irAComoFunciona();">¿CÓMO FUNCIONA?</span></a>
  <!--a class="nav-enlace"><span class="como-funciona-text" (click)="irAContacto();"     style="cursor: none;"></span></a>
  <a class="nav-enlace"><span class="como-funciona-text" (click)="irAExperiencia();"  style="cursor: none;"></span></a>
  <a class="nav-enlace"><span class="como-funciona-text" (click)="irAAlianzas();"     style="cursor: none;"></span></a>
  <a class="nav-enlace"><span class="como-funciona-text" (click)="irAAcademiia();"    style="cursor: none;"></span></a-->
  <a class="nav-enlace"><button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon class="icono-menu">menu</mat-icon></button></a>
</nav>

<!-- OPCIONES MENU -->
<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="irARegister()">
      <mat-icon>description</mat-icon>
      <span>Regístrate</span>
    </button>
    <button mat-menu-item (click)="irAIniciarS()">
      <mat-icon>touch_app</mat-icon>
      <span>Inicia Sesión</span>
    </button>
  </mat-menu>
<router-outlet></router-outlet>
