<button  *ngIf="videoReady; else elseBlock" type="button" class="btnmodal sistemas boton-principal boton-tipou-3"  
        style="cursor: unset;width: 96%;margin: 15px;">
    <span class="titulo-boton-tipou-2"> <b>Stive para emprendedores</b></span>
    <br><span class="texto-boton-tipou-2"><br>Creemos que el futuro de Latinoamérica está en el emprendimiento...<br>
        Y el futuro del emprendimiento está en la innovación y la competitividad.
        </span>
    <br><br><img style="width: 110px;vertical-align: super;" src="../../../../assets/img/iconos-ver-1/Stive 2.png"> <br><br> <br>
   
      <div class="row">
        <div class="column column-1">
          <p style="text-align: center;"> Un Consultor virtual que ayuda a los emprendedores a Co-crear una estrategia de negocios para su emprendimiento. 
          Aumentando la probabilidad de éxito en la venta de sus productos/servicios y fortaleciendo el negocio.</p>
        </div>
        <div class="column column-2">
            <span class="titulo-boton-tipou-2"> <b>Mira la introducción a Stive&nbsp;&nbsp; &nbsp;&nbsp; </b></span>
        </div>
        <div class="column column-3">
                <iframe class="responsive-iframe video-size" [src]="videoURL" frameborder="4"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                </iframe>

        </div>
      </div>


</button>
<ng-template #elseBlock><mat-spinner style="margin-left: 50%;"></mat-spinner></ng-template>
<!--footer -->
<app-init-page-footer></app-init-page-footer>