<app-header-layout></app-header-layout>
<div class="row">
    <div class="container">
        <section class="estrategia">
            <article class="row">
                <h4>Perfil Proyecto</h4>
                <!--SEMI - stepper-->
                <app-footer-layout style="width: 100%;"></app-footer-layout>
            </article>
            <article class="row pc">
                <div class="col-md-8 text-center">
                    <p class="text-center">En el tablero se muestran las 9 posiciones que puede tomar un emprendimiento para competir en el mercado.</p>
                </div>
                <div class="col-md-4 text-center is">
                    <button type="submit" class="btn btn-primary" (click)="dashboard()">
                        <mat-icon matSuffix id="iconoVideo">keyboard_backspace</mat-icon>
                        Volver al dashboard
                    </button><br><br>
                    <button type="submit" class="btn btn-primary va fondo-boton-video" (click)="openVideoIntroductorio()">
                        <mat-icon matSuffix id="iconoVideo">video_library</mat-icon>
                        Video de apoyo
                    </button>
                </div>
            </article>
            <p class="text-center do">Antes de tomar la decisión de tu posición competitiva, prueba seleccionar diferentes opciones para ver más información.</p>
            <article class="row">
                <div class="col-md-3 tl">
                    <p class="texto-uno"> El puntaje representa la recomendación de Stive para tu negocio.<br><br><br></p>
                    <p class="margen-text texto-dos"> Los puntajes positivos son oportunidad de crecer</p><br><br><br><br><br>
                    <p class="margen-text texto-tres"> Los puntajes negativos son riesgos para el negocio</p><br>
                    <img src="../../../../../assets/img/Grafica puntaje.png" class="img-respo">
                </div>
                <div class="col-md-6 text-center">
                    <div class="row">
                        <div id="wrap">
                            <div class="tabList">
                                <!--  Tab Starts Here  -->
                                <div class="tabList-list-wrap">
                                    <ul class="tabList-list p-0">
                                        <a (click)="verDetalle(i)" *ngFor="let propuesta of propuestaValor; index as i">
                                            <li 
                                                class="tabList-list-item altura-seleccionado" 
                                                [class.selected]="propuesta===propuestaValorSelected"  
                                                [ngStyle]="{'color': propuesta===propuestaValorSelected ? 'white' : '#F5F5F5','background': propuesta.color}" >
                                                    <mat-icon class="estilo-icon-seleccionado pos-icono" *ngIf="propuesta === propuestaConMayorPromedio"  >star</mat-icon>
                                                    <h3 class="estilo-font-seleccionado" [ngStyle]="{'color': propuesta===propuestaValorSelected ? 'white' : 'darkslategray'}">{{propuesta.promedio}}</h3>
                                                    <p  class="texto-cuatro" [ngStyle]="{'color': propuesta===propuestaValorSelected ? 'white' : 'darkslategray'}" >{{propuesta.nombre}}</p>
                                            </li>
                                        </a>
                                    </ul>
                                </div>
                            </div>
                            <!--  Tab Ends Here  -->
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="row" id="contentcard" *ngIf="propuestaValorSelected && propuestaValorSelected.nombre">
                        <div class="tabList-content_">
                            <div class="tabList-tab_ active">
                                <p>{{propuestaValorSelected.nombre}}</p>
                                <img src="{{propuestaValorSelected.icono}}" alt="" width="50%">
                                <p>{{propuestaValorSelected.descripcion}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
            <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-4 mt-4 alineado-center">
                    <button type="submit" class="btn btn-primary"  id="pvalor" [disabled]="disabled" (click)="seleccionar()" >
                        Seleccionar propuesta de valor
                    </button>
                </div>
                <div class="col-md-4"></div>
            </div>
        </section>
    </div>
</div>
<ngx-spinner></ngx-spinner>
