import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ListasService } from '@services/index';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService } from '@app/_modal/alert/alert.service';
import { mensaje } from '@app/_const/alert_error_message';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { LoginPlanesSubsComponent } from '@app/login/popup/subs/login-planes-subs/login-planes-subs.component';

@Component({
  selector: 'app-funcionamiento-arriba',
  templateUrl: './funcionamiento-arriba.component.html',
  styleUrls: ['./funcionamiento-arriba.component.css']
})
export class FuncionamientoArribaComponent implements OnInit {

  videoURL:any;
  videoReady:boolean = false;
  mostrarTxt:boolean = false;

  constructor(public router:Router,
    private alertSrv: AlertService,
    public listSrv: ListasService,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog) { 

   }

   ngOnInit(): void {
    this.getVideo();
  }

  getVideo(){
    this.spinner.show();
    this.listSrv.getVideos().subscribe(
      res=>{
        res.datos.videosList.filter(v => {
          if(v.clave === "DashboardEmpresarial"){
            this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(v.url);
            this.videoReady = true;
          }
          this.spinner.hide();
        });
      },
      error=>{
        this.spinner.hide();
        this.alertSrv.showAlert(mensaje.error, "Error");
      }
    );
  }

  irAIniciarS(){
    this.router.navigate(['/register-payu']);
  }

  irAAdquiereTuSub(){
    let dialogBoxSettings = {
      height: '675px',
      width: '1050px',
      margin: '0 auto',
      disableClose: false,
      hasBackdrop: true,
      panelClass: 'popup-tipo-subs',
    };
    const dialogRef = this.dialog.open(
      LoginPlanesSubsComponent,
      dialogBoxSettings,
    );
  }

}
