<header class="headerppal p-4">
    <div class="row">
        <div class="col-md-4 col-0"></div>
        <div class="col-md-4 col-5 text-center">
          <img *ngIf="this.tipoEmprendedor=='lite'" src="../../../../assets/img/Stive lite.png" (click)="dashboard()" alt="volver al dashboard" title="volver al dashboard" style="width: 150px;height: 61px;">
          <img *ngIf="this.tipoEmprendedor!='lite'" src="../../../../assets/img/Stive pro.png" (click)="dashboard()" alt="volver al dashboard" title="volver al dashboard"  style="width: 150px;height: 61px;">
        </div>
        <div class="col-md-4 col-7 text-right">
          <div class="row">
            <div class="row col-md-10">
              <div class="col-md-12">{{name}}</div>
              <div class="col-md-12" *ngIf="nameProject">{{nameProject}}</div>
            </div>
            <div class="col-md-2"> 
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>menu</mat-icon>
              </button>
            </div>
          </div>
        </div>
    </div>
</header>
<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="editarPerfil()">
      <mat-icon>supervisor_account</mat-icon>
      <span>Editar perfil</span>
    </button>
    <button mat-menu-item (click)="gestionarPlan()">
      <mat-icon>account_balance_wallet</mat-icon>
      <span>Gestionar plan</span>
    </button>
    <button mat-menu-item (click)="close()">
      <mat-icon>exit_to_app</mat-icon>
      <span>Cerrar sesión</span>
    </button>
  </mat-menu>
<router-outlet></router-outlet>
