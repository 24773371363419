import { Inject, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import md5 from 'crypto-js/md5';
import { StateService } from '@app/_helpers/state.service';
import { AlertService } from '@app/_modal/alert/alert.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { EmprendedorService } from '@app/_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gestionar-plan',
  templateUrl: './gestionar-plan.component.html',
  styleUrls: ['./gestionar-plan.component.css']
})
export class GestionarPlanComponent implements OnInit {

  @ViewChild('formLITE') formLite:  ElementRef;
  @ViewChild('formPRO') formPro:    ElementRef;
  @ViewChild("signatureLiteTest") signatureLiteTest:      ElementRef;
  @ViewChild("referenceLiteTest") referenceLiteTest:      ElementRef;
  @ViewChild("pgnConfirmacionLite") pgnConfirmacionLite:  ElementRef;
  @ViewChild("pgnConfirmacionPro")  pgnConfirmacionPro:   ElementRef;
  @ViewChild("buyerEmailLite")      buyerEmailLite:       ElementRef;
  @ViewChild("buyerEmailPro")       buyerEmailPro:        ElementRef; 

  //producto PRO
  referenceCodePro: string = "SUBSCRIPCION-PRO-";
  @ViewChild("referencePro")      referencePro:       ElementRef;
  @ViewChild("signaturePro")      signaturePro:       ElementRef;
  //--
  @ViewChild("paginaRtaPro")      paginaRtaPro:        ElementRef; 
  @ViewChild("paginaRtaLite")     paginaRtaLite:       ElementRef; 
  //--
  @ViewChild("amountPro")  amountPro: ElementRef; 
  @ViewChild("amountLite") amountLite: ElementRef; 
  

  apiKey:                   string = "NDBrUH8i1KtFiGX6C4Cfw3di2c";
  apiKeyTest:               string = "4Vj8eK4rloUd272L48hsrarnUA";
  merchantId:               string = "962611";
  merchantIdTest:           string = "508029";
  referenceCodeLiteDe12mil: string = "SUBSCRIPCION-LITE-";;
  precioSubPro:             string = "350000";
  precioSubLite:            string = "250000"; 
  esUnaRenovacion:          boolean = false;

  currency:                 string = "COP";
  mostrarPrecios:           boolean = true;
  fechaVencimientoSub:      String = "";
  tipoDeSub:                String = "";
  diasValidosSub:           number = 120;
  esProYPuedeComprarLite:   boolean = false;
  esLiteYPuedeComprarPro:   boolean = false;
  esPro:                    boolean = false;
  esLite:                   boolean = false;
  diasDeVtoMenoresEIgualesA30:      boolean = false;
  habilitarBotonPro:        boolean = false;
  habilitarBotonLite:       boolean = false;
  textoTuPlanLite:          string = "Tu plan actual";
  textoTuPlanPro:           string = "Tu plan actual";
  esUneRenovacion:          boolean = false;
  codigoEmprendedor:        string = "";
  codigoProyecto:           string = "";
  emailEmprendedor:         string = "";
  mensaje : string = "";
  URL_DASHBOARD:    string = "https://stivedev-front.com/registered/dashboard";
  URL_CAMBIAR_SUB:  string = "https://apirest.stivedev-back.com:8100/v1/emprendedor/confirmacionPagoCambiarSub";
  URL_RENOVAR_SUB:  string = "https://apirest.stivedev-back.com:8100/v1/emprendedor/confirmacionPagoRenovarSub";
  URL_LOGIN:        string = "https://stivedev-front.com/login";
  //PDN
  URL_DASHBOARD_PDN:    string = "https://stiveai.com/registered/dashboard";
  URL_CAMBIAR_SUB_PDN:  string = "https://api.stiveai.com:8100/v1/emprendedor/confirmacionPagoCambiarSub";
  URL_RENOVAR_SUB_PDN:  string = "https://api.stiveai.com:8100/v1/emprendedor/confirmacionPagoRenovarSub";
  URL_LOGIN_PDN:        string = "https://stiveai.com/login";
  subVencida:       boolean = false;
  precioRenovacionProConDto:  number = Number(this.precioSubPro)/2;
  precioRenovacionLiteConDto: number =  Number(this.precioSubLite)/2;
  amountLiteDePDN:            string = "125000";
  amountProDePDN:             string = "175000";

  constructor( 
    private state: StateService, 
    private spinner: NgxSpinnerService, 
    private alertSrv: AlertService, 
    public dialog: MatDialog,
    private emprendedorServ: EmprendedorService,
    private router: Router,) { 

  }

  

  ngOnInit(): void {
    this.spinner.hide();
    this.tipoDeSub = this.state.getInfo("basico", "tipoEmprendedor");
    if (this.tipoDeSub === "pro") {
      this.precioRenovacionLiteConDto = Number(this.precioSubLite);
    } else if (this.tipoDeSub === "lite") {
      this.precioRenovacionProConDto = Number(this.precioSubPro);
    }
    //---
    this.subVencida = this.state.getInfo("basico", "subVencida");
    this.codigoEmprendedor  = this.state.getInfo('basico', 'idEmprendedor');
    this.codigoProyecto     = this.state.getInfo('basico', 'codProyecto');
    this.emailEmprendedor   = this.state.getInfo('basico', 'emailEmprendedor');
    this.verificarRenovacion();
    this.mensaje = "Tu plan actual de Stive vence el " + this.fechaVencimientoSub + "." + 
    "Podrás cambiar tu plan de Stive cuando esté cercana la fecha de vencimiento de tu suscripción actual";
    setTimeout(()=>{
      setTimeout(() =>this.mostrarPrecios = false );
    }, 500);
  }

  limpiarData(){
    this.state.updateBasico('diasValidosSub',                   0);
    this.state.updateBasico('fechaVencimientoSub',              "");
    this.state.updateBasico('tipoSubscripcionModificadaActiva', "");
    this.state.updateBasico('codigoSub',                        "");
  }

  verificarRenovacion(){
      const request = {
        "correo":    this.state.getInfo('basico', 'emailEmprendedor'),
        "password": "basico"
      };
     
      //soliictar nueva fecha de vencimiento
      this.emprendedorServ.inicioSesionEmprendedor(request).subscribe(
        response => {
          if(response.datos){
            this.limpiarData();
            this.state.updateBasico('diasValidosSub',                   response.datos.diasValidosSub);
            this.state.updateBasico('fechaVencimientoSub',              response.datos.fechaVencimientoSub);
            this.state.updateBasico('tipoSubscripcionModificadaActiva', response.datos.tipoSubscripcionModificadaActiva);
            this.state.updateBasico('codigoSub',                        response.datos.codigoSub);
            //--
            this.fechaVencimientoSub  = response.datos.fechaVencimientoSub;
            this.tipoDeSub            = this.state.getInfo("basico", "tipoEmprendedor");
            this.diasValidosSub       = response.datos.diasValidosSub>0?response.datos.diasValidosSub:0;
            //--
            this.validarTipoDeSub();
            this.validarDiasDeVto();
            this.validarBotoneras();
          }else{
            console.log("inicioSesionEmprendedor sin datos");
          }
        },
        error => {console.log(error);}
      );
  }

  validarTipoDeSub() {
    if (this.tipoDeSub === "pro") {
      this.esPro = true;
      this.textoTuPlanLite = "         ";
    } else if (this.tipoDeSub === "lite") {
      this.esLite = true;
      this.textoTuPlanPro = "         ";
    }
  }

  validarDiasDeVto(){
    if(this.diasValidosSub<=30){
      this.diasDeVtoMenoresEIgualesA30 = true;
    }
  }

  validarBotoneras(){
    if( (this.esPro) || (this.esLite && this.diasDeVtoMenoresEIgualesA30) ){
      this.habilitarBotonPro = true;
    }
    if( (this.esLite) || (this.esPro && this.diasDeVtoMenoresEIgualesA30) ){
      this.habilitarBotonLite = true;
    }
  }
  

  submitFormLite(){
    if(this.habilitarBotonLite){
      this.amountLite.nativeElement.value  = this.amountLiteDePDN;
      this.buyerEmailLite.nativeElement.value    = this.emailEmprendedor;
      let referenciaRandomica = this.calculoRandomico();
      let referenciaCompleta  = this.referenceCodeLiteDe12mil + referenciaRandomica;
      this.referenceLiteTest.nativeElement.value    = referenciaCompleta;
      this.signatureLiteTest.nativeElement.value    = this.crearFirmaCualquierTest(referenciaCompleta, this.amountLiteDePDN, this.apiKey, this.merchantId);
      this.pgnConfirmacionLite.nativeElement.value  = this.URL_CAMBIAR_SUB;
      this.paginaRtaLite.nativeElement.value        = this.URL_DASHBOARD;
      if(this.esLite){
        this.esUneRenovacion      = true;
        this.pgnConfirmacionLite.nativeElement.value  = this.URL_RENOVAR_SUB;
        if(this.subVencida){
          this.paginaRtaLite.nativeElement.value  = this.URL_LOGIN;
        }
        this.formLite.nativeElement.submit();
        this.spinner.show();
      }else{
        //es un cambio de plan de pro a lite
        this.paginaRtaLite.nativeElement.value  = this.URL_LOGIN;
        this.confirmacionCambioDeProALite();
      }
    }else{
      this.alertSrv.showAlert("Podrás cambiar tu plan, faltando un mes para su vencimiento.");
    }
  }

  submitFormPro(){
    if(this.habilitarBotonPro){
      this.amountPro.nativeElement.value            = this.amountProDePDN;
      this.buyerEmailPro.nativeElement.value        = this.emailEmprendedor;
      this.pgnConfirmacionPro.nativeElement.value   = this.URL_CAMBIAR_SUB;
      this.paginaRtaPro.nativeElement.value         = this.URL_DASHBOARD;
      if(this.esPro){
        this.esUneRenovacion = true;
        if(this.subVencida){
          this.paginaRtaPro.nativeElement.value  = this.URL_LOGIN;
        }
        this.pgnConfirmacionPro.nativeElement.value  = this.URL_RENOVAR_SUB;
      }else{
        //es un cambio de plan de lite a pro
        this.paginaRtaPro.nativeElement.value  = this.URL_LOGIN;
      }
      //--
      let referenciaRandomica               = this.calculoRandomico();
      let referenciaCompleta                = this.referenceCodePro + referenciaRandomica;
      this.referencePro.nativeElement.value = referenciaCompleta;
      this.signaturePro.nativeElement.value = this.crearFirmaCualquierTest(referenciaCompleta, this.amountProDePDN, this.apiKey, this.merchantId);
      //--
      this.formPro.nativeElement.submit();
      this.spinner.show();
    }else{
      this.alertSrv.showAlert("Podrás cambiar tu plan, faltando un mes para su vencimiento.");
    }
  }

  crearFirmaLiteTes(referenciaCompleta: string) {  
    let hash = md5(
        this.apiKey                 + "~" +
        this.merchantId             + "~" +
        referenciaCompleta          + "~" +
        500    + "~" +
        this.currency
    ).toString();
    return hash;
  }

  calculoRandomico(){
    return Math.random().toString(36).substr(2, 9);
  }

  validarMostrarPrecios(){
    this.mostrarPrecios = true;
    let top = document.getElementById('div-focus-top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
    setTimeout(()=>{
      setTimeout(() => document.getElementById('div-focus-top').scrollIntoView());
    }, 50);
  }

  confirmacionCambioDeProALite(): void {
    let dialogRef =this.dialog.open(ConfirmacionCambioDePlan, {
      panelClass: 'popup-confirmacion',
      data: { 
        acepta: "NO"
      }
    });
    //--
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.formLite.nativeElement.submit();
        this.spinner.show();
      }else{
        console.log('The dialog was closed y NO acepto EL CAMBIO');
      }
    });
  }

  mostrarResumen(){
    let top = document.getElementById('div-principal');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
    setTimeout(()=>{
      setTimeout(() => document.getElementById('div-principal').scrollIntoView());
    }, 50);
  }

  crearFirmaCualquierTest(referenciaCompleta: string, amount: string, apiKey: string, merchantId: string) {  
    let hash = md5(
        apiKey                 + "~" +
        merchantId             + "~" +
        referenciaCompleta     + "~" +
        amount                 + "~" +
        this.currency
    ).toString();
    return hash;
  }

  dashboard(){
    this.router.navigate(['registered/dashboard']);
  }



}

/*--------------------------------------------------------------------------------------------------------------------------*/

@Component({
  selector: 'confirmacion-cambio-plan-dialog',
  templateUrl: 'confirmacion-cambio-plan-dialog.html',
})

export class ConfirmacionCambioDePlan {

  constructor(public dialogRef: MatDialogRef<ConfirmacionCambioDePlan>,@Inject(MAT_DIALOG_DATA) public data: any) { 

  }

  noAceptarCambio(): void {
    this.dialogRef.close();
  }

  aceptarCambio(val){ 
    this.dialogRef.close(val);
  }

}
