import { Component, Input, OnInit } from '@angular/core';
import { ProyectoService } from '@services/index';
import { AlertService } from '@app/_modal/alert/alert.service';
import { mensaje } from '@app/_const/alert_error_message';
import { StateService } from '@app/_helpers/state.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-layout',
  templateUrl: './footer-layout.component.html',
  styleUrls: ['./footer-layout.component.css']
})
export class FooterLayoutComponent implements OnInit {

  @Input() id: boolean[] = [];
  editando : boolean = false;
  editandoCompetencia : boolean = false;
  editandoTipoEmprendedor : boolean = false;

  
  constructor(
    public alertSrv: AlertService,
    private proyServ: ProyectoService,
    private state: StateService,
    public router:Router,
  ) {

   }

  arrayFaseEmprendedor: any[];
  arrayFaseProyecto: any[];
  arrayFaseEstrategia: any[];
  arrayFaseEstrategiaEdicion: any[];
  arrayTactics:any[];
  footer: string;

  
  ngOnInit(): void {
    if(this.router.url.includes('/competencias')){
      this.editandoCompetencia = true;
    }
    if(this.router.url.includes('/tipo-emprendedor')){
      this.editandoTipoEmprendedor = true;
    }
    this.editando = this.state.getInfo("basico", "stepperEnEdicion");
    this.footer = this.state.getInfo('basico','footer');
    this.routeEntrepreneur();
    this.routeProject();
    this.routeEstrategia(); 
    this.arrayFaseEstrategiaEdicion = [
      {
        'nombre': 'Editar Prioridades estratégicas',
        'estado': false,
        'route': 'registered/project/strategy'
      },
      {
        'nombre': 'Editar selección de tácticas',
        'estado': false,
        'route': 'registered/project/tactics'
      }
    ];
  }

  routeEntrepreneur(){
    this.arrayFaseEmprendedor = this.state.getInfo('estadoEmprendedor');
    for (let i = 0; i < this.arrayFaseEmprendedor.length ; i ++){
      if (this.arrayFaseEmprendedor[i].estado === false){
        for (let j = i; j >= 0 ; j --) {
          this.arrayFaseEmprendedor[j].color = true;
        }
        return;
      }
    }
   }

   routeProject(){
    this.arrayFaseProyecto = this.state.getInfo('estadoProyecto');
    for (let i = 0; i < this.arrayFaseProyecto.length ; i ++){
      if (this.arrayFaseProyecto[i].estado === false){
        for (let j = i; j >= 0 ; j --) {
          this.arrayFaseProyecto[j].color = true;
        }
        return;
      }
    }
   }

   routeEstrategia(){
    this.arrayFaseEstrategia = this.state.getInfo('estadoEstrategia');
    for (let i = 0; i < this.arrayFaseEstrategia.length; i++){
      if (this.arrayFaseEstrategia[i].nombre === 'Sistema de negocio 1'){
        this.actualizarSistemas(i);
      }
      if (this.arrayFaseEstrategia[i].estado === false){
          for (let j = i; j >= 0 ; j --) {
            this.arrayFaseEstrategia[j].color = true;
          }
          return;
      }
    }
  }

   actualizarSistemas(index:number){
    const request = {
      "codigoEmprendedor":this.state.getInfo("basico", "idEmprendedor"),
      "codigoProyecto":this.state.getInfo("basico", "codProyecto")
    };
    this.proyServ.getTacticas(request).subscribe(
      response=>{
        if(!(response.datos)){
          return;
        }else{
          this.arrayTactics = response.datos.sistemasList;
          for (let i = 0; i < this.arrayTactics.length; i++) {
            this.arrayFaseEstrategia[index+i].nombre = this.arrayTactics[i].nombre;
          }
        }
      },
      error=>{
        this.alertSrv.showAlert(mensaje.error, "Error");
        console.log(error);
      }
    );
  }

  irA(){
    this.editandoCompetencia ? 
    this.router.navigate(['editar/tipo-emprendedor']) : this.router.navigate(['editar/competencias']);
  }

  proyectoIrA(aDondeIr:string){
    if(aDondeIr === 'Inscripción de proyecto'){
      this.router.navigate(['editar/proyecto'])
    }
    if(aDondeIr === 'Fase y momento de negocio'){
      this.router.navigate(['editar/fase-momento'])
    }
    if(aDondeIr === 'Capacidad del negocio'){
      this.router.navigate(['editar/capacidades'])
    }
    if(aDondeIr === 'Posición competitiva'){
      this.router.navigate(['editar/propuesta'])
    }
  }

  irASeleccionTacticas(ruta: string){
    this.router.navigate([ruta]);
  }


}
