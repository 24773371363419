import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmprendedorService, ProyectoService } from '@app/_services';
import { AlertService } from '@app/_modal/alert/alert.service';
import { mensaje } from '@app/_const/alert_error_message';
import { StateService } from '@app/_helpers/state.service';
import { ListasService } from '@services/index';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ForgotPwdComponent } from "@app/login/popup/forgot-pwd/forgot-pwd.component";
import { MensajeComponent } from '@app/login/popup/mensaje/mensaje.component';
import { VideoService } from '@app/_modal/video-introductorio/video-introductorio.service';

@Component({
  selector: 'app-registro-edicion',
  templateUrl: './registro-edicion.component.html',
  styleUrls: ['./registro-edicion.component.css']
})
export class RegistroEdicionComponent implements OnInit {

@ViewChild('formLITE')          formLite:           ElementRef;
@ViewChild("buyerFullNameLite") buyerFullNameLite:  ElementRef;
@ViewChild("buyerEmailLite")    buyerEmailLite:     ElementRef;
//--
@ViewChild('formPRO')          formPro:            ElementRef;
@ViewChild("buyerFullNamePro") buyerFullNamePro:  ElementRef;
@ViewChild("buyerEmailPro")    buyerEmailPro:     ElementRef;
  
constructor(
  private formBuilder: FormBuilder,
  private route: ActivatedRoute,
  private router: Router,
  private spinner: NgxSpinnerService,
  private alertSrv: AlertService,
  private state: StateService,
  private proySrv: ProyectoService,
  private emprendedorServ: EmprendedorService,
  public listSrv: ListasService,
  private sanitizer: DomSanitizer,
  public dialog: MatDialog,
  private listasServ: ListasService,
  private stateSrv: StateService,
  private videoDeApoyo: VideoService,) { 


}


informationForm: FormGroup;
informationSubmitted = false;
newEmprendedor: any;
submitted = false;
// Datos de prueba para pasar ID por URL
idUsuario: String;
inicialDateForm: string = '';
loginForm: FormGroup;
registerForm: FormGroup;
loginSubmitted = false;
registerSubmitted = false;
dateSubmitted = false;
vp: String = 'password';
vpc = false;
responseTactics:any[];
codigoEmprendedor: String;
videoURL:any;
videoReady:boolean = false;
correoEmprendedor:any;
dateSubmitted2 = false;
listaGeneros: any[] = [];
listaPaises: any[] = [];
listaStive: any[] = [];
listaCiudades: any[] = [];
emprendedor: any[] = [];
paisActual: boolean;
datosForm: any;
disabled: boolean = false;
credentials: any;
emprendedorDB: any;
datosEmprendedor: any;
mostrarSeccion1 = true;
mostrarSeccion2 = false;

ngOnInit() {
  this.registerForm = this.formBuilder.group({
      useremail: ['', [Validators.required, Validators.email]],
      password: ['P6be3b6607ab84ebc861afdd2b3273c5517b582ab', [
                    Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.$@$!%*?&-_#])[A-Za-z\d.$@$!%*?&-_#].{8,}')]],
      password2: ['P6be3b6607ab84ebc861afdd2b3273c5517b582ab', ],
      name: ['', [Validators.required,    Validators.pattern(/^[ÑÁÉÍÓÚA-Z ]?[a-záéíóúñ ]+(\s+[ÑÁÉÍÓÚA-Z ]?[a-záéíóúñ ]+)*$/), Validators.maxLength(100)]],
      surname: ['', [Validators.required, Validators.pattern(/^[ÑÁÉÍÓÚA-Z ]?[a-záéíóúñ ]+(\s+[ÑÁÉÍÓÚA-Z ]?[a-záéíóúñ ]+)*$/), Validators.maxLength(100)]],
      age: [ { value: '', disabled: false}, Validators.required],
      phone: ['', [Validators.required, Validators.pattern('[1-9]{1}[0-9]{9}|[1-9]{1}[0-9]{6}'),Validators.maxLength(10)]],
      gender: ['', Validators.required],
      country: ['', Validators.required],
      city: ['', Validators.required],
      ccas: ['', Validators.required],
      cis: [ '', ],
    }, {validator: this.passwordConfirming});
  
      //Definimos nuestro Objeto JSON.
      this.newEmprendedor={
        "nombres": "",
        "apellidos": "",
        "codigoGenero": "",
        "codigoPais": "",
        "codigoCiudad": "",
        "opcionComoConocioStive":"",
        "telefono": "",
        "correo": "",
        "password": "",
        "fechaNacimiento": "",
        "codigoEmprendedor": "",
        "generoTexto": "",
        "paisTexto": "",
        "ciudadTexto": "",
        "conocioStiveTexto": "",
        "actualizarClave": false
      };
    this.codigoEmprendedor = this.state.getInfo("basico", "idEmprendedor");
    this.getListas();
    this.getVideo();
}

validarEdadEnLaFecha(fechaAValidar) {
  if(fechaAValidar && fechaAValidar.length>0){
    let fechaAValidarDate = new Date(fechaAValidar.substring(0,4),parseInt(fechaAValidar.substring(5,7))-1,fechaAValidar.substring(8,11));
    let monthDiff = Date.now() - fechaAValidarDate.getTime();
    //convert the calculated difference in date format
    let ageDt = new Date(monthDiff); 
    //extract year from date    
    let year = ageDt.getUTCFullYear();
    //now calculate the age of the user
    let age = Math.abs(year - 1970);
    //--
    let anoFechaAValidarDate = fechaAValidarDate.getFullYear();
    let anoFechaActual = new Date().getFullYear();
    let edadCalculadaSoloAnos = anoFechaActual - anoFechaAValidarDate;
    if (age > 100 || age < 13) {
      this.dateSubmitted2 = true;
      return false;
    }else{
      this.dateSubmitted2 = false;
    }
  }
}

/*Creamos un nuevo validador para confirmar pasdword con password2, para asi asegurarnos
que el campo contraseña confirma con confirmar contraseña*/
passwordConfirming(c: AbstractControl): { invalid: boolean } {
  if (c.get('password').value !== c.get('password2').value) {
      return {invalid: true};
  }
}

// convenience getter for easy access to form fields
get r() { return this.registerForm.controls; }

  //metodo para traer las listas.
  getListas(){
    this.spinner.show();
    this.listasServ.getListas().subscribe(
      res=>{
        this.listaGeneros = res.datos.generos;
        this.listaPaises = res.datos.paises;
        this.listaStive = res.datos.comoConocioAstive;
        this.spinner.hide();
        this.consultEmprendedor();
      }, e=>{
        this.spinner.hide();
        this.alertSrv.showAlert(mensaje.error, "Error");
        console.log(e);
      }
    );
  }

  consultEmprendedor(){
    setTimeout(()=>{
      this.correoEmprendedor = {
        "correo": this.state.getInfo("basico", "emailEmprendedor")
      };
      this.emprendedorServ.consultEmprendedor({ "correo": this.state.getInfo("basico", "emailEmprendedor")}).subscribe(
        response => {
          if (response.datos !== null){
            this.newEmprendedor.nombres                 = response.datos.nombres;
            this.newEmprendedor.apellidos               = response.datos.apellidos;
            this.newEmprendedor.fechaNacimiento         = this.formatearFechaNacimiento(response.datos.fechaNacimiento);
            this.newEmprendedor.codigoGenero            = response.datos.genero;
            this.newEmprendedor.codigoPais              = response.datos.pais;
            this.newEmprendedor.codigoCiudad            = response.datos.ciudad;
            this.newEmprendedor.opcionComoConocioStive  = response.datos.comoConocioAstive;
            this.newEmprendedor.telefono                = response.datos.telefono;
            this.newEmprendedor.correo                  = response.datos.correo;
            this.newEmprendedor.password                = "P6be3b6607ab84ebc861afdd2b3273c5517b582ab";
            //------
            this.registerForm.controls['name'].setValue(this.newEmprendedor.nombres);
            this.registerForm.controls['surname'].setValue(this.newEmprendedor.apellidos);
            this.registerForm.controls['phone'].setValue(this.newEmprendedor.telefono);
            this.registerForm.controls['useremail'].setValue(this.newEmprendedor.correo);
            //--
            this.registerForm.controls['gender'].setValue(this.obtenerValorPorNombre(this.listaGeneros,response.datos.genero));
            let codigoPais = this.obtenerValorPorNombre(this.listaPaises,response.datos.pais);
            this.registerForm.controls['country'].setValue(codigoPais);
            this.obtenerListaDeCiudades(codigoPais,response.datos.ciudad);
            this.registerForm.controls['ccas'].setValue(this.obtenerValorComoConocioStive(this.listaStive,response.datos.comoConocioAstive));
            //--
            this.registerForm.controls['age'].setValue(this.newEmprendedor.fechaNacimiento);
            return;
          }else {
            return;
          }
        },
        e => {
          this.alertSrv.showAlert(mensaje.error, 'Error');
          return;
        }
      );
    }, 500);
  }

    //metodo para traer la lista de ciudades.
    obtenerListaDeCiudades(pais:string,nombreCiudad){
      this.listasServ.getListasCiudades({"pais":pais}).subscribe(
        res=>{
          this.listaCiudades = res.datos.ciudades;
          this.registerForm.controls['city'].setValue(this.getValorCiudadPorNombre(res.datos.ciudades,nombreCiudad));
        }, e=>{
          console.log(e);
        }
      );
    }

    obtenerValorComoConocioStive(listaGenerica, nombreFiltro){
      for(let i = 0; i < listaGenerica.length; i++){
        if(listaGenerica[i].descripcion == nombreFiltro){
          return listaGenerica[i].opcion;
        }
      }
    }

  obtenerValorPorNombre(listaGenerica, nombreFiltro){
    for(let i = 0; i < listaGenerica.length; i++){
      if(listaGenerica[i].nombre == nombreFiltro){
        return listaGenerica[i].codigo;
      }
    }
  }

  getValorCiudadPorNombre(listaGenerica, nombreFiltro){
    for(let i = 0; i < listaGenerica.length; i++){
      if(listaGenerica[i].nombre.indexOf(nombreFiltro+'/') == 0){
        return listaGenerica[i].codigo;
      }
    }
  }

  //metodo para traer la lista de ciudades.
  getListaCiudad(pais:string){
    this.spinner.show();
    this.listasServ.getListasCiudades({"pais":pais}).subscribe(
      res=>{
        this.spinner.hide();
        this.listaCiudades = res.datos.ciudades;
      }, e=>{
        this.spinner.hide();
        this.alertSrv.showAlert(mensaje.error, "Error");
        console.log(e);
      }
    );
  }

    //metodo para mostrar ciudades segun el pais.
  onChangePais(pais){

    if(pais == ""){
      this.listaCiudades = [];

    } else {
      //se llama el servicio de lista solo si la variable es igual a pais.
      this.getListaCiudad(pais);
    }
    if(pais !== 'CO'){
      this.alertSrv.showAlert(mensaje.disponibilidadListasPais, "Error");
      this.disabled = true;
    }
    else{
      this.disabled = false;
    }

  }

dashboard(){
  this.router.navigate(['registered/dashboard']);
}

onLoginSubmit() {

  this.loginSubmitted = true;
  // stop here if form is invalid
  if (this.loginForm.invalid) {
      return;
  }
  const request = {
    "correo": this.loginForm.value.useremail,
    "password": this.loginForm.value.password
  };
  this.spinner.show();
  this.emprendedorServ.inicioSesionEmprendedor(request).subscribe(
    response => {
      console.log(response);
      
      // Eliminamos los datos del localStorage
      localStorage.removeItem('STATE');
      // Eliminamos datos guardados en state
      this.state.init();
      this.state.updateBasico('editando', false);
      if (response.datos !== null){
        this.message();
        // Cargamos los datos basicos del emprendedor
        this.state.updateBasico('idEmprendedor', response.datos.basico.idEmprendedor);
        this.state.updateBasico('emailEmprendedor', response.datos.basico.emailEmprendedor);
        this.state.updateBasico('codPaisEmprendedor', response.datos.basico.codPaisEmprendedor);
        this.state.updateBasico('nombres', response.datos.basico.nombres);
        this.state.updateBasico('apellidos', response.datos.basico.apellidos);
        // Cargamos los datos del emprendedor
        this.state.updateEnterpreneur('datos', response.datos.basico.emprendedor);
        // Verificamos el proceso del registro del emprendedor
        if (response.datos.basico.emprendedor.competenciasList !== null){
          this.state.updateEmprendedor('Competencias de emprendedor');
        }
        if (response.datos.basico.emprendedor.tipoEmprendedorList !== null){
          this.state.updateEmprendedor('Tipo de emprendedor');
        }
        if (response.datos.basico.nombreProyecto !== null){
          this.state.updateBasico('codProyecto', response.datos.basico.codProyecto);
          this.state.updateBasico('nombreProyecto', response.datos.basico.nombreProyecto);
          this.state.updateBasico('codPaisProyecto', response.datos.basico.codPaisProyecto);
          this.state.updateProyecto('Inscripción de proyecto');

          // Cargamos los datos del proyecto
          this.state.updateProject('datos', response.datos.basico.proyecto);
          // Verificamos el proceso del registro del proyecto
          if (response.datos.basico.proyecto.fase !== null && response.datos.basico.proyecto.momento !== null){
            this.state.updateProyecto('Fase y momento de negocio');
          }
          if (response.datos.basico.proyecto.perfilCapacidades.sistemaComercial !== null &&
            response.datos.basico.proyecto.perfilCapacidades.sistemaEmpresarial !== null &&
            response.datos.basico.proyecto.perfilCapacidades.sistemaInnovacion !== null ){
            this.state.updateProyecto('Capacidad del negocio');
          }
          if (response.datos.basico.proyecto.propuestaDeValor !== ''){
            this.state.updateProyecto('Posición competitiva');
          }
        }
        // Cargamos los datos de las tacticas
        this.getEstrategia();
      }else {
        this.spinner.hide();
        this.alertSrv.showAlert(response.error.mensaje, 'Error');
        return;
      }
    },
    e => {
      this.spinner.hide();
      this.alertSrv.showAlert(e.error.mensaje, 'Error');
      return;
    }
  );
}

getEstrategia(){
  const requestTactics = {
    "codigoEmprendedor": this.state.getInfo('basico', 'idEmprendedor'),
    "codigoProyecto": this.state.getInfo('basico', 'codProyecto')
  };
  this.proySrv.getTacticas(requestTactics).subscribe(
    response => {
      if (!(response.datos)){
        console.log('No se han priorizado los sistemas estrategicos');
      }else{
        this.responseTactics = response.datos.sistemasList;
        this.state.updateProject("plan", this.responseTactics);
        this.state.updateEstrategia("Entorno competitivo");
        this.state.updateEstrategia("Prioridades estratégicas");
      }
      this.spinner.hide();
      this.router.navigate(['/registered/dashboard']);
    },
    error => {
      this.spinner.hide();
      this.alertSrv.showAlert(mensaje.error, 'Error');
      console.log(error);
    }
  );
  this.proySrv.consultarTacticas(requestTactics).subscribe(
    response => {
      const sdn = this.state.getInfo('estadoEstrategia');
      if (!(response.datos)) {
        console.log('No se han creado las tacticas para el proyecto');
        for (let i = 1; i < sdn.length; i++) {
          sdn[i].estado = false;
        }console.log(sdn);
      } else {
        const long = response.datos.sistemasList.length;
        for (let i = 0; i < long; i++) {
          if (sdn[i+1].nombre !== response.datos.sistemasList[i].nombre) {
            sdn[i+1].nombre = response.datos.sistemasList[i].nombre;
          }
          if (response.datos.sistemasList[i].clusterList.length > 0) {
            this.state.updateEstrategia(sdn[i+1].nombre);
          } else {
            sdn[i+1].estado = false;
          }
        }console.log(sdn);
        if (response.datos.sistemasList[response.datos.sistemasList.length-1].clusterList.length > 0) {
          this.state.updateProject("resumenTacticas", response.datos.sistemasList);
        }
      }
      this.spinner.hide();
    },
    error => {
      this.spinner.hide();
      this.alertSrv.showAlert(mensaje.error, 'Error');
      console.log(error);
    }
  );
}

validarFecha(){
  var fechaAValidar = this.registerForm.value.age;
  var expreg_mmddyyyy = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;
  var expreg_ddmmyyyy = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/[0-9]{4}$/
  if(!expreg_mmddyyyy.test(fechaAValidar) && !expreg_ddmmyyyy.test(fechaAValidar)){
    this.dateSubmitted = true;
  }else{
    this.dateSubmitted = false;
  }
  this.validarEdadEnLaFecha(this.registerForm.value.age);
}


onRegisterSubmit() {
  this.registerSubmitted = true;
  this.informationSubmitted = true;
  // stop here if form is invalid
  if (this.registerForm.invalid) {
      //--validar fecha
      this.validarFecha();
      return;
  }  
  //--validar fecha
  this.validarFecha();
  this.validarEdadEnLaFecha(this.registerForm.value.age);
  if(this.dateSubmitted || this.dateSubmitted2){
    return;
  }
  //-- 
  if('P6be3b6607ab84ebc861afdd2b3273c5517b582ab' != this.registerForm.value.password){
    this.newEmprendedor.actualizarClave = true;
    //'la clave fue modificada (SI es valida), se irá pa la DB, valor: ' continuar con guardado
  }else{
    //'la clave no se modificó, NO se irá para la DB - ' continuar con guardado
  }
  //---
  this.newEmprendedor.nombres                 = this.registerForm.value.name;
  this.newEmprendedor.apellidos               = this.registerForm.value.surname;
  this.newEmprendedor.fechaNacimiento         = this.registerForm.value.age;
  this.newEmprendedor.codigoGenero            = this.registerForm.value.gender;
  this.newEmprendedor.codigoPais              = this.registerForm.value.country;
  this.newEmprendedor.codigoCiudad            = this.registerForm.value.city;
  this.newEmprendedor.opcionComoConocioStive  = this.registerForm.value.ccas;
  this.newEmprendedor.telefono                = this.registerForm.value.phone;
  this.newEmprendedor.correo                  = this.registerForm.value.useremail;
  this.newEmprendedor.password                = this.registerForm.value.password;
  this.newEmprendedor.codigoEmprendedor       = this.codigoEmprendedor;
  //--
  this.newEmprendedor.generoTexto       = this.getTextPorCodigo(this.listaGeneros,this.registerForm.value.gender);
  this.newEmprendedor.paisTexto         = this.getTextPorCodigo(this.listaPaises,this.registerForm.value.country);
  this.newEmprendedor.ciudadTexto       = this.getTextPorCodigo(this.listaCiudades,this.registerForm.value.city);
  this.newEmprendedor.conocioStiveTexto = this.getTextPorCodigoccas(this.listaStive,this.registerForm.value.ccas);

  this.disabled = true;
  this.spinner.show();
  this.emprendedorServ.actualizarPerfilEmprendedor(this.newEmprendedor).subscribe(
    response =>{ 
        this.disabled = false;
        this.spinner.hide();
        if(response.error && response.error.codigo){
          this.alertSrv.showAlert(response.error.mensaje, "Error");
          return;
        }
        //--
        this.datosEmprendedor                     = this.state.getInfo('emprendedor', 'datos');
        this.datosEmprendedor.nombres             = this.registerForm.value.name;
        this.datosEmprendedor.pais                = this.newEmprendedor.paisTexto;
        this.datosEmprendedor.ciudad              = this.newEmprendedor.ciudadTexto;
        this.datosEmprendedor.comoConocioAstive   = this.newEmprendedor.conocioStiveTexto;
        this.datosEmprendedor.apellidos           = this.registerForm.value.surname;
        //--
        this.stateSrv.updateBasico("nombres",   this.registerForm.value.name);
        this.stateSrv.updateBasico("apellidos", this.registerForm.value.surname);
        //Registro actualizado, redirecciona a dashboard
        this.router.navigate(['/registered/dashboard']);
        
    }, e=>{
      this.disabled = false;
      this.spinner.hide();
      this.alertSrv.showAlert(mensaje.error, "Error");
      console.log(e);
    }
  );

}

getTextPorCodigo(listax, codigox){
  for(let i = 0; i < listax.length; i++){
    if(listax[i].codigo == codigox){
      return listax[i].nombre;
    }
  }
}

getTextPorCodigoccas(listax, opcionx){
  for(let i = 0; i < listax.length; i++){
    if(listax[i].opcion == opcionx){
      return listax[i].descripcion;
    }
  }
}


viewPassword(){
  if (this.vpc === true){
    this.vp = 'password';
    this.vpc = false;
  }
  else {
    this.vp = 'text';
    this.vpc = true;
  }
}

getVideo(){
  this.listSrv.getVideos().subscribe(
    res=>{
      res.datos.videosList.filter(v => {
        if(v.clave === "DashboardEmpresarial"){
          this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(v.url);
          this.videoReady = true;
        }
      });
    },
    error=>{
      this.alertSrv.showAlert(mensaje.error, "Error");
    }
  );
}

recoverPwd() {
  this.dialog.open(ForgotPwdComponent, {
    panelClass: 'popup-ovlide-contrasenia',
    data: {
      title: 'Olvidé mi contraseña'
    }
  });
}

message() {
  this.dialog.open(MensajeComponent, {
    panelClass: 'popup-mensaje-importante',
    data: {
      title: 'Mensaje importante'
    }
  });
}

  formatearFechaNacimiento(fecha){
    let fechaFormateada = "";
    fechaFormateada = fecha.substring(0,4) + "-" + fecha.substring(5,7) + "-" + fecha.substring(8,10);
    return fechaFormateada;
  }

  formatearFechaNacimientoDate(fecha){
    let fechaFormateada = "";
    let fechaFormateadaDate = new Date(fecha.substring(0,4),parseInt(fecha.substring(5,7))-1,fecha.substring(8,10));
    fechaFormateada = fecha.substring(0,4) + "-" + fecha.substring(5,7) + "-" + fecha.substring(8,10);
    return fechaFormateadaDate;
  }

  submitFormLite(){
    this.buyerFullNameLite.nativeElement.value = this.newEmprendedor.nombres + " " + this.newEmprendedor.apellidos;
    this.buyerEmailLite.nativeElement.value    = this.newEmprendedor.correo;
    this.formLite.nativeElement.submit();
    this.spinner.show();
  }

  submitFormPro(){
    this.buyerFullNamePro.nativeElement.value = this.newEmprendedor.nombres + " " + this.newEmprendedor.apellidos;
    this.buyerEmailPro.nativeElement.value    = this.newEmprendedor.correo;
    this.formPro.nativeElement.submit();
    this.spinner.show();
  }

  abrirVideoDeApoyo() {
    this.videoDeApoyo.showAlert("Registro");
  }

}