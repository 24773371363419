import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-init-page-header',
  templateUrl: './init-page-header.component.html',
  styleUrls: ['./init-page-header.component.css']
})
export class InitPageHeaderComponent implements OnInit {

  constructor(public router:Router) { }

  ngOnInit(): void {

  }

  irAIniciarS(){
    this.router.navigate(['/login']);
  }

  irARegister(){
    this.router.navigate(['/register-payu']);
  }

  irAComoFunciona(){
    this.router.navigate(['/como-funciona']);
  }

  irAContacto(){
   
  }


  irAExperiencia(){
    
  }

  irAAlianzas(){
    
  }


  irAAcademiia(){
   
  }

  irAInitP(){
    this.router.navigate(['/init-page']);
  }


}
