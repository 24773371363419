
// Constante para los errores mas recurrentes
export const mensaje = {
    error: 'Ocurrio un error inesperado',
    exito: 'Su registro ha sido exitoso',
    errorNumericoProyectoEstrategia: 'Solo se aceptan valores de 1 a 3',
    disponibilidadListasPais: 'No se encuentran disponible las listas para otro país diferente a Colombia',
    seleccionPropuestaValor: 'Selecciona una propuesta de valor para continuar',
    seleccionEstretegia: 'Selecciona una estrategia para continuar',
    seleccionFechaSeguimiento: 'La fecha inicial no puede ser igual o mayor a la fecha de vencimiento',
    seleccionFechaVencimiento: 'La fecha de vencimiento no puede ser menor a la fecha actual',
    msjAdvertenciaEditar: 'Si editas la información registrada deberás volver a generar tu estrategia de negocio.'
};

export const titulo = {
    msjTituloEditar: ''
}
