import { Component, OnInit } from '@angular/core';
import { ListasService } from '@services/index';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService } from '@app/_modal/alert/alert.service';
import { mensaje } from '@app/_const/alert_error_message';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-info-instituciones',
  templateUrl: './info-instituciones.component.html',
  styleUrls: ['./info-instituciones.component.css']
})
export class InfoInstitucionesComponent implements OnInit {

  videoURL:any;
  videoReady:boolean = false;

  constructor(
    private alertSrv: AlertService,
    public listSrv: ListasService,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,) {  

  }

  ngOnInit(): void {
    this.getVideo();
  }

  getVideo(){
    this.spinner.show();
    this.listSrv.getVideos().subscribe(
      res=>{
        res.datos.videosList.filter(v => {
          if(v.clave === "DashboardEmpresarial"){
            this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(v.url);
            this.videoReady = true;
          }
          this.spinner.hide();
        });
      },
      error=>{
        this.spinner.hide();
        this.alertSrv.showAlert(mensaje.error, "Error");
      }
    );
  }

}
