<div *ngIf="videoReady; else elseBlock" style="background-color: white;">
    <!-- ENCABEZADO - FOTO BACK -->
    <div class="img-response" >
      <div class="gfg">
        <img src="../../../assets/img/iconos-ver-1/como-funciona-bg4.jpg" class="img-responsive"/>
        <p class="texto-portada txt-tres color-negro">Alcanza todo lo que te propongas con un aliado de negocios dedicado a ti.</p>
        <p class="texto-portada-name txt-uno color-negro">Junto a Stive puedes diseñar tu modelo de negocio, desarrollar estratégias competitivas para
          tu negocio y mejorar tu eficiencia, crecer tu negocio y aumentar su valor. #Soystiver
          </p>
      </div>
    </div>
    <!--  SECCIÖN INFORMATIVA TEXTO - VIDEO INTRODUCCION -->
    <div class="row padding-row">
      <div class="column column-1">
        <div style="text-align: center;">
          <img src="../../../assets/img/iconos-ver-1/Stive_como1.png" style="width: 100px;">
        </div>
        <p class="texto-intro"> 
          Stive es un asesor inteligente que usa inteligencia de negocios
          para calcular billones de estrategias de negocio en minutos. Se
          actualiza constantemente con información del mercado y de
          expertos para dar información actualizada a los emprendedores
          de su entorno competitivo y permitir a cualquier negocio realizar
          las mejores estrategias y a crecer.
        </p>
      </div>
      <div class="column column-3">
        <iframe class="responsive-iframe size-video1" [src]="videoURL" frameborder="4"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
        </iframe>
      </div>
    </div><br>
    <!-- SECCION: ALIADO ESTRATEGICO-->
    <div class="size-titulos"><p class="texto-alidado-1">
      <strong>Stive, el aliado estratégico que se adapta a ti y a tu negocio</strong>
    </p></div><br>
    <div class="row pos-aliado">
      <div class="column-aliado">
        <div style="text-align: center;">
          <img src="../../../assets/img/iconos-ver-1/Stive_como2.png" style="width: 100px;">
        </div>
        <p class="texto-intro"> Diseña estrategias en tiempo real para lograr tus objetivos de negocio</p>
      </div>
      <div class="column-aliado">
        <div style="text-align: center;">
          <img src="../../../assets/img/iconos-ver-1/Stive_como3.png" style="width: 100px;">
        </div>
        <p class="texto-intro"> Conoce las fuerzas del entorno competitivo que afectan a tu empresa.
          </p>
      </div>
      <div class="column-aliado">
        <div style="text-align: center;">
          <img src="../../../assets/img/iconos-ver-1/Stive_como4.png" style="width: 100px;">
        </div>
        <p class="texto-intro"> Obtén informes de emprendedor, del negocio y tu plan estratégico</p>
      </div>
      <div class="column-aliado">
        <div style="text-align: center;">
          <img src="../../../assets/img/iconos-ver-1/Stive_como5.png" style="width: 100px;">
        </div>
        <p class="texto-intro">Haz seguimiento a tu estrategia para hacer crecer tu negocio</p>
      </div>
    </div>
    <!-- HERRAMIENTAS -->
    <div class="size-titulos"><p class="texto-alidado-1">
      <strong>Entregando las herramientas de negocio que necesitas</strong>
    </p></div><br>
      <!--PARTE 1-->
    <div class="row padding-row">
      <div class="column column-1">
        <p class="texto-intro"> 
          Entiende tu negocio en todas sus dimensiones y encuentra nuevas y mejores oportunidades de crecimiento, con el plan de negocios que construyes
        </p><br>
        <p class="texto-intro"> 1. Toma mejores decisiones estratégicas</p><br>
        <p class="texto-intro"> 2. Encuentra recursos financieros y operacionales</p><br>
        <p class="texto-intro"> 3. Ejecuta y controla tu negocio como los mejores</p><br>
      </div>
      <div class="column column-3">
        <div style="display: flex;">
          <div>
            <img src="../../../assets/img/iconos-ver-1/Stive_como6.png" class="padding-tool size-tool"><p class="texto-tool">Perfil emprendedor</p>
          </div>
          <div>
            <img src="../../../assets/img/iconos-ver-1/Stive_como7.png" class="padding-tool size-tool"><p class="texto-tool">Clasificación de proyecto</p>
          </div>
          <div>
            <img src="../../../assets/img/iconos-ver-1/Stive_como8.png" class="padding-tool size-tool"><p class="texto-tool">Creación de estrategia</p>
          </div>
          <div>
            <img src="../../../assets/img/iconos-ver-1/Stive_como9.png" class="padding-tool size-tool"><p class="texto-tool">Seguimiento del negocio</p>
          </div>
        </div><br>
        <div style="display: flex;text-align: center;">
          <div class="pad-div"><img src="../../../assets/img/iconos-ver-1/Stive_como10.png" class="size-tool2"><p class="texto-tool">Sistema Empresarial</p></div>
          <div class="pad-div"><img src="../../../assets/img/iconos-ver-1/Stive_como11.png" class="size-tool2"><p class="texto-tool">Sistema Innovación</p></div>
          <div class="pad-div"><img src="../../../assets/img/iconos-ver-1/Stive_como12.png" class="size-tool2"><p class="texto-tool">Sistema Comercial</p></div>
        </div>
      </div>
    </div><br>
    <!--PARTE 2-->
    <div class="row padding-row2">
      <div class="column column-tool" style="background-color: #6abfd4;">
        <p class="texto-2"> Acelera el crecimiento de tu negocio. <br>Tú decides como gestionar tu proyecto…</p>
        <p class="texto-2">No hay límites para ti<br>No hay límites para tus objetivos<br>No hay límites para tus sueños.</p>
      </div>
      <div class="column column-3" style="padding-top: 45px;">
        <div style="text-align: center;"><img src="../../../assets/img/iconos-ver-1/Stive_como13.jpg" class="size-img2"></div>
      </div>
    </div><br>
    <!--INGRESA-->
    <div class="size-titulos"><p class="texto-alidado-1">
      <strong>Ingresa a Stive ahora</strong>
    </p></div><br>
    <div class="row padding-row">
      <div class="column">
        <div>
          <img src="../../../assets/img/iconos-ver-1/Stive_como14.png" class="size-img4">
        </div>
      </div>
      <div class="column columna-4">
        <p class="texto-intro"> <br>
          Regístrate en Stive ahora y conoce tus competencias como emprendedor, las capacidades de tu negocio, selecciona tu propuesta de valor y crea la mejor estrategia para alcanzar tus objetivos.
        </p>
        <br>
        <div class="texto-boton-tipou-1" style="color: #7E7C7C;">
          ¿Quieres usar Stive en tu negocio?&nbsp;&nbsp; 
          <button (click)="irAAdquiereTuSub();" type="submit" class="subboton-boton-tipou-1">Subscríbete</button>
        </div>
        <br>
        <div class="texto-boton-tipou-1" style="color: #7E7C7C;">
          ¿Ya tienes una cuenta Stive?&nbsp;&nbsp;
          <button  (click)="irAIniciarS();" type="submit" class="subboton-boton-tipou-1" style="margin-left: 35px;">Regístrate</button>
        </div>
        <br><br>
        <p class="texto-intro" style="font-style: italic;font-weight: 150;"> Navegadores recomendados: Google Chrome,Microsoft Edge, Opera, Mozilla Firefox, Safari</p>
  
  
      </div>
    </div>
  </div>
  <ng-template #elseBlock><mat-spinner style="margin-left: 20%;"></mat-spinner></ng-template>
  