<app-header-layout></app-header-layout>
<div class="row">
    <div class="container">
        <section class="estrategia">
            <article class="row">
                <h4>Perfil emprendedor</h4>
                <!--SEMI - stepper-->
                <app-footer-layout style="width: 100%;"></app-footer-layout>
                <p>En Stive clasificamos a los emprendedores en 6 tipos de personalidad para definir sus cualidades y características a la hora de trabajar y enfrentar retos en el negocio. Califica de 0 a 10 tu nivel de afinidad con respecto a las siguientes
                    afirmaciones.</p>
            </article>
            <article class="row inf">
                <div class="col-md-6 col-lg-4 escalaimg">
                    <img src="../../../../assets/img/escala.png" width="310" height="70">
                </div>
                <div class="col-md-6 col-lg-4 escaladesc">
                    <p style="margin-bottom: 5px;text-align: justify">0 = Poco de acuerdo</p>
                    <p style="margin-bottom: 5px;text-align: justify">5 = Medianamente de acuerdo</p>
                    <p style="text-align: justify">10 = Muy de acuerdo</p>
                </div>
                <div class="col-md-12 col-lg-4 text-center is">
                    <button type="submit" class="btn btn-primary" (click)="dashboard()">
                        <mat-icon matSuffix id="iconoVideo">keyboard_backspace</mat-icon>
                        Volver al dashboard
                    </button>
                    <button type="submit" class="btn btn-primary fondo-boton-video" (click)="openVideoIntroductorio()">
                        <mat-icon matSuffix id="iconoVideo">video_library</mat-icon>
                        Video de apoyo
                    </button>
                </div>
            </article>
            <form [formGroup]="createForm" (ngSubmit)="onCreateFormSubmitted()">
                <article class="container">
                    <div class="row ">
                        <div class="col-lg-4 col-md-6" *ngFor="let p of preguntasFull; let i=index">
                            <p>{{p.pregunta.pregunta}}</p>
                            <div class="container-slider">
                                <mat-slider thumbLabel value="{{p.valor}}" min="0" max="10" #matslider (input)="onInputChange($event,p)"></mat-slider>
                                <span style="font-size: 8;color:#6ABFD4;">{{p.valor}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="offset-md-3 col-md-6 mb-3 text-center">
                            <button type="submit" id="btn-tipo-emprendedor" [disabled]="disabled" class="btn btn-primary">Guardar</button>
                        </div>
                    </div>
                </article>
            </form>
        </section>
    </div>
</div>
<ngx-spinner></ngx-spinner>