<app-header-layout></app-header-layout>
<div class="row">
    <div class="container">
        <section class="estrategia">
            <article class="row">
                <h4>Perfil Proyecto</h4>
                <!--SEMI - stepper-->
                <app-footer-layout style="width: 100%;"></app-footer-layout>
                <article class="row pc">
                    <div class="col-md-8 text-center">
                        <p>La fase de desarrollo y el momento de negocio muestran el lugar en el que se encuentra el negocio en el camino del emprendimiento. De transformar una idea de negocio a un negocio maduro y sostenible. Responde las preguntas que se te harán a continuación.</p>
                    </div>
                    <div class="col-md-4 text-center is">
                        <button type="submit" class="btn btn-primary" (click)="dashboard()">
                            <mat-icon matSuffix id="iconoVideo">keyboard_backspace</mat-icon>
                            Volver al dashboard
                        </button><br><br>
                        <button type="submit" class="margen-boton-2 btn btn-primary va fondo-boton-video" (click)="openVideoIntroductorio()">
                            <mat-icon matSuffix id="iconoVideo">video_library</mat-icon>
                            Video de apoyo
                        </button>
                    </div>
                  </article>

                <h5>Editar Momento del negocio ({{this.momentoDBStr}})</h5>
                <p class="text-center">Selecciona la opción que mejor se ajuste al momento actual de tu emprendimiento</p>
            </article>
            <form class="text-center" id="mForm" [formGroup]="momentoForm" (ngSubmit)="onMomentoSubmit()">
                <article class="row">
                    <div class="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                        <div class="input-group">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-select class="text-center" id="inputGroupSelect01" formControlName="momento" 
                                [ngClass]="{ 'is-invalid': momentoSubmitted && r.momento.errors}">
                                    <mat-option value="">Momento del negocio</mat-option>
                                    <mat-option *ngFor="let m of listaMomentos" [value]="m.codigo">{{m.momento}}</mat-option>
                                </mat-select>
                                <mat-icon matSuffix>insights</mat-icon>
                            </mat-form-field>
                            <span *ngIf="momentoSubmitted && r.momento.errors" class="error">
                                <span *ngIf="r.momento.errors.required">Momento del negocio es requerido</span>
                            </span>
                        </div>
                    </div>
                </article>
            </form>
            <article class="row mt-5 fdesarrollo d-flex justify-content-center">
                <h5>Editar Fase de desarrollo ({{this.faseDB}})</h5>

                <p class="text-center" *ngIf="pregunta.codigoNo">Responde “si” o “no” a las preguntas que se te harán a continuación. Oprime reiniciar si cometiste un error al responder alguna de las preguntas.
                    <br><span class="error">{{restriccion}}</span>
                </p>
                <p class="text-center">
                    <strong>{{pregunta.texto}}</strong><br>
                    {{pregunta.descripcion}}
                </p>
                <button type="submit" (click)="answer(pregunta.codigoNo)" class="btn btn-primary" 
                    *ngIf="pregunta.codigoNo">No</button>
                <button type="submit" (click)="answer(pregunta.codigoSi)" class="btn btn-primary"
                    *ngIf="pregunta.codigoSi">Si</button>
            </article>
            <article class="row botonesfinales d-flex justify-content-center">
                <button type="submit" (click)="reset()" class="btn btn-secundary">Reiniciar</button>
                <button type="submit" form="mForm" class="estilo-btn-continuar" id="btn-fase-dello" [class]="boton" [disabled]="disabled">Continuar</button>
            </article>
        </section>
    </div>
</div>
<ngx-spinner></ngx-spinner>
