import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component'
import { DashboardComponent } from './innpulsa/dashboard/dashboard.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { EmprendoderesComponent } from './reportes/emprendoderes/emprendoderes.component';
import { DashboardMinculturaComponent } from './reportes/mincultura/dashboard-mincultura/dashboard-mincultura.component';
import { EmprendedoresMinculturaComponent } from './reportes/mincultura/emprendedores-mincultura/emprendedores-mincultura.component';
import { CirculovidaEdicionComponent } from './registered/dashboard/editar/circulovida-edicion/circulovida-edicion.component';
import { CompetenciasEdicionComponent } from './registered/dashboard/editar/competencias-edicion/competencias-edicion.component';
import { TipoemprendedorEdicionComponent } from './registered/dashboard/editar/tipoemprendedor-edicion/tipoemprendedor-edicion.component';
import { ProyectoEdicionComponent } from './registered/dashboard/editar/proyecto-edicion/proyecto-edicion.component';
import { FaseMomentoEdicionComponent } from './registered/dashboard/editar/fase-momento-edicion/fase-momento-edicion.component';
import { CapacidadesEdicionComponent } from './registered/dashboard/editar/capacidades-edicion/capacidades-edicion.component';
import { PropuestaEdicionComponent } from './registered/dashboard/editar/propuesta-edicion/propuesta-edicion.component';
import { EntornocEdicionComponent } from './registered/dashboard/editar/entornoc-edicion/entornoc-edicion.component';
import { EstrategiasEdicionComponent } from './registered/dashboard/editar/estrategias-edicion/estrategias-edicion.component';
import { RegistroEdicionComponent } from './register/registro-edicion/registro-edicion.component';
import { RegisterPayuComponent } from './registerpayu/register-payu/register-payu.component';
import { GestionarPlanComponent } from './comprar-subs/gestionar-plan/gestionar-plan.component';
import { InitPageMainComponent } from './init-page/init-page-main/init-page-main.component';
import { FuncionamientoComponent } from './como-funciona/funcionamiento/funcionamiento.component';



const routes: Routes = [
  { path: ``, redirectTo: 'init-page', pathMatch: 'full' },
  { path: `login`, component: LoginComponent },

  { path: `register`, component: RegisterComponent },
  { path: 'register-payu', component: RegisterPayuComponent },
  { path: 'gestionar-plan', component: GestionarPlanComponent },
  { path: 'innpulsa/dashboard', component: DashboardComponent },
  { path: 'innpulsa/emprendedores', component: EmprendoderesComponent },
  { path: 'mincultura/dashboard', component: DashboardMinculturaComponent },
  { path: 'mincultura/emprendedores', component: EmprendedoresMinculturaComponent },
  { path: 'editar/circulo-vida', component: CirculovidaEdicionComponent },
  { path: 'editar/competencias', component: CompetenciasEdicionComponent },
  { path: 'editar/tipo-emprendedor', component: TipoemprendedorEdicionComponent },
  { path: 'editar/proyecto', component: ProyectoEdicionComponent },
  { path: 'editar/fase-momento', component: FaseMomentoEdicionComponent },
  { path: 'editar/capacidades', component: CapacidadesEdicionComponent },
  { path: 'editar/propuesta', component: PropuestaEdicionComponent },
  { path: 'editar/entornoc', component: EntornocEdicionComponent },
  { path: 'editar/estrategias', component: EstrategiasEdicionComponent },
  { path: 'editar/registro', component: RegistroEdicionComponent },
  { path: 'init-page', component: InitPageMainComponent },
  { path: 'como-funciona', component: FuncionamientoComponent },
  {
    path: `registered`, 
    loadChildren: () => import('./registered/registered.module').then(m => m.RegisteredModule)
  },
  { path: `**`, component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
