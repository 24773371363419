import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-video-introductorio',
  templateUrl: './video-introductorio.component.html',
  styleUrls: ['./video-introductorio.component.css']
})
export class VideoIntroductorioComponent implements OnInit {

  videoURL: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data:any,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
  }

}
