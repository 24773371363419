import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-init-page-main',
  templateUrl: './init-page-main.component.html',
  styleUrls: ['./init-page-main.component.css']
})
export class InitPageMainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
