<div class="row h-100 pregistro">
    <div class="container h-100">
            <div class="row">
                <section class="estrategia w-100">
                    <article class="row innovacion">
                        <div class="col-md-12 p-0"><br>
                            <article class="row">
                                <!-- UNICO IZQ-->
                                <div class="col-md-12 col-lg-4">
                                    <div class="cardpuntaje" style="text-align: center;">
                                        <img src="../../../../assets/img/iconos-ver-1/Stive-animado.gif" class="animado-pos">
                                    </div>
                                </div>
                                <!-- GRID DERECHA -->
                                <div class="col-md-12 col-lg-8">
                                        <!-- 2 PREGUNTAS -->
                                    <!-- 1.1 -->
                                    <button type="button" class="btnmodal sistemas boton-principal boton-tipou-1" style="cursor:default!important;">
                                        <div class="texto-boton-tipou-1" style="color: #7E7C7C;">
                                            ¿Quieres usar Stive en tu negocio?&nbsp;&nbsp; 
                                            <button (click)="irAAdquiereTuSub();" type="submit" class="subboton-boton-tipou-1">Subscríbete</button></div>
                                    </button>
                                     <!-- 1.2 -->
                                     <button type="button" class="btnmodal sistemas boton-principal boton-tipou-1" style="cursor:default!important;">
                                        <div class="texto-boton-tipou-1" style="color: #7E7C7C;">
                                            ¿Ya tienes una cuenta Stive?&nbsp;&nbsp;
                                            <button  (click)="irAIniciarS();" type="submit" class="subboton-boton-tipou-3">Inicia Sesión</button></div>
                                    </button>
                                        <!-- 3 PANELES -->
                                    <!-- 2.1 -->
                                    <button title="Conoce más" type="button" (click)="clicEnEmprendedores();" class="btnmodal sistemas boton-principal boton-tipou-2 btn-width-1">
                                        <img style="width: 70px;vertical-align: super;" src="../../../../assets/img/iconos-ver-1/Stive 2.png"><br>
                                        <span class="titulo-boton-tipou-2" style="font-size: 24px;"> <b>Emprendedores</b></span>
                                        <span class="texto-boton-tipou-2" style="color: #7E7C7C;"><br>Si tienes una idea de negocio y quieres mi ayuda en tu plan estratégico.</span>
                                        <br><br><button type="submit" (click)="irAAdquiereTuSub();" class="subboton-boton-tipou-2">Subscríbete</button>
                                    </button>
                                    <!-- 2.2 -->
                                    <button title="Conoce más" type="button" (click)="clicEnInstitucioness();" class="btnmodal sistemas boton-principal boton-tipou-2 btn-width-2">
                                        <img style="width: 70px;vertical-align: super;"src="../../../../assets/img/iconos-ver-1/Stive 3.png"><br>
                                        <span class="titulo-boton-tipou-2" style="font-size: 24px;"> <b>Instituciones</b></span>
                                        <span class="texto-boton-tipou-2" style="color: #7E7C7C;"><br>Si eres un usuario invitado por una institución alidada, regístrate aquí.&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; </span>
                                        <br><br><button type="submit" (click)="irARegister();" class="subboton-boton-tipou-4">Regístrate</button>
                                    </button>
                                    <!-- 2.3 -->
                                    <button title="Conoce más" type="button" (click)="clicEnGobierno();" class="btnmodal sistemas boton-principal boton-tipou-2 btn-width-3">
                                        <img style="width: 65px;vertical-align: super;" src="../../../../assets/img/iconos-ver-1/Stive 4.png"><br>
                                        <span class="titulo-boton-tipou-2" style="font-size: 24px;"> <b>Gobierno</b></span>
                                        <span class="texto-boton-tipou-2" style="color: #7E7C7C;"><br>Si eres un usuario invitado por una institución gubernamental, regístrate aquí.</span>
                                        <br><br><button type="submit" (click)="irARegister();" class="subboton-boton-tipou-4">Regístrate</button>
                                    </button>
                                </div>
                            </article>
                        </div>
                    </article><br>
                    <!--   SECCIÖN CONOCE MAS POR TIPO DE USUARIO  -->
                    <app-info-emprendedores *ngIf="mostrarInfoEmprendedores"></app-info-emprendedores>
                    <app-info-instituciones *ngIf="mostrarInfoInstituciones"></app-info-instituciones>
                    <app-info-gobierno *ngIf="mostrarInfoGobierno"></app-info-gobierno>
                </section>
            </div>
    </div>
</div>