<div class="contenedor-detalles">
    <div class="detalle">
        <div class="modal-body">
            <div class="container-iframe">
                <iframe class="responsive-iframe" [src]="videoURL" frameborder="4" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                </iframe>
            </div>
            <div class="contenido"> 
                <p>{{data.descripcion}}</p>
            </div>
        </div>
    </div>
</div>