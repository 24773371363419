import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RegisterComponent } from './register/register.component';
import { CommonsModule } from './commons.module';
import { TermsComponent } from './register/popup/terms/terms.component';
import { DashboardComponent } from './innpulsa/dashboard/dashboard.component';
import { ForgotPwdComponent } from './login/popup/forgot-pwd/forgot-pwd.component';
import { MensajeComponent } from './login/popup/mensaje/mensaje.component';
import { EmprendoderesComponent } from './reportes/emprendoderes/emprendoderes.component';
import { DashboardMinculturaComponent } from './reportes/mincultura/dashboard-mincultura/dashboard-mincultura.component';
import { EmprendedoresMinculturaComponent } from './reportes/mincultura/emprendedores-mincultura/emprendedores-mincultura.component';
import { CirculovidaEdicionComponent } from './registered/dashboard/editar/circulovida-edicion/circulovida-edicion.component';
import { CompetenciasEdicionComponent } from './registered/dashboard/editar/competencias-edicion/competencias-edicion.component';
import { TipoemprendedorEdicionComponent } from './registered/dashboard/editar/tipoemprendedor-edicion/tipoemprendedor-edicion.component';
import { ProyectoEdicionComponent } from './registered/dashboard/editar/proyecto-edicion/proyecto-edicion.component';
import { FaseMomentoEdicionComponent } from './registered/dashboard/editar/fase-momento-edicion/fase-momento-edicion.component';
import { CapacidadesEdicionComponent } from './registered/dashboard/editar/capacidades-edicion/capacidades-edicion.component';
import { PropuestaEdicionComponent } from './registered/dashboard/editar/propuesta-edicion/propuesta-edicion.component';
import { EntornocEdicionComponent } from './registered/dashboard/editar/entornoc-edicion/entornoc-edicion.component';
import { EstrategiasEdicionComponent } from './registered/dashboard/editar/estrategias-edicion/estrategias-edicion.component';
import { RegistroEdicionComponent } from './register/registro-edicion/registro-edicion.component';
import { PlanesSubsComponent } from './banner-subs/planes-subs/planes-subs.component';
import { LoginPlanesSubsComponent } from './login/popup/subs/login-planes-subs/login-planes-subs.component';
import { RegisterPayuComponent } from './registerpayu/register-payu/register-payu.component';
import { GestionarPlanComponent } from './comprar-subs/gestionar-plan/gestionar-plan.component';
import { InitPageMainComponent } from './init-page/init-page-main/init-page-main.component';
import { InitPageHeaderComponent } from './init-page/init-page-header/init-page-header.component';
import { InitPageCenterComponent } from './init-page/init-page-center/init-page-center.component';
import { InfoEmprendedoresComponent } from './init-page/info-emprendedores/info-emprendedores.component';
import { InfoInstitucionesComponent } from './init-page/info-instituciones/info-instituciones.component';
import { InfoGobiernoComponent } from './init-page/info-gobierno/info-gobierno.component';
import { FuncionamientoComponent } from './como-funciona/funcionamiento/funcionamiento.component';
import { FuncionamientoArribaComponent } from './como-funciona/funcionamiento-arriba/funcionamiento-arriba.component';
import { FuncionamientoAbajoComponent } from './como-funciona/funcionamiento-abajo/funcionamiento-abajo.component';
import { InitPageFooterComponent } from './init-page/init-page-footer/init-page-footer/init-page-footer.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotFoundComponent,
    RegisterComponent,
    TermsComponent,
    DashboardComponent,
    ForgotPwdComponent,
    MensajeComponent,
    EmprendoderesComponent,
    DashboardMinculturaComponent,
    EmprendedoresMinculturaComponent,
    CirculovidaEdicionComponent,
    CompetenciasEdicionComponent,
    TipoemprendedorEdicionComponent,
    ProyectoEdicionComponent,
    FaseMomentoEdicionComponent,
    CapacidadesEdicionComponent,
    PropuestaEdicionComponent,
    EntornocEdicionComponent,
    EstrategiasEdicionComponent,
    RegistroEdicionComponent,
    PlanesSubsComponent,
    LoginPlanesSubsComponent,
    RegisterPayuComponent,
    GestionarPlanComponent,
    InitPageMainComponent,
    InitPageHeaderComponent,
    InitPageCenterComponent,
    InfoEmprendedoresComponent,
    InfoInstitucionesComponent,
    InfoGobiernoComponent,
    FuncionamientoComponent,
    FuncionamientoArribaComponent,
    FuncionamientoAbajoComponent,
    InitPageFooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CommonsModule,
    MaterialModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
