import { Component, OnInit } from '@angular/core';
import { ProyectoService } from '@services/index';
import { Router } from '@angular/router';
import { AlertService } from '@app/_modal/alert/alert.service';
import { NgxSpinnerService } from "ngx-spinner";
import { mensaje} from '@app/_const/alert_error_message';
import { StateService } from '@app/_helpers/state.service';
import { VideoService } from '@app/_modal/video-introductorio/video-introductorio.service';

const CUATRO = '4';
const CERO = 0;
const COMPLETO = 'completado';
@Component({
  selector: 'app-propuesta-edicion',
  templateUrl: './propuesta-edicion.component.html',
  styleUrls: ['./propuesta-edicion.component.css']
})
export class PropuestaEdicionComponent implements OnInit {

  constructor(
    public proySrv: ProyectoService,
    public alertSrv: AlertService,
    private spinner: NgxSpinnerService,
    private state: StateService,
    private router: Router,
    private videoIntroductorio: VideoService
  ) { }

  propuestaValor:any[] = [];
  propuestaValorSelected: any = {};
  propuestaConMayorPromedio: any = {};

  codEmprendedor:any = null;
  codProyecto:any = null;
  oceanos: any;
  proyectoList: any[]=[];
  disabled: boolean = false;
  propuestaDB: string = "";

  ngOnInit(): void {
    this.state.updateBasico('footer','proyecto');
    //nos movemos a la parte superior de la pagina
    window.scrollTo(0, 0);
    this.codProyecto = this.state.getInfo("basico", "codProyecto");
    this.codEmprendedor = this.state.getInfo("basico", "idEmprendedor");
    this.consultarPropuestaDeValorEnDB();
  }

  getPropuestaValor(){
    this.spinner.show();
    let request = this.getDatosProyecto();
    this.proySrv.getPropuestaValor(request).subscribe(
      resp=>{
        this.spinner.hide();
        resp.datos.propuestaValorList.sort(function(a, b) {
          if (a.orden < b.orden) return -1;
          if (a.orden > b.orden) return 1;
          return 0;
        });
        this.propuestaValor = resp.datos.propuestaValorList;
        this.oceanos = resp.datos;
        //--consultar la propuestas seleccionada de DB
        for(let i=0; i<this.propuestaValor.length; i++){
          if(this.propuestaValor[i].nombre == this.propuestaDB){
            this.propuestaValorSelected = this.propuestaValor[i];
          }
        }
        //consultar propuesta con mayor promedio
        this.calcularMayorPromedio();
      },
      error=>{
        this.spinner.hide();
        this.alertSrv.showAlert(mensaje.error, "Error");
      }
    );
  }

  getDatosProyecto(){
    return {
      "codigoEmprendedor": this.codEmprendedor,
      "codigoProyecto": this.codProyecto
     };
  }

  verDetalle(index: number){
    this.propuestaValorSelected = this.propuestaValor[index];
  }

  seleccionar(){
    if(!this.propuestaValorSelected.nombre){
      this.alertSrv.showAlert(mensaje.seleccionPropuestaValor);
      return;
    }
    this.disabled = true;
    this.spinner.show();
    const request = {
      "codigoEmprendedor": this.codEmprendedor,
      "codigoProyecto": this.codProyecto,
      "oceano": {
        "codigo":  this.propuestaValorSelected.codigo,
        "promedio":  this.propuestaValorSelected.promedio
      }
    };
    this.proySrv.setActualizar(request).subscribe(
      response=>{
        this.disabled = false;
        this.spinner.hide();

         //Cambiar estado
          this.state.updateProyecto("Posición competitiva");
          let estadoClasificacionDelProyecto = this.state.getInfo("basico", "estadoClasificacionDelProyecto");
          estadoClasificacionDelProyecto.filter(item => item.posicion === CUATRO)[CERO].estado = COMPLETO;
          this.state.updateBasico('estadoClasificacionDelProyecto',  estadoClasificacionDelProyecto);
          this.router.navigate(['registered/dashboard']);
          this.consultarProyecto();
      },
      error=>{
        this.disabled = false;
        this.spinner.hide();
        this.alertSrv.showAlert(error.error.mensaje,"Error");
      }
    );
  }

  //abrimos el pop-up de video introductorio
  openVideoIntroductorio(){
    this.videoIntroductorio.showAlert("Oceano");
  }

  consultarProyecto(){
    let idUser = this.state.getInfo("basico", "idEmprendedor");
    this.proySrv.consultProyecto(idUser).subscribe(
      response =>{
        this.propuestaDB = response.datos.proyectos[0].propuestaDeValor;
        this.spinner.hide();
        if(response.error && response.error.codigo){
          this.alertSrv.showAlert(response.error.mensaje, "Error");
          return;
        }
        //this.proyectoList.push(response.datos.proyectos[0]);
        this.state.updateProject("datos", response.datos.proyectos[0]);
      },
      e=>{
        this.spinner.hide();
        this.alertSrv.showAlert(mensaje.error, "Error");
        console.log(e);
      }
    )
  }

  dashboard(){
    this.router.navigate(['registered/dashboard']);
  }

  consultarPropuestaDeValorEnDB(){
    let idUser = this.state.getInfo("basico", "idEmprendedor");
    this.proySrv.consultProyecto(idUser).subscribe(
      response =>{
        this.spinner.hide();
        if(response.error && response.error.codigo){
          this.alertSrv.showAlert(response.error.mensaje, "Error");
          return;
        }else{
          this.propuestaDB = response.datos.proyectos[0].propuestaDeValor;
          this.getPropuestaValor();
        }
      },
      e=>{
        this.spinner.hide();
        this.alertSrv.showAlert(mensaje.error, "Error");
        console.log(e);
      }
    )
  }

  irAProyecto(){
    this.router.navigate(['editar/proyecto']);
  }

  irAFase(){
    this.router.navigate(['editar/fase-momento']);
  }

  irACapacidades(){
    this.router.navigate(['editar/capacidades']);
  }

  irAOceano(){
    this.router.navigate(['editar/propuesta']);
  }

  calcularMayorPromedio(){
    this.propuestaConMayorPromedio = this.propuestaValor[0];
    for(let i=0; i<this.propuestaValor.length; i++){
      if(this.propuestaValor[i].promedio  > this.propuestaConMayorPromedio.promedio){
        this.propuestaConMayorPromedio = this.propuestaValor[i];
      }
    }
  }



}
