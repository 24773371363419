<app-header-layout></app-header-layout>
<div class="row h-100">
    <div class="container h-100">
        <div class="formularioregistro estilo-form">
            <h3 *ngIf="mostrarSeccion1">DATOS PERSONALES</h3>
            <div class="content1 sub-tirulo centrar-item margen-div-video" *ngIf="mostrarSeccion1">
                Ingresa tus datos personales para editar tu cuenta
                               <button type="submit" class="btn btn-primary fondo-boton-video" style="margin-top: 15px;width: 18%;" (click)="abrirVideoDeApoyo()">
                    <mat-icon matSuffix id="iconoVideo" style="float: left;">video_library</mat-icon>
                         Video de apoyo
                </button>
            </div>
            <br>
            <!--Formulario de registro-->
            <form class="text-center" [formGroup]="registerForm" (ngSubmit)="onRegisterSubmit()">
<!--SECCION UNO -->
                <!--Nombre-->
                <div class="row centrar-item" *ngIf="mostrarSeccion1">
                    <div class="col-md-6 mb-3">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Nombre</mat-label>
                            <input matInput type="text" formControlName="name" placeholder="Nombre"
                            [ngClass]="{ 'is-invalid': informationSubmitted && r.name.errors }">
                        </mat-form-field>
                        <span *ngIf="informationSubmitted && r.name.errors" class="error">
                            <span *ngIf="r.name.errors.required">Nombre es requerido</span>
                            <span *ngIf="r.name.errors.pattern">Nombre solo permite letras</span>
                        </span>
                    </div>
                </div>
                <!--Apellido-->
                <div class="row centrar-item" *ngIf="mostrarSeccion1">
                    <div class="col-md-6 mb-3">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Apellido</mat-label>
                            <input matInput type="text" formControlName="surname" placeholder="Apellido"
                            [ngClass]="{ 'is-invalid': informationSubmitted && r.surname.errors }">
                        </mat-form-field>
                        <span *ngIf="informationSubmitted && r.surname.errors" class="error">
                            <span *ngIf="r.surname.errors.required">Apellido es requerido</span>
                            <span *ngIf="r.surname.errors.pattern">Apellido solo permite letras</span>
                        </span>
                    </div>
                </div>
                <!--Fecha-->
                <div class="row centrar-item" *ngIf="mostrarSeccion1">
                    <div class="col-md-6 mb-3">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Fecha de nacimiento</mat-label>
                            <input  matInput type="text" class="form-control estilo-fechauno" formControlName="age"
                                    placeholder="Fecha de nacimiento" onfocus="(this.type='date')"
                                    [ngClass]="{ 'is-invalid': dateSubmitted}">
                        </mat-form-field>
                        <span *ngIf="dateSubmitted || dateSubmitted2" class="error">
                            <span *ngIf="dateSubmitted">Fecha vacía o Formato inválido</span>
                            <span *ngIf="dateSubmitted2"> La fecha ingresada no está entre 13 y 100 años</span>
                        </span>
                    </div>
                </div>
                <!--Género-->
                <div class="row centrar-item" *ngIf="mostrarSeccion1">
                    <div class="col-md-6 mb-3">
                        <div class="input-group">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Género</mat-label>
                                <mat-select class="text-center estilo-genero" id="inputGroupSelect01" formControlName="gender"
                                [ngClass]="{ 'is-invalid': informationSubmitted && r.gender.errors}">
                                    <mat-option *ngFor="let g of listaGeneros" [value]="g.codigo">{{g.nombre}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <span *ngIf="informationSubmitted && r.gender.errors" class="error">
                                <span *ngIf="r.gender.errors.required">Género es requerido</span>
                            </span>
                        </div>
                    </div>
                </div>
                <!--Teléfono-->
                <div class="row centrar-item" *ngIf="mostrarSeccion1">
                    <div class="col-md-6 mb-3">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Teléfono de contacto</mat-label>
                            <input matInput type="number" placeholder="Teléfono de contacto" formControlName="phone"
                            [ngClass]="{ 'is-invalid': informationSubmitted && r.phone.errors }">
                        </mat-form-field>
                        <span *ngIf="informationSubmitted && r.phone.errors" class="error">
                            <span *ngIf="r.phone.errors.required">Teléfono es requerido</span>
                            <span *ngIf="r.phone.errors.pattern">Teléfono no es válido</span>
                        </span>
                    </div>
                </div>
                <!--País-->
                <div class="row centrar-item" *ngIf="mostrarSeccion1">
                    <div class="col-md-6 mb-3">
                        <div class="input-group ">
                            <mat-form-field appearance="outline" class="w-100 ">
                                <mat-label>País</mat-label>
                                <mat-select class="text-center" id="inputGroupSelect02" formControlName="country"
                                [ngClass]="{ 'is-invalid': informationSubmitted && r.country.errors}"
                                (selectionChange)="onChangePais($event.value)">
                                    <mat-option *ngFor="let p of listaPaises" [value]="p.codigo">{{p.nombre}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <span *ngIf="informationSubmitted && r.country.errors" class="error">
                                <span *ngIf="r.country.errors.required">País es requerido</span>
                            </span>
                        </div>
                    </div>
                </div>
                <!--Ciudad-->
                <div class="row centrar-item" *ngIf="mostrarSeccion1">
                    <div class="col-md-6 mb-3">
                        <div class="input-group ">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Ciudad</mat-label>
                                <mat-select class="text-center" id="inputGroupSelect03" formControlName="city"
                                [ngClass]="{ 'is-invalid': informationSubmitted && r.city.errors}">
                                    <mat-option *ngFor="let c of listaCiudades" [value]="c.codigo">{{c.nombre}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <span *ngIf="informationSubmitted && r.city.errors" class="error">
                                <span *ngIf="r.city.errors.required">Ciudad es requerida</span>
                            </span>
                        </div>
                    </div>
                </div>
                <!--Email-->
                <div class="row centrar-item" *ngIf="mostrarSeccion1">
                    <div class="col-md-6 mb-3">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Ingresar correo</mat-label>
                            <input matInput type="email" placeholder="Correo electrónico" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="useremail" [ngClass]="{ 'is-invalid': registerSubmitted && r.useremail.errors }">
                        </mat-form-field>
                        <span *ngIf="registerSubmitted && r.useremail.errors" class="error">
                            <span *ngIf="r.useremail.errors.email">Ingrese un Email Valido</span>
                        </span>
                    </div>
                </div>
                <!--Contraseña-->
                <div class="row centrar-item" *ngIf="mostrarSeccion1">
                    <div class="col-md-6 mb-3">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Ingresar contraseña</mat-label>
                            <input matInput type="{{vp}}" id="exampleInputPassword" placeholder="Contraseña" formControlName="password" [ngClass]="{ 'is-invalid': registerSubmitted && r.password.errors }">
                            <mat-icon matSuffix (click)="viewPassword()" class="viewPassword">remove_red_eye</mat-icon>
                        </mat-form-field>
                        <span *ngIf="registerSubmitted && r.password.errors">
                            <span *ngIf="r.password.errors.pattern" class="error">
                                <ul>
                                    <li>Se debe tener al menos una minúscula</li>
                                    <li>Se debe tener al menos una mayúscula</li>
                                    <li>Se debe tener al menos un número</li>
                                    <li>Debe tener una longitud mayor a 8 caracteres y menor o igual a 20</li>
                                    <li>Se debe tener al menos un caracter especial Ejm:(.$@$!%*?&)</li>
                                </ul>
                            </span>
                        </span>
                    </div>
                </div>
                <!--Confirmar Contraseña-->
                <div class="row centrar-item" *ngIf="mostrarSeccion1">
                    <div class="col-md-6 mb-3">
                        <mat-form-field  appearance="outline" class="w-100">
                            <mat-label>Confirmar contraseña</mat-label>
                            <input matInput type="{{vp}}" id="exampleInputPassword2" placeholder="Confirmar contraseña"
                            formControlName="password2" [ngClass]="{ 'is-invalid': registerSubmitted && r.password.errors }">
                            <mat-icon matSuffix (click)="viewPassword()" class="viewPassword">remove_red_eye</mat-icon>
                        </mat-form-field>
                        <span *ngIf="registerSubmitted && registerForm.errors?.invalid" class="error">
                        La contraseña no coincide
                        </span>
                    </div>
                </div>
                <!--¿Cómo-->
                <div class="row centrar-item" *ngIf="mostrarSeccion1">
                    <div class="col-md-6 mb-3">
                        <div class="input-group ">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>¿Cómo conoció a Stive?</mat-label>
                                <mat-select class="text-center" id="inputGroupSelect04" formControlName="ccas"
                                [ngClass]="{ 'is-invalid': informationSubmitted && r.ccas.errors}">
                                    <mat-option *ngFor="let p of listaStive" [value]="p.opcion">{{p.descripcion}}</mat-option>
                                </mat-select>  
                            </mat-form-field>
                            <span *ngIf="informationSubmitted && r.ccas.errors" class="error">
                                <span *ngIf="r.ccas.errors.required">Este campo es requerido</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="offset-md-3 col-md-6 mb-3 text-center">
                    <button type="submit" class="btn btn-primary estilo-boton-uno" [disabled]="disabled">Actualizar</button>     
                    <button type="button" class="btn btn-primary estilo-boton-uno"  (click)="dashboard();" >Volver al dashboard</button>
                </div>
            </form>
        </div>
    </div>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>